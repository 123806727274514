import { Box, Button, SxProps, Theme, Typography } from '@mui/material'
import React from 'react'
import { Styles } from '../../../types'
import { formatCurrency } from '../../../rsmCoreComponents/utils/formatters'
import { Heading } from '../../typography'
import ArrowLeftIcon from '../../icons/ArrowLeftIcon'
import { useDeviceType } from '../../../rsmCoreComponents/hooks/useDeviceType'
import tokens from '../../../styles/tokens.json'

const styles: Styles = {
  link: {
    display: 'block',
    textDecoration: 'underline',
    marginLeft: '0.5rem',
  },
  textButton: (theme) => ({
    color: theme.palette.secondary.main,
    fontWeight: 500,
    fontSize: '1rem',
    lineHeight: '1.5rem',
    justifyContent: 'start',
    verticalAlign: 'top',
    padding: 0,
    marginBottom: '1rem',
  }),
  paymenthistoryLink: (theme) => ({
    color: theme.palette.secondary.main,
    fontWeight: 500,
    fontSize: '1rem',
    lineHeight: '1.5rem',
    justifyContent: 'start',
    verticalAlign: 'top',
    padding: 0,
    marginBottom: '1rem',
  }),
  banner: (theme) => ({
    color: theme.palette.text.primary,
    fontFamily: 'Prelo-Book, sans-serif',
    display: 'flex',
    justifyContent: 'space-between',
    flexDirection: 'column',
    marginBottom: '1.875rem',

    [theme.breakpoints.up('tablet')]: {
      flexDirection: 'row',
      marginTop: '-1rem',
      paddingLeft: '2rem',
      paddingRight: '2rem',
    },
    [theme.breakpoints.only('desktop')]: {
      marginLeft: '4.5rem',
      marginRight: '0.5rem',
    },
    [theme.breakpoints.only('mobile')]: {
      paddingLeft: '1rem',
      paddingRight: '1rem',
    },
  }),
  bannerPayableAmount: (theme) => ({
    textAlign: 'center',
    [theme.breakpoints.only('mobile')]: {
      display: 'none',
    },
    display: 'flex',
    justifyContent: 'flex-end',
    flexDirection: 'column',
    [theme.breakpoints.up('tablet')]: {
      marginLeft: '1.5rem',
    },
    [theme.breakpoints.only('tablet')]: {
      minWidth: '50%',
      marginTop: '0',
    },
  }),
  bannerMainContent: {
    display: 'flex',
    justifyContent: 'flex-end',
    flexDirection: 'column',
  },
  secondaryHeader: {
    textTransform: 'uppercase',
  },
  secondaryHeaderContainer: {
    float: 'right',
    textAlign: 'center',
    paddingRight: '1rem',
  },
  buttonSlotContainer: (theme) => ({
    marginRight: '0.5rem',
    [theme.breakpoints.only('mobile')]: {
      position: 'relative',
      left: '50%',
      transform: 'translateX(-50%)',
      marginTop: '-0.5rem',
    },
  }),
  header: (theme) => ({
    fontFamily: 'Prelo-Light, sans-serif',
    fontSize: '1.375rem',
    lineHeight: '1.5rem',
    color: theme.palette.text.primary,
    [theme.breakpoints.up('tablet')]: {
      fontSize: '1.875rem',
      lineHeight: '2.125rem',
    },
    [theme.breakpoints.only('mobile')]: {
      fontWeight: 600,
      paddingBottom: '0.5rem',
    },
  }),
  bannerDescription: (theme) => ({
    fontStyle: 'normal',
    color: tokens.colors.rsmText.primary,
    opacity: 1,
    fontSize: '1rem',
    lineHeight: '1.5rem',
    margin: '1rem 0 0',
    [theme.breakpoints.only('tablet')]: {
      paddingRight: '5rem',
      width: '32rem',
    },
    [theme.breakpoints.only('mobile')]: {
      fontSize: '0.75rem',
      lineHeight: '1rem',
      fontWeight: 400,
    },
  }),
  bannerSpace: (theme) => ({
    [theme.breakpoints.only('desktop')]: {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
    },
    [theme.breakpoints.only('mobile')]: {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
    },
  }),
}

interface InvoicesHeaderProps {
  amountHeaderId?: string
  amountHeaderText?: string | undefined
  amountSubSlot?: React.ReactNode
  amount?: number | undefined
  backButtonId: string
  backButtonText: string
  primaryHeaderId: string
  primaryHeaderText: string
  primaryHeaderDescription: React.ReactNode | string
  handleNavigation: () => void
  buttonSlot?: React.ReactNode
  bottomSlot?: React.ReactNode
  headerDescriptionStyleOverride?: SxProps<Theme> | undefined
}

const InvoicesHeader = ({
  amountHeaderId = '',
  amountHeaderText = undefined,
  amountSubSlot = undefined,
  amount = undefined,
  backButtonId,
  backButtonText,
  primaryHeaderId,
  primaryHeaderText,
  primaryHeaderDescription,
  handleNavigation,
  buttonSlot = undefined,
  bottomSlot = undefined,
  headerDescriptionStyleOverride = undefined,
}: InvoicesHeaderProps) => {
  const { isMobile, isDesktop } = useDeviceType()

  const getBackButton = (): JSX.Element => (
    <Button
      data-testid={`Btn_Invoicing_${backButtonId}`}
      onClick={handleNavigation}
      sx={styles.textButton}
      role="link">
      <ArrowLeftIcon />
      <Box component="span" sx={styles.link}>
        {backButtonText}
      </Box>
    </Button>
  )

  return (
    <Box>
      <Box sx={styles.banner}>
        <Box sx={styles.bannerMainContent}>
          {isMobile ? (
            <Box style={{ display: 'grid', gridTemplateColumns: '50% 50%' }}>
              {getBackButton()}
              <Box>
                <Heading
                  variant="h2WithH6Styling"
                  data-testid={`Hed_Invoicing_${amountHeaderId}`}>
                  <Box
                    style={{
                      textAlign: 'right',
                      textTransform: 'uppercase',
                    }}>
                    {amountHeaderText}
                  </Box>
                </Heading>
                <Box style={{ textAlign: 'right' }}>
                  {amount ? formatCurrency(amount) : ''}
                </Box>
              </Box>
            </Box>
          ) : (
            getBackButton()
          )}
          <Box
            component={'h1' as React.ElementType}
            sx={styles.header}
            data-testid={`Hed_Invoicing_${primaryHeaderId}`}>
            {primaryHeaderText}
          </Box>
        </Box>
        <Box sx={styles.bannerSpace}>
          {isDesktop && (
            <Box data-testid="buttonSlot" sx={styles.buttonSlotContainer}>
              {buttonSlot}
            </Box>
          )}
          <Box sx={styles.bannerPayableAmount}>
            <Box sx={{ textAlign: 'right' }}>
              <Box sx={styles.secondaryHeaderContainer}>
                <Heading
                  variant="h2WithH6Styling"
                  data-testid={`Hed_Invoicing_${amountHeaderId}`}>
                  <Box component="span" sx={styles.secondaryHeader}>
                    {amountHeaderText}
                  </Box>
                </Heading>
                <Heading variant="h2">
                  {amount ? formatCurrency(amount) : ''}
                </Heading>
                {amountSubSlot}
              </Box>
            </Box>
          </Box>
        </Box>
      </Box>
      <Box sx={styles.banner}>
        <Typography
          component="span"
          sx={headerDescriptionStyleOverride ?? styles.bannerDescription}>
          {primaryHeaderDescription}
        </Typography>
      </Box>
      {!isDesktop && buttonSlot && (
        <Box sx={styles.banner}>
          <Box data-testid="buttonSlot" sx={styles.buttonSlotContainer}>
            {buttonSlot}
          </Box>
        </Box>
      )}
      {bottomSlot && (
        <Box sx={styles.banner}>
          <Box data-testid="bottomSlot">{bottomSlot}</Box>
        </Box>
      )}
    </Box>
  )
}

export default InvoicesHeader
