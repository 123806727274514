import {
  InvoicesSearchFilter,
  PaymentSearchFilter,
} from '@rsmus/ecp-financeservice'
import { InvoiceSelectedFiltersState } from 'src/store/invoices/invoiceSelectedFiltersSlice'
import { PaymentSelectedFiltersState } from 'src/store/invoices/paymentSelectedFiltersSlice'

export const toInvoiceSearchFilter = (
  filters: InvoiceSelectedFiltersState,
): InvoicesSearchFilter => ({
  ...filters,
  sortBy: filters.sortby,
  isSortAscending: filters.isSortAscending ?? true,
  clientIds: filters.clientId ? [Number(filters.clientId)] : [],
  pageNumber: filters.pageNumber ?? '1',
  pageSize: filters.pageSize ?? '10',
  invoiceAmountFrom: filters.invoiceAmountFrom ?? 0,
  invoiceAmountTo: filters.invoiceAmountTo ?? 0,
  invoiceStartDate: filters.invoiceStartDate ?? undefined,
  invoiceEndDate: filters.invoiceEndDate ?? undefined,
})

export const toPaymentSearchFilter = (
  filters: PaymentSelectedFiltersState,
): PaymentSearchFilter => ({
  ...filters,
  sortBy: filters.sortby,
  isSortAscending: filters.IsSortAscending ?? true,
  clientIds: filters.client?.clientId ? [Number(filters.client.clientId)] : [],
  pageNumber: filters.pageNumber ?? 1,
  pageSize: filters.pageSize ?? 10,
  paymentAmountFrom: filters.paymentAmountFrom ?? 0,
  paymentAmountTo: filters.paymentAmountTo ?? 0,
  paymentStartDate: filters.paymentStartDate ?? undefined,
  paymentEndDate: filters.paymentEndDate ?? undefined,
  processedBy: filters.processedBy ?? undefined,
  paymentStatus: filters.paymentStatus ?? [],
})
