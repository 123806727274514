import React, { useCallback, useState } from 'react'
import {
  IconButton,
  InputAdornment,
  TextField,
  Box,
  debounce,
} from '@mui/material'
import { Close as CloseIconSmall } from '@mui/icons-material'
import { useTranslation } from 'react-i18next'
import {
  PaymentSelectedFiltersConstants,
  setFilterValue,
} from '../../../store/invoices/paymentSelectedFiltersSlice'
import { SearchIcon } from '../../icons'
import { Styles } from '../../../types'

const styles: Styles = {
  container: (theme) => ({
    // '.MuiBox-root css-717api': {
    justifyContent: 'left !important',
    // },
    [theme.breakpoints.up('tablet')]: {
      width: '20rem',
    },
  }),
  processcontainer: (theme) => ({
    [theme.breakpoints.up('tablet')]: {
      width: '15rem',
    },
  }),
}

interface ProcessedBySearchProps {
  changeFilterAndSearch: (filterValue: string) => void
  testId?: string
}

const ProcessedBySearch = ({
  changeFilterAndSearch,
  testId = '',
}: ProcessedBySearchProps) => {
  const { t } = useTranslation()
  const [showClear, setShowClear] = useState(false)
  const [value, setValue] = useState('')

  const debouncedChangeFilterAndSearch = useCallback(
    debounce((e: string) => {
      // Minimum length of 2 chars required to search (or zero to clear search)
      if (e.length >= 2) {
        changeFilterAndSearch(
          setFilterValue(PaymentSelectedFiltersConstants.processedBy, e),
        )
      } else {
        changeFilterAndSearch(
          setFilterValue(PaymentSelectedFiltersConstants.processedBy, null),
        )
      }
    }, 500),
    [],
  )
  const handleChange = (e: string) => {
    setValue(e)
    if (e !== '') {
      setShowClear(true)
    } else {
      setShowClear(false)
    }
    debouncedChangeFilterAndSearch(e)
  }

  return (
    <Box
      id="invoices-filter-chips-clear-all-focus-target"
      sx={styles.container}>
      <TextField
        role="search"
        placeholder={t('SearchProcessedByKeyword')}
        variant="outlined"
        className="subvariant-search"
        onChange={(event) => handleChange(event.target.value)}
        value={value}
        InputProps={{
          'aria-describedby': 'search-instructions',
          endAdornment: showClear ? (
            <InputAdornment position="end">
              <IconButton
                aria-label="clear search"
                onClick={() => handleChange('')}
                data-testid="Btn_Projects_SearchBoxClear">
                <CloseIconSmall fontSize="small" />
              </IconButton>
            </InputAdornment>
          ) : (
            <InputAdornment position="end">
              <SearchIcon />
            </InputAdornment>
          ),
        }}
        fullWidth
        data-testid={testId}
      />
      <Box sx={{ fontSize: '0rem' }}>
        <span aria-hidden id="search-instructions">
          {t('SearchScreenReaderInstructions')}
        </span>

        <span aria-live="polite">
          {value} {t('Results')}
        </span>
      </Box>
    </Box>
  )
}

export default ProcessedBySearch
