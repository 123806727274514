import { AutoPayRecurrenceInterval } from '@rsmus/ecp-financeservice'

const minYear = new Date('50')
const maxYear = new Date((new Date().getFullYear() + 28).toString())
// eslint-disable-next-line @typescript-eslint/no-explicit-any
const isValidDate = (d: any) =>
  // isNaN and Number.isNaN have different behavior, so need to disable the eslint rule.
  // eslint-disable-next-line no-restricted-globals
  d instanceof Date && !isNaN(d as Date as unknown as number)

const isEndOfMonth = (date: Date) => {
  const lastDay = new Date(date.getFullYear(), date.getMonth() + 1, 0).getDate()
  return date.getDate() === lastDay
}

const getEndOfMonth = (date: Date) =>
  new Date(date.getFullYear(), date.getMonth() + 1, 0)

export const handleStartDateError = (
  startDate: Date | null,
  endDate: Date | null,
  setStartDateError: React.Dispatch<React.SetStateAction<boolean>>,
): boolean => {
  const hasError =
    (startDate !== null && !isValidDate(startDate)) ||
    (endDate !== null && startDate === null) ||
    (endDate !== null && startDate !== null && startDate > endDate) ||
    (startDate !== null && startDate < minYear) ||
    (startDate !== null && startDate > maxYear)

  if (hasError) {
    setStartDateError(true)
  } else {
    setStartDateError(false)
  }

  return hasError
}

export const handleEndDateError = (
  startDate: Date | null,
  endDate: Date | null,
  setEndDateError: React.Dispatch<React.SetStateAction<boolean>>,
): boolean => {
  const hasError =
    (endDate !== null && !isValidDate(endDate)) ||
    (endDate !== null && startDate !== null && endDate < startDate) ||
    (endDate === null && startDate !== null) ||
    (endDate !== null && endDate > maxYear)

  if (hasError) {
    setEndDateError(true)
  } else {
    setEndDateError(false)
  }

  return hasError
}

export const getNextProcessingDate = (
  processingDate: Date | undefined,
  frequency: AutoPayRecurrenceInterval | undefined,
) => {
  if (processingDate === undefined || frequency === undefined) return new Date()

  const today = new Date()
  const date = new Date(processingDate)
  const endOfMonth = getEndOfMonth(today)
  let nextDate

  switch (frequency.toLowerCase()) {
    case 'weekly':
      nextDate = new Date(date)
      while (nextDate <= today) {
        nextDate.setDate(nextDate.getDate() + 7)
      }
      break
    case 'biweekly':
      nextDate = new Date(date)
      while (nextDate <= today) {
        nextDate.setDate(nextDate.getDate() + 14)
      }
      break
    case 'monthly':
      nextDate = new Date(date)
      while (nextDate <= today) {
        const currentMonth = today.getMonth()
        nextDate.setMonth(currentMonth + 1)
      }

      if (!isEndOfMonth(today) && nextDate > endOfMonth) {
        nextDate = endOfMonth
      }
      break
    default:
      throw new Error(
        'Invalid frequency. Please use "weekly", "bi-weekly", or "monthly".',
      )
  }

  return nextDate
}
