import React, { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { Alert, Snackbar, IconButton } from '@mui/material'
import Slide from '@mui/material/Slide'
import { AnalyticEvent } from 'src/analytics/adobe/types'
import { ToastSuccessIcon, CloseIcon } from '../../../icons'
import tokens from '../../../../styles/tokens.json'
import { useStyles } from '../Alert.styles'

const analyticEventType: AnalyticEvent = 'SuccessBannerPage_View'
export type CustomSuccessAlertProps = {
  message: string
  messageParams?: any
  header: string
  open: boolean
  close: () => void
  testId: string
  overrideToastMsgTimeLimit?: number | null
  dataAnalyticsId?: string
  disableAutoHide?: boolean
}

const CustomSuccessAlert = ({
  header,
  message,
  messageParams = null,
  open,
  close,
  testId,
  overrideToastMsgTimeLimit = null,
  dataAnalyticsId = '',
  disableAutoHide = false,
}: CustomSuccessAlertProps) => {
  const { t } = useTranslation()
  const toastMsgTimelimit = disableAutoHide ? null : 8000
  const toastMsgSlideEnter = 1000
  const toastMsgSlideExit = 1000

  const classes = useStyles()

  useEffect(() => {
    if (open) {
      ;(window as any).pendo?.track(analyticEventType, {
        testId,
        dataAnalyticsId: dataAnalyticsId || testId,
        timestamp: new Date(),
      })
    }
  }, [open])

  let autoHideDuration = toastMsgTimelimit

  if (disableAutoHide) {
    autoHideDuration = null
  } else if (overrideToastMsgTimeLimit !== null) {
    autoHideDuration = overrideToastMsgTimeLimit
  }

  return (
    <Snackbar
      anchorOrigin={{
        vertical: 'top',
        horizontal: 'left',
      }}
      transitionDuration={{
        enter: toastMsgSlideEnter,
        exit: toastMsgSlideExit,
      }}
      TransitionComponent={Slide}
      autoHideDuration={autoHideDuration}
      open={open}
      onClose={(event, reason) => {
        if (disableAutoHide && reason === 'clickaway') {
          return
        }
        close()
      }}
      sx={classes.successSnackbar}
      data-testid={`${testId}_Snackbar`}
      data-analytic-event={analyticEventType}
      data-analytic-id={dataAnalyticsId === '' ? testId : dataAnalyticsId}>
      <Alert
        aria-atomic="true"
        aria-live="assertive"
        severity="success"
        icon={<ToastSuccessIcon color={tokens.colors.rsmGray.copy} />}
        sx={{
          ...classes.successAlert,
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
          width: '100%',
          padding: '1rem',
        }}
        action={
          <IconButton
            size="small"
            aria-label="close"
            color="inherit"
            onClick={close}
            data-testid={`${testId}_CloseButton`}>
            <CloseIcon size={18} color={tokens.colors.rsmGray.copy} />
          </IconButton>
        }>
        <div className="m-[1.5625rem]">
          <div
            style={{ marginBottom: '0.3125rem', fontWeight: 'bold' }}
            data-testid={`${testId}_Header`}
            data-analytic-event={analyticEventType}
            data-analytic-id={
              dataAnalyticsId === '' ? testId : dataAnalyticsId
            }>
            {t(header)}
          </div>
          <div
            data-testid={`${testId}_Message`}
            data-analytic-event={analyticEventType}
            data-analytic-id={
              dataAnalyticsId === '' ? testId : dataAnalyticsId
            }>
            {`${t(message, messageParams)}`}
          </div>
        </div>
      </Alert>
    </Snackbar>
  )
}

export default CustomSuccessAlert
