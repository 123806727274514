/* eslint-disable react/jsx-props-no-spreading */
import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import {
  Select,
  TextField,
  styled,
  Box,
  Typography,
  FormHelperText,
  Stack,
} from '@mui/material'
import { Controller, useForm } from 'react-hook-form'
import { useDispatch, useSelector } from 'react-redux'
import SupportFormCard from 'src/rsmCoreComponents/components/DynamicForm/FormControls/SupportFormCard'
import {
  SupportCategoryViewModel,
  SupportSubcategoryViewModel,
} from '@rsmus/ecp-communicationservice'
import { useGetUserContactPreferenceDetailsQuery } from '../../../../store/userService'
import tokens from '../../../../styles/tokens.json'
import { ArrowDownIcon } from '../../../icons'
import {
  getSelectedSupportCategory,
  setIsDashIssue,
  setSelectedSupportCategory,
  setShowSuccessBanner,
} from '../../../../store/support/supportSlice'
import CustomErrorAlert from '../../../forms/Alert/CustomErrorAlert/CustomErrorAlert'
import { Styles } from '../../../../types'
import useApps from '../../../../api/user/hooks/useApps'
import api from '../../../../api'
import FormAutoPopEmail from '../../../../rsmCoreComponents/components/DynamicForm/FormControls/FormAutoPopEmail'
import FormPreferredContactRadio from '../../../../rsmCoreComponents/components/DynamicForm/FormControls/FormPreferredContactRadio'
import FormBusinessPhoneNumber from '../../../../rsmCoreComponents/components/DynamicForm/FormControls/FormSupportBusinessPhoneNumber'
import { useDeviceType } from '../../../../rsmCoreComponents/hooks/useDeviceType'

const issueTextLimit = 2000
const StyledTextField = styled(TextField)(({ theme }) => ({
  [theme.breakpoints.down('tablet')]: {
    '.MuiInputBase-root': {
      width: '100%',
      maxWidth: '100%',
    },
    '& .MuiFormHelperText-root': { margin: '0rem' },
  },
  [theme.breakpoints.only('desktop')]: {
    '.MuiInputBase-root': {
      width: 'calc(60% + 2.5rem)',
      maxWidth: 'calc(60% + 2.5rem)',
    },
    '& .MuiFormHelperText-root': { margin: '0rem' },
  },
}))

const StyledSelectField = styled(Select)({
  '.MuiOutlinedInput-root': {
    width: '50%',
    maxWidth: '50%',
  },
  '.MuiSelect-select': { fontFamily: 'Prelo-Book !important' },
})

const StyledLabel = styled('label')(({ theme }) => ({
  fontFamily: 'Prelo-Black, sans-serif',
  fontSize: '1rem',
  paddingBottom: '0.5rem',
  color: theme.palette.text.primary,
  display: 'block',
}))

const styles: Styles = {
  dashError: {
    fontFamily: 'Prelo-Book, sans-serif',
    fontSize: '0.875rem',
    color: tokens.colors.rsmRed.secondary,
  },
  dashWarning: () => ({
    fontFamily: 'Prelo-Book, sans-serif',
    paddingLeft: '1.4375rem',
  }),
  descriptionText: (theme) => ({
    [theme.breakpoints.only('tablet')]: {
      width: '100%',
      padding: '0 1.4375rem',
    },
    [theme.breakpoints.only('mobile')]: {
      width: '50%',
    },
    [theme.breakpoints.only('desktop')]: {
      width: '30%',
      marginBottom: '1.5rem',
    },
  }),

  bpmb32: (theme) => ({
    [theme.breakpoints.only('tablet')]: {
      width: '23.5rem',
      padding: '0 -1.4375rem',
    },
    [theme.breakpoints.only('mobile')]: {
      width: '100%',
      padding: '0 1.4375rem',
    },
  }),
  paddingContainer: (theme) => ({
    [theme.breakpoints.only('tablet')]: {
      width: '100%',
    },
    [theme.breakpoints.up('mobile')]: {
      width: '100%',
    },
    marginBottom: '1.5rem',
  }),
  bppr5mb9: (theme) => ({
    width: '100%',
    [theme.breakpoints.only('desktop')]: {
      width: '30%',
    },
    marginBottom: '1.5rem',
  }),
  bppr10mb8: (theme) => ({
    [theme.breakpoints.only('tablet')]: {
      width: '50%',
      marginBottom: '1.5rem',
      padding: '0 1.4375rem',
    },
    [theme.breakpoints.only('mobile')]: {
      width: '100%',
      marginBottom: '1.5rem',
      padding: '0 1.4375rem',
    },
    [theme.breakpoints.only('desktop')]: {},
  }),
  error: {
    display: 'inline',
    float: 'left',
    fontFamily: 'Prelo-Book, sans-serif',
    fontSize: '.875rem',
    fontWeight: 400,
    color: tokens.colors.rsmRed.secondary,
    paddingTop: '0rem',
  },
  descriptionLimitContainer: (theme) => ({
    width: '100%',
    maxWidth: '100%',
    marginLeft: 0,
    [theme.breakpoints.only('desktop')]: {
      width: 'calc(60% + 2.5rem)',
      maxWidth: 'calc(60% + 2.5rem)',
    },
  }),
  descriptionLimit: (theme) => ({
    paddingTop: '0rem',
    float: 'right',
    fontFamily: 'Prelo-Book, sans-serif',
    fontSize: '1rem',
    fontWeight: 400,
    color: theme.palette.text.primary,
  }),
}

const SupportPageForm = () => {
  const { t } = useTranslation()
  const {
    handleSubmit,
    reset,
    control,
    clearErrors,
    getValues,
    formState,
    setValue,
  } = useForm({ mode: 'onSubmit' })
  // selectedSupportCategory is currently used to load default category currently for TeamDocs but could be used for other scenarios
  const selectedSupportCategory = useSelector(getSelectedSupportCategory)
  const [isSuccess, setIsSuccess] = useState(false)
  const { userApps } = useApps()
  const [open, setOpen] = useState(false)
  const [showDashWarning, setShowDashWarning] = useState(false)
  const [phoneNumber, setPhoneNumber] = useState('')
  const [dashIssueState, setIsDashIssueState] = useState<boolean | undefined>(
    undefined,
  )
  const dispatch = useDispatch()
  const navigate = useNavigate()

  const { data } = useGetUserContactPreferenceDetailsQuery()

  const { isMobile } = useDeviceType()

  const contactMethodTxtKey = data?.data.preferredContactMethod?.contactMedium
  const contactMethod = contactMethodTxtKey
    ? `${t(
        `UserProfile.ContactPreferenceForm.ContactMethods.${contactMethodTxtKey}`,
      )}`
    : `${t(
        `UserProfile.ContactPreferenceForm.ContactMethods.EmailContactMethod`,
      )}`

  const [preferredContact, setPreferredContact] = useState(contactMethod)
  const [categoriesData, setcategoriesData] = useState<
    SupportCategoryViewModel[]
  >([])

  const [subCategoryOptions, setSubCategoryOptions] = useState<
    SupportSubcategoryViewModel[]
  >([])

  const handleClose = useCallback(() => {
    setOpen(false)
  }, [setOpen])

  const onSubmit = useCallback(
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    async (request: any) => {
      try {
        const response: boolean = (
          await api.communication.issue_SupportPageIssue(request)
        )?.data
        if (response) {
          dispatch(setShowSuccessBanner(true)) // Set Redux state for success
          reset()
          navigate('/') // Navigate to homepage on success
        } else setIsSuccess(false)
      } catch (e) {
        setIsSuccess(false)
      }

      setOpen(true)
      setPreferredContact('Email')
    },
    [
      api.communication.issue_SubmitIssue,
      setIsSuccess,
      reset,
      setOpen,
      setPreferredContact,
    ],
  )

  const handleRadioChange = (val: string) => {
    setPreferredContact(val)
    if (val === 'Email') {
      clearErrors('businessPhone')
    }
  }

  const handleDashIssueChange = useCallback(() => {
    const val = getValues('dashIssue')
    if (val === 'Yes') {
      setShowDashWarning(true)
      dispatch(setIsDashIssue(true))
    } else {
      setShowDashWarning(false)
      dispatch(setIsDashIssue(false))
    }
  }, [setShowDashWarning, setIsDashIssue])

  const hasDash = useMemo(
    () => userApps.some((userApp) => userApp.name?.indexOf('DASH') === 0),
    [userApps],
  )

  const localCategoryData = async () => {
    const categorydata =
      (await api.communication.issue_GetSupportCategories())?.data || []
    setcategoriesData(categorydata || [])
  }

  const handleMainCategoryChange = async (optedCategoryID: number | string) => {
    const subcategorydata =
      optedCategoryID === ''
        ? []
        : (
            await api.communication.issue_GetSupportSubcategories(
              Number(optedCategoryID),
            )
          )?.data || []
    setSubCategoryOptions(subcategorydata || [])
  }

  const renderDashIssueDropdown = () => (
    <Box
      sx={{ display: 'inline-block', margin: '0 -1.4375rem', width: '100%' }}>
      <Box
        style={{ padding: '0 1.4375rem' }}
        sx={styles.paddingContainer}
        data-testid="Div_Support_DashIssueContainer">
        <StyledLabel id="Lbl_DashIssue" htmlFor="dashIssue">
          <span data-testid="Lbl_Support_DashIssueLabel">
            {t('DashFunctionalityIssue')}&nbsp;*
          </span>
          <span className="sr-only">{t('srOnlyRequired')}</span>
        </StyledLabel>
        <Controller
          name="dashIssue"
          control={control}
          defaultValue=""
          rules={{
            required: `${t('DashIssueShortened')} ${t('IsARequiredField')}`,
          }}
          render={({
            field: { onChange, value, ref },
            fieldState: { error },
          }) => (
            <>
              <StyledSelectField
                native
                id="dashIssue"
                style={{ width: '9.375rem' }}
                value={value}
                onChange={(e) => {
                  onChange(e)
                  handleDashIssueChange()
                  const isDashIssue = e.target.value as string
                  if (phoneNumber) {
                    setValue('businessPhone', phoneNumber)
                  }
                  setIsDashIssueState(
                    isDashIssue === ''
                      ? undefined
                      : isDashIssue.toLowerCase() === 'yes',
                  )
                }}
                inputRef={ref}
                fullWidth
                IconComponent={ArrowDownIcon}
                labelId="Lbl_DashIssue Lbl_DashWarning"
                error={!!error}
                displayEmpty
                inputProps={{
                  'aria-describedby': 'dashIssueErrorText',
                  'data-testid': 'Sel_Support_DashIssueSelect',
                }}>
                <option value="" data-testid="Opt_Support_DashIssueOptionClear">
                  {value !== ''
                    ? t('SupportFormDashDropdown.ClearSelection')
                    : t('SelectBusiness')}
                </option>
                <option
                  value="Yes"
                  data-testid="Opt_Support_DashIssueOptionYes">
                  {t('SupportFormDashDropdown.Yes')}
                </option>
                <option value="No" data-testid="Opt_Support_DashIssueOptionNo">
                  {t('SupportFormDashDropdown.No')}
                </option>
              </StyledSelectField>
              {error && (
                <FormHelperText
                  id="dashIssueErrorText"
                  data-testid="Lbl_Support_DashIssueError"
                  sx={styles.dashError}>
                  {error.message}
                </FormHelperText>
              )}
            </>
          )}
        />
      </Box>
      {showDashWarning && (
        <Stack
          sx={styles.dashWarning}
          direction="row"
          alignContent="center"
          alignItems="center"
          spacing={1}>
          <Box
            id="Lbl_DashWarning"
            role="alert"
            data-testid="Div_Support_SubmitToDashContainer">
            {t('DashFunctionalityIssueWarning')}
          </Box>
        </Stack>
      )}
    </Box>
  )

  useEffect(() => {
    localCategoryData()
  }, [])

  useEffect(() => {
    if (selectedSupportCategory) {
      handleMainCategoryChange(selectedSupportCategory)
    }
  }, [selectedSupportCategory])

  // when component unmounts set selected support category back to default value
  // currently selectedSupportCategory is set for teamdocs report an issue
  useEffect(
    () => () => {
      if (selectedSupportCategory) {
        dispatch(setSelectedSupportCategory(undefined))
      }
    },
    [],
  )

  const subCategoryValidationRules = useMemo(() => {
    const rules =
      subCategoryOptions.length > 0
        ? { required: `${t('IssueDesc')} ${t('IsARequiredField')}` }
        : { required: false }
    return rules
  }, [subCategoryOptions, t])

  return (
    <Box className="grow">
      <Box data-testid="successAlert">
        {!isSuccess && (
          <CustomErrorAlert
            testId="Banner_Support_Error"
            disableAutoHide
            header="SupportError"
            open={open}
            close={handleClose}
            message="SupportErrorMessage"
          />
        )}
      </Box>
      <SupportFormCard
        desc={t('SupportPageFormDesc')}
        handleSubmit={handleSubmit(onSubmit)}
        submitBtnDisabled={showDashWarning}
        testId="SupportForm"
        isDashUser={hasDash}
        isDashIssue={dashIssueState}
        dashDropdown={renderDashIssueDropdown()}>
        {hasDash && dashIssueState && renderDashIssueDropdown()}
        <Box sx={{ display: 'flex', flexWrap: 'wrap' }}>
          <FormAutoPopEmail />
        </Box>

        {isMobile ? (
          <Box
            sx={{
              display: 'flex',
              flexWrap: 'wrap',
            }}>
            <FormPreferredContactRadio
              control={control}
              handleRadioChange={handleRadioChange}
              contactMethod={preferredContact}
            />
            <FormBusinessPhoneNumber
              formState={formState}
              setValue={setValue}
              control={control}
              required={preferredContact === 'Phone'}
              setPhoneNumber={setPhoneNumber}
              getValues={getValues}
            />
          </Box>
        ) : (
          <Box
            sx={{
              gap: '2.5rem',
              display: 'flex',
              alignItems: 'center',
              flexWrap: 'nowrap',
            }}>
            <Box sx={styles.bppr5mb9}>
              <FormPreferredContactRadio
                control={control}
                handleRadioChange={handleRadioChange}
                contactMethod={preferredContact}
              />
            </Box>
            <Box sx={styles.bppr5mb9}>
              <FormBusinessPhoneNumber
                formState={formState}
                setValue={setValue}
                control={control}
                required={preferredContact === 'Phone'}
                setPhoneNumber={setPhoneNumber}
                getValues={getValues}
              />
            </Box>
          </Box>
        )}
        {isMobile ? (
          <Box
            sx={{ display: 'flex', flexWrap: 'wrap', margin: '0 -1.4375rem' }}>
            <Box sx={styles.bppr10mb8}>
              <StyledLabel htmlFor="Category">
                <span data-testid="Lbl_Support_CategoryLabel">
                  {t('Category')}&nbsp;*
                </span>
              </StyledLabel>
              <Controller
                name="Category"
                control={control}
                defaultValue={selectedSupportCategory || ''}
                rules={{
                  required: `${t('Category')}  ${t('IsARequiredField')}`,
                }}
                render={({
                  field: { onChange, value, ref },
                  fieldState: { error },
                }) => (
                  <>
                    <StyledSelectField
                      native
                      id="Category"
                      value={value}
                      onChange={(e) => {
                        onChange(e)
                        const selectedValue = e.target.value as number | string
                        handleMainCategoryChange(selectedValue)
                      }}
                      error={!!error}
                      fullWidth
                      IconComponent={ArrowDownIcon}
                      inputRef={ref}
                      inputProps={{
                        'aria-label': 'Category',
                        'data-testid': 'Sel_Support_CategorySelect',
                        'aria-required': true,
                        'aria-describedby': 'CategoryErrorText',
                      }}
                      displayEmpty>
                      <option value="" data-testid="Opt_Support_CategoryEmpty">
                        {t('SelectCategory')}
                      </option>
                      {categoriesData != null &&
                        categoriesData.length > 0 &&
                        categoriesData?.map((category) => (
                          <option
                            key={category.id}
                            value={category.id}
                            data-testid={`Opt_Support_Category_${category.id}`}>
                            {category.categoryName}
                          </option>
                        ))}
                    </StyledSelectField>
                    {error && (
                      <FormHelperText
                        id="CategoryErrorText"
                        data-testid="Lbl_Support_CategoryError"
                        sx={styles.dashError}>
                        {error.message}
                      </FormHelperText>
                    )}
                  </>
                )}
              />
            </Box>

            <Box sx={styles.bpmb32}>
              <StyledLabel htmlFor="SubCategory">
                <span data-testid="Lbl_Support_SubCategoryLabel">
                  {t('IssueDesc')}&nbsp;
                  {subCategoryOptions.length > 0 && <>&nbsp;*</>}
                </span>
              </StyledLabel>
              <Controller
                name="SubCategory"
                control={control}
                defaultValue=""
                rules={subCategoryValidationRules}
                render={({
                  field: { onChange, value, ref },
                  fieldState: { error },
                }) => (
                  <>
                    {' '}
                    <StyledSelectField
                      native
                      id="SubCategory"
                      value={value}
                      onChange={onChange}
                      error={!!error}
                      fullWidth
                      IconComponent={ArrowDownIcon}
                      inputRef={ref}
                      inputProps={{
                        'aria-label': 'Issue',
                        'data-testid': 'Sel_Support_SubCategorySelect',
                        'aria-required':
                          subCategoryOptions.length > 0 ? 'true' : 'false',
                        'aria-describedby': 'SubCategoryErrorText',
                      }}
                      displayEmpty>
                      <option
                        value=""
                        data-testid="Opt_Support_SubCategoryEmpty">
                        {t('SelectIssue')}
                      </option>
                      {subCategoryOptions.length > 0 &&
                        subCategoryOptions?.map((subCategory) => (
                          <option
                            key={subCategory.id}
                            value={subCategory.id}
                            data-testid={`Opt_Support_SubCategory_${subCategory.id}`}>
                            {subCategory.subcategoryName}
                          </option>
                        ))}
                    </StyledSelectField>
                    {error && (
                      <FormHelperText
                        id="SubCategoryErrorText"
                        data-testid="Lbl_Support_SubCategoryError"
                        sx={styles.dashError}>
                        {error.message}
                      </FormHelperText>
                    )}
                  </>
                )}
              />
            </Box>
          </Box>
        ) : (
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              gap: '2.5rem',
              flexWrap: 'nowrap',
            }}>
            <Box sx={styles.bppr5mb9}>
              <StyledLabel htmlFor="Category">
                <span data-testid="Lbl_Support_CategoryLabel">
                  {t('Category')}&nbsp;*
                </span>
              </StyledLabel>
              <Controller
                name="Category"
                control={control}
                defaultValue={selectedSupportCategory || ''}
                rules={{
                  required: `${t('Category')}  ${t('IsARequiredField')}`,
                }}
                render={({
                  field: { onChange, value, ref },
                  fieldState: { error },
                }) => (
                  <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                    <StyledSelectField
                      native
                      id="Category"
                      value={value}
                      onChange={(e) => {
                        onChange(e)
                        const selectedValue = e.target.value as number | string
                        handleMainCategoryChange(selectedValue)
                      }}
                      error={!!error}
                      fullWidth
                      IconComponent={ArrowDownIcon}
                      inputRef={ref}
                      inputProps={{
                        'aria-label': 'Category',
                        'data-testid': 'Sel_Support_CategorySelect',
                        'aria-required': true,
                        'aria-describedby': 'CategoryErrorText',
                      }}
                      displayEmpty>
                      <option value="" data-testid="Opt_Support_CategoryEmpty">
                        {t('SelectCategory')}
                      </option>
                      {categoriesData != null &&
                        categoriesData.length > 0 &&
                        categoriesData?.map((category) => (
                          <option
                            key={category.id}
                            value={category.id}
                            data-testid={`Opt_Support_Category_${category.id}`}>
                            {category.categoryName}
                          </option>
                        ))}
                    </StyledSelectField>
                    <FormHelperText
                      id="CategoryErrorText"
                      data-testid="Lbl_Support_CategoryError"
                      sx={{
                        minHeight: '1.5rem',
                        visibility: error ? 'visible' : 'hidden',
                        ...styles.dashError,
                      }}>
                      {error?.message || ' '}
                    </FormHelperText>
                  </Box>
                )}
              />
            </Box>
            <Box sx={styles.bppr5mb9}>
              <StyledLabel htmlFor="SubCategory">
                <span data-testid="Lbl_Support_SubCategoryLabel">
                  {t('IssueDesc')}&nbsp;
                  {subCategoryOptions.length > 0 && <>&nbsp;*</>}
                </span>
              </StyledLabel>
              <Controller
                name="SubCategory"
                control={control}
                defaultValue=""
                rules={subCategoryValidationRules}
                render={({
                  field: { onChange, value, ref },
                  fieldState: { error },
                }) => (
                  <Box
                    sx={{
                      display: 'flex',
                      flexDirection: 'column',
                    }}>
                    <StyledSelectField
                      native
                      id="SubCategory"
                      value={value}
                      onChange={onChange}
                      error={!!error}
                      fullWidth
                      IconComponent={ArrowDownIcon}
                      inputRef={ref}
                      inputProps={{
                        'aria-label': 'Issue',
                        'data-testid': 'Sel_Support_SubCategorySelect',
                        'aria-required':
                          subCategoryOptions.length > 0 ? 'true' : 'false',
                        'aria-describedby': 'SubCategoryErrorText',
                      }}
                      displayEmpty>
                      <option
                        value=""
                        data-testid="Opt_Support_SubCategoryEmpty">
                        {t('SelectIssue')}
                      </option>
                      {subCategoryOptions.length > 0 &&
                        subCategoryOptions.map((subCategory) => (
                          <option
                            key={subCategory.id}
                            value={subCategory.id}
                            data-testid={`Opt_Support_SubCategory_${subCategory.id}`}>
                            {subCategory.subcategoryName}
                          </option>
                        ))}
                    </StyledSelectField>
                    <FormHelperText
                      id="SubCategoryErrorText"
                      data-testid="Lbl_Support_SubCategoryError"
                      sx={{
                        minHeight: '1.5rem',
                        visibility: error ? 'visible' : 'hidden',
                        ...styles.dashError,
                      }}>
                      {error?.message || ' '}
                    </FormHelperText>
                  </Box>
                )}
              />
            </Box>
          </Box>
        )}

        <Box
          sx={{ marginTop: '1.5rem' }}
          data-testid="Div_Support_IssueContainer">
          <StyledLabel htmlFor="Description">
            <span data-testid="Lbl_Support_IssueLabel">
              {t('Description')}&nbsp;*
            </span>
          </StyledLabel>
          <Controller
            name="issue"
            control={control}
            defaultValue=""
            rules={{
              maxLength: issueTextLimit,
              required: `${t('Description')}  ${t('IsARequiredField')}`,
            }}
            render={({
              field: { onChange, value, ref },
              fieldState: { error },
            }) => (
              <StyledTextField
                id="Description"
                variant="outlined"
                fullWidth
                multiline
                value={value}
                rows={4}
                inputRef={ref}
                disabled={showDashWarning}
                inputProps={{
                  maxLength: issueTextLimit,
                  'data-testid': 'Inp_Support_IssueValue',
                  'aria-required': true,
                }}
                onChange={(e) => onChange(e)}
                error={!!error}
                FormHelperTextProps={{ sx: styles.descriptionLimitContainer }}
                helperText={
                  <>
                    {!!error && (
                      <Typography
                        component="span"
                        sx={styles.error}
                        data-testid="Txt_Support_IssueError">
                        {error?.message}
                      </Typography>
                    )}
                    <Typography
                      component="span"
                      sx={styles.descriptionLimit}
                      data-testid="Txt_Support_IssueLength">
                      {value.length}/{issueTextLimit}
                    </Typography>
                  </>
                }
              />
            )}
          />
        </Box>
      </SupportFormCard>
    </Box>
  )
}

export default SupportPageForm
