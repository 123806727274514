import { Box } from '@mui/material'
import React, { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import processAdobePageView from '../../analytics/adobe/pageView'
import { UserType } from '../../analytics/adobe/types'
import SupportPageForm from '../../components/domain/Support/SupportForm/SupportPageForm'
import { useAITracking } from '../../rsmCoreComponents/hooks/useAITracking'
import { getUserInfo } from '../../store/userInfo/userInfoSlice'

const pageTitle = 'Support Page'

const SupportPage = () => {
  const { t } = useTranslation()
  const userInfo = useSelector(getUserInfo)
  const userType: UserType = userInfo.isEmployee ? 'Internal' : 'External'
  useAITracking(t('ECPSupportTitle'), window.location.href)

  useEffect(() => {
    document.title = t('ECPSupportTitle')
    processAdobePageView(pageTitle, userType)
  }, [t, userType])

  return (
    <Box>
      <SupportPageForm />
    </Box>
  )
}

export default SupportPage
