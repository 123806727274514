import React, { useMemo } from 'react'
import { TabContext } from '@mui/lab'
import { Box, List } from '@mui/material'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import {
  isTargetedFeatureEnabled,
  isCemFeatureEnabled,
  isFeatureFlagEnabled,
} from '../../../rsmCoreComponents/utils/featureFlagUtils'
import {
  getTargetedFeatureFlags,
  getCemFeatures,
} from '../../../store/userInfo/userInfoSlice'
import { Styles } from '../../../types'
import {
  CEM_FEATURE_PAYMENT_PAY_INVOICES,
  CEM_FEATURE_PAYMENT_VIEW_INVOICES_AND_DOCUMENTS,
  CEM_FEATURE_PAYMENT_CLIENT_ADMIN,
} from '../../../utils/constants/constants'
import RouterNavListItem from '../../Navigation/NavListItem/RouterNavListItem'

const styles: Styles = {
  nav: (theme) => ({
    display: 'flex',
    justifyContent: 'flex-end',
    height: '100%',
    [theme.breakpoints.down('desktop')]: {
      display: 'none',
    },
  }),
  list: (theme) => ({
    display: 'flex',
    flexDirection: 'row',
    flexShrink: 0,
    border: 'none',
    height: '100%',
    gap: '2.5rem',
    a: {
      '&[aria-current="true"]': {
        position: 'relative',
        '&::after': {
          content: '""',
          display: 'block',
          position: 'absolute',
          top: 'calc(100% - 0.125rem)',
          right: 0,
          bottom: 0,
          left: 0,
          width: '100%',
          height: '0.125rem',
          backgroundColor: theme.palette.secondary.main,
          zIndex: 1,
        },
      },
    },
  }),
}

type DesktopMenuProps = {
  currentItem?: string
}

const DesktopMenu = ({ currentItem = '' }: DesktopMenuProps) => {
  const { t } = useTranslation()
  const targetedFeatureFlags = useSelector(getTargetedFeatureFlags)
  const cemFeatures = useSelector(getCemFeatures)

  const invoicingEnabled = useMemo(
    () =>
      isCemFeatureEnabled(
        [
          CEM_FEATURE_PAYMENT_PAY_INVOICES,
          CEM_FEATURE_PAYMENT_VIEW_INVOICES_AND_DOCUMENTS,
          CEM_FEATURE_PAYMENT_CLIENT_ADMIN,
        ],
        cemFeatures,
        'any',
      ),
    [cemFeatures],
  )

  const engagementsEnabled = isTargetedFeatureEnabled(
    'Targeting_Engagement',
    targetedFeatureFlags,
  )

  return (
    <Box component="nav" sx={styles.nav} aria-label={t('Navigation.Main')}>
      <TabContext value={currentItem}>
        <List sx={styles.list}>
          <RouterNavListItem to="/" value="home" testId="Lnk_Navigation_Home">
            {t('Navigation.Home')}
          </RouterNavListItem>

          {engagementsEnabled && (
            <RouterNavListItem
              to="/engagements"
              value="engagements"
              testId="Lnk_Navigation_Engagements">
              {t('Navigation.Engagements')}
            </RouterNavListItem>
          )}

          <RouterNavListItem
            to="/insights"
            value="insights"
            testId="Lnk_Navigation_Insights">
            {t('Navigation.Insights')}
          </RouterNavListItem>

          {isFeatureFlagEnabled('Invoicing') && invoicingEnabled && (
            <RouterNavListItem
              to="/invoicing"
              value="invoicing"
              testId="Lnk_Navigation_Invoicing">
              {t('Navigation.PayMyBill')}
            </RouterNavListItem>
          )}

          {!isFeatureFlagEnabled('Support') && (
            <RouterNavListItem
              to="/support"
              value="support"
              testId="Lnk_Navigation_Support">
              {t('Navigation.Support')}
            </RouterNavListItem>
          )}

          {isFeatureFlagEnabled('Support') && (
            <RouterNavListItem
              to="/contact-us"
              value="contact-us"
              testId="Lnk_Navigation_contact-us">
              {t('Navigation.ContactUs')}
            </RouterNavListItem>
          )}
        </List>
      </TabContext>
    </Box>
  )
}

export default DesktopMenu
