import React, { useEffect, useState } from 'react'
import { useTheme, Box, Theme, Card } from '@mui/material'
import { useNavigate } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import {
  getSelectedSupportCategory,
  setSelectedSupportCategory,
} from 'src/store/support/supportSlice'
import { useSelector, useDispatch } from 'react-redux'
import OverFlowToolTip from '../../OverFlowToolTip/OverFlowToolTip'
import ToolsIcon from '../../icons/ToolsIcon'
import CaretLink from '../../Navigation/CaretLink'
import { isFeatureFlagEnabled } from '../../../rsmCoreComponents/utils/featureFlagUtils'

type OwnProps = {
  fullWidth?: boolean
}

export const useStyles = (theme: Theme, { fullWidth }: OwnProps) => ({
  root: {
    margin: '0.25rem',
    padding: '1.5rem',
    display: 'flex',
    flexDirection: 'row',
    fontSize: '1rem',
    color: theme.palette.text.primary,
    width: fullWidth ? '100%' : '27rem',
    [theme.breakpoints.down('tablet')]: {
      width: '100%',
    },
    justifyContent: 'flex-start',
  },
  left: {
    flex: '1 1 100%',
  },
  category: {
    fontFamily: 'Prelo-Bold, sans-serif',
    fontSize: '0.75rem',
    fontWeight: 700,
    textTransform: 'uppercase',
  },
  title: {
    fontFamily: 'Prelo-Book,sans-serif',
    fontSize: '1.125rem',
    [theme.breakpoints.down('tablet')]: {
      width: '100%',
    },
    marginBottom: '1rem',
    marginRight: '2rem',
  },
  contactLink: {
    color: theme.palette.secondary.main,
    fontSize: '1rem',
    fontWeight: '500',
    textDecoration: 'underline',
    fontFamily: 'Prelo-Book, sans-serif',
    cursor: 'pointer',
  },
  icon: {
    display: 'block',
    [theme.breakpoints.down('tablet')]: {
      display: 'none',
    },
  },
})

const ReportAnIssueCard = ({ fullWidth = false }: OwnProps) => {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const { t } = useTranslation()
  const theme = useTheme()
  const classes = useStyles(theme, { fullWidth })
  const selectedSupportCategory = useSelector(getSelectedSupportCategory)
  const [navToSupport, setHasNavToSupport] = useState<boolean>(false)

  const reportAnIssueNavLink = () => {
    if (isFeatureFlagEnabled('Support')) {
      setHasNavToSupport(true)
      dispatch(setSelectedSupportCategory(6))
    } else {
      navigate('/reportanissue')
    }
  }

  useEffect(() => {
    if (
      navToSupport &&
      selectedSupportCategory &&
      selectedSupportCategory === 6
    ) {
      setHasNavToSupport(false) // prevents useEffect from being called multiple times
      navigate('/contact-us/support-page')
    }
  }, [navToSupport, selectedSupportCategory])

  return (
    <Card variant="outlined" sx={classes.root}>
      <Box sx={classes.left}>
        <Box component="h2" sx={classes.category}>
          <OverFlowToolTip testId="Hed_ReportAnIssueCard_Name">
            {t('Projects.ReportCard.Name')}
          </OverFlowToolTip>
        </Box>
        <Box component="h3" sx={classes.title}>
          <OverFlowToolTip
            numberOfLines={4}
            testId="Hed_ReportAnIssueCard_Name_Category">
            {t('Projects.ReportCard.Category')}
          </OverFlowToolTip>
        </Box>
        <CaretLink
          tabIndex={0}
          onClick={() => reportAnIssueNavLink()}
          sx={classes.contactLink}
          data-testid="Lnk_ReportAProblemCard_Name_ContactReportAnIssue">
          {t('Projects.ReportCard.Name')}
        </CaretLink>
      </Box>
      <Box sx={classes.icon}>
        <ToolsIcon heightInRem="3.75rem" widthInRem="3.75rem" />
      </Box>
    </Card>
  )
}

export default ReportAnIssueCard
