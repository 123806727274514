// CEM Features
export const CEM_FEATURE_PAYMENT_PAY_INVOICES = 'Payment-Pay Invoices'
export const CEM_FEATURE_PAYMENT_VIEW_INVOICES_AND_DOCUMENTS =
  'Payment-View Invoices and Documents'
export const CEM_FEATURE_PAYMENT_CREATE_INSTALLMENTS =
  'Payment-Create Installments'
export const CEM_FEATURE_PAYMENT_CLIENT_ADMIN = 'Payment-Client Admin'
export const CEM_FEATURE_PAYMENT_ACCOUNT_MANAGEMENT =
  'Payment-Account Management'
export const CEM_FEATURE_PAYMENT_INST_ADDL_FREQUENCY_OPTS =
  'Payment-Inst Addl Frequency Opts'
export const CEM_FEATURE_PAYMENT_EXTENDED_INSTALLMENTS =
  'Payment-Extended Installments'
export const CEM_FEATURE_WELCOME_OPT_OUT = 'Welcome Opt Out'
export const CEM_FEATURE_ARTICLESHARE = 'Insights Article Sharing'

// User Preferences
export const USER_PREF_INVOICING_IGNOREUNRELATED = 'Invoicing_IgnoreUnrelated'

// Invoice Filter Statuses
export const INVOICE_FILTER_STATUS_OPEN = 'open'
export const INVOICE_FILTER_STATUS_PAID = 'paid'
export const INVOICE_FILTER_STATUS_CANCELLED = 'cancelled'
export const INVOICE_FILTER_STATUS_SCHEDULED = 'scheduled'

// Invoicing Features
export const INVOICING_FEATURE_AUTOPAY = 'Invoicing_Autopay'

// Feature Flag constants
export type FeatureFlag =
  | 'AEMFeedFromBlobStorage'
  | 'AEMFeedFromConfigOverride'
  | 'BrandedAppsToolsList'
  | 'Communications'
  | 'DocumentRequest'
  | 'FAQRedesign'
  | 'HideWelcomeExperience'
  | 'HomePageEngagements'
  | 'InsightArticle_BannerImage'
  | 'InsightArticle_ClickableTags'
  | 'InsightsFeedNotifications'
  | 'Invoicing'
  | 'Invoicing_Autopay'
  | 'Invoicing_ExportTableData'
  | 'SpotlightArticleBrandEvolution'
  | 'UserProfile'
  | 'Notifications'
  | 'TeamDocs2024External'
  | 'TeamDocs2024Internal'
  | 'Support'

export type TargetingFeatureFlag =
  | 'Targeting_Engagement'
  | 'Targeting_SharedArticle'

export type SignalRMethods = 'NotificationsNewCount' | 'NotificationsDelete'
