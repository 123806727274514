import React, { useCallback } from 'react'
import { Box, Theme } from '@mui/material'
import { FaqItemModel, FaqTopicModel } from 'src/store/faqs/faqsSlice'
import { tokens } from '../../../../../styles/materialTheme'
import { Styles } from '../../../../../types'
import FaqItem from '../FaqItem'
import tokensJson from '../../../../../styles/tokens.json'
import { isFeatureFlagEnabled } from '../../../../../rsmCoreComponents/utils/featureFlagUtils'

const styles: Styles = {
  faqitem: () => ({
    backgroundColor: tokensJson.colors.white,
  }),
}

type FaqTopicProps = {
  topic: FaqTopicModel
  topicIndex: number
}
const faqTopic = ({ topic, topicIndex }: FaqTopicProps) => {
  const isFAQRedesignEnabled = isFeatureFlagEnabled('FAQRedesign')

  const computedStyles = {
    topic: useCallback(
      (theme: Theme) =>
        isFAQRedesignEnabled
          ? {
              fontSize: '1.5rem',
              fontStyle: 'normal',
              fontWeight: 700,
              lineHeight: '1.8125rem',
              color: tokens.colors.rsmGray.copy,
              [theme.breakpoints.up('mobile')]: {
                paddingTop: topicIndex === 0 ? 0 : '2rem',
              },
              [theme.breakpoints.up('desktop')]: {
                paddingBottom: '0.75rem',
              },
              [theme.breakpoints.up('tablet')]: {
                paddingBottom: '1.5rem',
              },
            }
          : {
              fontSize: '2.25rem',
              lineHeight: '2.25rem',
              color: tokens.colors.rsmGray.copy,
              [theme.breakpoints.up('mobile')]: {
                fontSize: '1.5rem',
                paddingTop: '2rem',
              },
              [theme.breakpoints.up('desktop')]: {
                paddingBottom: '0.75rem',
              },
              [theme.breakpoints.up('tablet')]: {
                paddingTop: '2rem',
                paddingBottom: '1.5rem',
                fontSize: '2.25rem',
              },
            },
      [isFAQRedesignEnabled, topicIndex],
    ),
  }

  return (
    <Box id={`topic${topicIndex}`} sx={{ paddingBottom: '0.5rem' }}>
      <Box
        id={`topicHeader${topicIndex}`}
        tabIndex={-1}
        component="h2"
        sx={(theme) => computedStyles.topic(theme)}
        data-testid={`Lbl_Faq_TopicTitle_${topicIndex}`}
        className="font-prelo-light">
        {topic?.topic}
      </Box>
      <Box sx={styles.faqitem}>
        {topic?.items?.map((item: FaqItemModel, index: number) => (
          <FaqItem
            key={`${topicIndex}-${item.question}`}
            topicIndex={topicIndex}
            indexNum={index}
            question={item.question}
            answer={item.answer}
            faqCount={topic?.items.length}
          />
        ))}
      </Box>
    </Box>
  )
}

export default faqTopic
