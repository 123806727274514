/* eslint-disable @typescript-eslint/no-unused-vars */
import { useCallback, useEffect, useState, useRef } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { InvoicesSearchFilter } from '@rsmus/ecp-financeservice'
import { useLocation } from 'react-router-dom'
import api from '../../../api'
import { isFeatureFlagEnabled } from '../../../rsmCoreComponents/utils/featureFlagUtils'
import {
  useGetInvoiceSearchFiltersQuery,
  useGetTotalOpenAmountQuery,
  useUpdateInvoiceSearchResultsMutation,
} from '../../../store/invoices/invoiceSearchService'
import {
  clearAllFilters,
  clearCategoryFilters,
  getInvoiceSelectedFilters,
  InvoiceSelectedFiltersState,
  setPageSize,
  toPageNumber,
  toNextPage,
  toPreviousPage,
  updateFilterValue,
  updateSearch as updateSearchFilter,
  amountRangeFilterMax,
  getPageInfo,
  updateSorting,
  setInvoiceCounts,
} from '../../../store/invoices/invoiceSelectedFiltersSlice'
import { setSelectedInvoices } from '../../../store/invoices/invoiceSelectedInvoicesSlice'
import { RsmPaginationPageSizes } from '../../../rsmCoreComponents/components/RsmPagination/RsmPagination'

const transformFilters = (
  selectedFilters: InvoiceSelectedFiltersState,
): InvoicesSearchFilter =>
  ({
    pageNumber: selectedFilters.pageNumber,
    pageSize: selectedFilters.pageSize,
    searchString: selectedFilters.searchString,
    invoiceStatus: selectedFilters.invoiceStatus,
    invoiceStartDate: selectedFilters.invoiceStartDate,
    invoiceEndDate: selectedFilters.invoiceEndDate,
    invoiceAmountFrom: selectedFilters.invoiceAmountFrom || 0,
    invoiceAmountTo: selectedFilters.invoiceAmountTo || 0,
    currency: selectedFilters.currency,
    sortBy: selectedFilters.sortby,
    isSortAscending: selectedFilters.isSortAscending,
    clientIds: selectedFilters.clientId ? [+selectedFilters.clientId] : [],
  } as InvoicesSearchFilter)

// this HOOK should have all ACTIONS related to invoices search page

const useInvoicesSearch = () => {
  const location = useLocation()
  const pageInfo = useSelector(getPageInfo)
  const selectedFilters = useSelector(getInvoiceSelectedFilters)
  const [isSearchPending, setIsSearchPending] = useState<boolean>(false)
  const [isTextSearchPending, setIsTextSearchPending] = useState<boolean>(false)
  const totalInvoicesCacheRef = useRef<number | null>(null)
  const dispatch = useDispatch()
  const {
    isSuccess: filterIsSuccess,
    isError: filterIsError,
    data: filterData,
    error: filterError,
  } = useGetInvoiceSearchFiltersQuery()

  const [
    updateSearch,
    {
      isLoading: invoicesIsLoading,
      isError: invoicesIsError,
      data: invoicesData,
      error: invoicesError,
    },
  ] = useUpdateInvoiceSearchResultsMutation()

  useEffect(() => {
    if (
      selectedFilters.invoiceCounts.totalInvoicesSet &&
      totalInvoicesCacheRef.current === null
    ) {
      totalInvoicesCacheRef.current =
        selectedFilters.invoiceCounts.totalInvoices
    }
  }, [selectedFilters.invoiceCounts])

  const shouldRunSearch = () =>
    (isSearchPending || isTextSearchPending) && filterIsSuccess

  const getTotalInvoices = async (
    filters: InvoicesSearchFilter,
  ): Promise<number | null> => {
    if (!isFeatureFlagEnabled('Invoicing_ExportTableData')) return null

    if (selectedFilters.invoiceCounts?.totalInvoicesSet) return null

    if (totalInvoicesCacheRef.current !== null) {
      const cached = totalInvoicesCacheRef.current
      totalInvoicesCacheRef.current = null
      return cached
    }

    const counts = await api.finance.invoice_GetInvoiceCounts(filters)
    return counts?.data?.totalInvoices ?? 0
  }

  const buildInvoiceCountsPayload = (
    filteredInvoices: number,
    totalInvoices?: number | null,
  ) => ({
    filteredInvoices,
    ...(totalInvoices !== undefined && totalInvoices !== null
      ? { totalInvoices }
      : {}),
  })

  const submitSearch = useCallback(async () => {
    if (!shouldRunSearch()) return

    setIsSearchPending(false)
    setIsTextSearchPending(false)

    // Set loading placeholder (0.5) to differentiate UI between loading and zero records
    dispatch(setInvoiceCounts({ filteredInvoices: 0.5 }))

    const filters = transformFilters(selectedFilters)

    try {
      const result = await updateSearch(filters).unwrap()

      const totalInvoices = await getTotalInvoices(filters)

      dispatch(
        setInvoiceCounts(
          buildInvoiceCountsPayload(result?.totalRows ?? 0, totalInvoices),
        ),
      )
    } catch (e: any) {
      if (e?.message !== 'canceled') {
        throw e
      }
    }
  }, [
    isSearchPending,
    isTextSearchPending,
    filterIsSuccess,
    selectedFilters,
    updateSearch,
    dispatch,
  ])

  const requestSearch = useCallback(
    (search: boolean, textSearch: boolean) => {
      setIsSearchPending(search)
      setIsTextSearchPending(textSearch)
    },
    [setIsSearchPending, setIsTextSearchPending],
  )

  const setInvoicePageSize = useCallback(
    (pageSize: RsmPaginationPageSizes) => {
      dispatch(setPageSize(pageSize))
      requestSearch(true, false)
    },
    [dispatch, setPageSize, requestSearch],
  )

  const toPage = useCallback(
    (pageNumber: number) => {
      dispatch(toPageNumber(pageNumber))
      requestSearch(true, false)
    },
    [dispatch, toPageNumber, requestSearch],
  )

  const nextPage = useCallback(() => {
    dispatch(toNextPage())
    requestSearch(true, false)
  }, [dispatch, toNextPage, requestSearch])

  const previousPage = useCallback(() => {
    dispatch(toPreviousPage())
    requestSearch(true, false)
  }, [dispatch, toPreviousPage, requestSearch])

  const SortInvoiceData = useCallback(
    (sortBy: string, isAscending: 'asc' | 'desc') => {
      // Update the state or dispatch an action to handle sorting
      const ascending = isAscending === 'asc'
      dispatch(updateSorting({ sortBy, isAscending: ascending }))
      requestSearch(true, false)
    },
    [dispatch, requestSearch],
  )

  const changeFilterValue = useCallback(
    (filterValue: string) => {
      dispatch(updateFilterValue(filterValue))
    },
    [dispatch, updateFilterValue],
  )

  const clearFilters = useCallback(() => {
    dispatch(setSelectedInvoices([]))
    dispatch(clearAllFilters())
    requestSearch(true, false)
  }, [dispatch, setSelectedInvoices, clearAllFilters, requestSearch])

  const clearCategoryFilter = useCallback(
    (categoryValue: string) => {
      dispatch(setSelectedInvoices([]))
      dispatch(clearCategoryFilters(categoryValue))
      requestSearch(true, false)
    },
    [dispatch, setSelectedInvoices, clearCategoryFilters, requestSearch],
  )

  const changeFilterAndSearch = useCallback(
    (filterValue: string, preserveSelectedInvoices?: boolean) => {
      if (!preserveSelectedInvoices) {
        dispatch(setSelectedInvoices([]))
      }
      changeFilterValue(filterValue)
      requestSearch(true, false)
    },
    [dispatch, setSelectedInvoices, changeFilterValue, requestSearch],
  )

  const changeSearchFilterAndSearch = (searchString: string) => {
    if (searchString !== selectedFilters.searchString) {
      dispatch(updateSearchFilter(searchString))
      requestSearch(false, true)
    }
  }

  useEffect(() => {
    if (location.pathname === '/invoicing/invoices') {
      submitSearch()
    }
  }, [location.pathname, isSearchPending, isTextSearchPending, filterIsSuccess])

  return {
    filterIsSuccess,
    filterIsError,
    filterData,
    filterError,
    invoicesIsLoading,
    invoicesIsError,
    invoicesData,
    invoicesError,
    nextPage,
    previousPage,
    setInvoicePageSize,
    toPage,
    pageInfo,
    clearFilters,
    clearCategoryFilter,
    changeFilterValue,
    requestSearch,
    changeFilterAndSearch,
    changeSearchFilterAndSearch,
    SortInvoiceData,
  }
}

export default useInvoicesSearch
