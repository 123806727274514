import React, { useEffect, useState } from 'react'
import { ExpandMore } from '@mui/icons-material'
import {
  AccordionDetails,
  Typography,
  Box,
  Accordion,
  AccordionSummary,
  Link,
} from '@mui/material'
import { useTranslation, Trans } from 'react-i18next'
import { useSelector } from 'react-redux'
import { Styles } from '../../../../../types'
import { RootState } from '../../../../../store'
import tokens from '../../../../../styles/tokens.json'
import { isFeatureFlagEnabled } from '../../../../../rsmCoreComponents/utils/featureFlagUtils'

const styles: Styles = {
  accord: {
    paddingTop: '0.75rem',
  },
  accordian: () => ({
    color: tokens.colors.rsmText.primary,
    paddingLeft: '0.25rem',
  }),
  question: () => ({
    paddingTop: '0.75rem',
    paddingBottom: '0.75rem',
  }),
  questionRedesign: () => ({
    paddingTop: '0.75rem',
    paddingBottom: '0.75rem',
    fontFamily: 'prelo-medium',
    fontSize: '1.125rem',
    fontWeight: 500,
    lineHeight: '1.8125rem',
  }),
  answer: (theme) => ({
    [theme.breakpoints.up('desktop')]: {
      width: '66.666667%',
    },
  }),
  link: (theme) => ({
    color: theme.palette.secondary.main,
    textDecoration: 'underline',
  }),
  expandIcon: (theme) => ({
    color: theme.palette.secondary.main,
  }),
}

type FaqItemProps = {
  question: string
  answer: string
  indexNum: number
  topicIndex: number
  faqCount?: number | undefined
}

const FaqItem = ({
  question,
  answer,
  indexNum,
  topicIndex,
  faqCount,
}: FaqItemProps) => {
  const { expandAll } = useSelector((state: RootState) => state.faqs)
  const [expanded, setExpanded] = useState<boolean>(expandAll)
  const { t } = useTranslation()
  const isFAQRedesignEnabled = isFeatureFlagEnabled('FAQRedesign')

  useEffect(() => {
    setExpanded(expandAll)
  }, [expandAll])

  const generateAnswerText = (faqAnswer: string) => {
    // check to see if i18next <0> index is present from faq answer so that we can check if isDashSupport  = 'please submit a DASH ticket here'
    // and also be able to utilize i18next Tran component to add link to DASH
    const isDashSupport = faqAnswer.substring(
      faqAnswer.indexOf('<0>') + 3,
      faqAnswer.lastIndexOf('</0>'),
    )
    if (
      isDashSupport !== '' &&
      isDashSupport === 'please submit a DASH ticket here'
    ) {
      return (
        <Trans
          defaults={faqAnswer}
          components={[
            <Link
              href="https://dash.rsmus.com/csm"
              sx={styles.link}
              data-testid={`Lnk_Faq_SubmitToDash_${topicIndex}_${indexNum}`}
              target="_blank"
              rel="noopener noreferrer"
              aria-label={t('DashSupportLink')}
            />,
          ]}
        />
      )
    }

    return faqAnswer
  }

  return (
    <Box
      sx={
        isFAQRedesignEnabled
          ? {
              borderTop: `${tokens.colors.rsmGray.dividers} 0.0313rem solid`,
              borderBottom:
                faqCount && indexNum === faqCount - 1
                  ? `${tokens.colors.rsmGray.dividers} 0.0313rem solid`
                  : 0,
            }
          : styles.accord
      }
      data-testid="topic-question">
      <Accordion
        expanded={expanded}
        onChange={() => setExpanded(!expanded)}
        square={isFAQRedesignEnabled}
        sx={styles.accordian}
        className="font-prelo-book">
        <AccordionSummary
          expandIcon={
            <ExpandMore
              sx={isFAQRedesignEnabled ? styles.expandIcon : undefined}
            />
          }
          aria-controls={`panel${topicIndex}a-content${indexNum}`}
          data-testid={`Lbl_Faq_Question_${topicIndex}_${indexNum}`}
          id={`panel${topicIndex}a-header${indexNum}`}
          sx={
            isFAQRedesignEnabled
              ? undefined
              : {
                  backgroundColor:
                    indexNum % 2 === 0
                      ? tokens.colors.rsmBackgroundAccent.primary
                      : tokens.colors.white,
                  height: '4.5rem',
                }
          }>
          <Box
            sx={
              isFAQRedesignEnabled ? styles.questionRedesign : styles.question
            }>
            {question}
          </Box>
        </AccordionSummary>
        <AccordionDetails
          sx={
            isFAQRedesignEnabled
              ? undefined
              : {
                  backgroundColor:
                    indexNum % 2 === 0
                      ? tokens.colors.rsmBackgroundAccent.primary
                      : tokens.colors.white,
                }
          }
          data-testid={`Lbl_Faq_Answer_${topicIndex}_${indexNum}`}>
          <Typography sx={styles.answer} className="font-prelo-book">
            {generateAnswerText(answer)}
          </Typography>
        </AccordionDetails>
      </Accordion>
    </Box>
  )
}

export default FaqItem
