import React, { useCallback, useEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { Button, Box, Theme } from '@mui/material'
import useDeviceType from 'src/rsmCoreComponents/hooks/useDeviceType'
import { Heading } from 'src/components/typography'
import FaqTopic from '../../components/domain/Support/Faq/FaqTopic'
import RoboIcon from '../../components/icons/RoboIcon'
import { FaqTopicModel, getFaqs, setfaqData } from '../../store/faqs/faqsSlice'
import Spinner from '../../components/forms/Spinner/Spinner'
import { useAITracking } from '../../rsmCoreComponents/hooks/useAITracking'
import { Styles } from '../../types'
import { tokens } from '../../styles/materialTheme'
import ExpandButton from '../../components/Faq/ExpandButton/ExpandButton'
import faqData from './faqData.json'

const styles: Styles = {
  faqSection: (theme) => ({
    backgroundColor: tokens.colors.white,
    paddingTop: '2.5rem',
    paddingLeft: '7.25rem',
    paddingRight: '7.25rem',
    [theme.breakpoints.only('mobile')]: {
      paddingLeft: '1.75rem',
      paddingRight: '1.75rem',
    },
    [theme.breakpoints.only('tablet')]: {
      paddingLeft: '2.75rem',
      paddingRight: '2.75rem',
    },
    [theme.breakpoints.only('desktop')]: {
      display: 'flex',
    },
  }),
  formDescription: (theme) => ({
    paddingTop: '1.5rem',
    paddingBottom: '2.5rem',
    color: '#515356',
    fontFamily: 'Prelo-Book',
    [theme.breakpoints.up('mobile')]: { width: '100%' },
  }),
  faqCard: (theme) => ({
    flex: '0.1 auto',
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    color: tokens.colors.rsmGray.copy,
    margin: '0 auto',
    [theme.breakpoints.up('mobile')]: {
      maxWidth: '100%',
      padding: '0rem 1rem',
    },
    [theme.breakpoints.up('tablet')]: { maxWidth: '40.625rem' },
    [theme.breakpoints.up('desktop')]: { maxWidth: '40.625rem' },
  }),
  faqIcon: () => ({
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    textAlign: 'center',
  }),
  faqErrorSection: () => ({
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  }),
  errorTitle: (theme) => ({
    textAlign: 'center',
    [theme.breakpoints.up('mobile')]: { width: '100%' },
    [theme.breakpoints.up('tablet')]: { paddingTop: '36.1875rem' },
    [theme.breakpoints.up('desktop')]: { paddingTop: '36.1875rem' },
  }),
  error: () => ({
    paddingBottom: '1rem',
    paddingTop: '0.5rem',
    fontSize: '2rem',
  }),
  faqErrorDescription: (theme) => ({
    paddingBottom: '0.25rem',
    fontSize: '1.125rem',
    [theme.breakpoints.up('desktop')]: {
      paddingRight: '1rem',
      paddingLeft: '1rem',
    },
  }),
  dataErrorCard: (theme) => ({
    display: 'flex',
    flexGrow: 1,
    paddingTop: '8.5rem',
    maxWidth: '100%',
    background: tokens.colors.white,
    border: tokens.colors.rsmGray.disabled,
    [theme.breakpoints.up('desktop')]: { flexGrow: 0, height: '55.5rem' },
    [theme.breakpoints.up('tablet')]: {
      flexGrow: 0,
      height: '27.625rem',
    },
    [theme.breakpoints.up('mobile')]: { height: '28.625rem' },
  }),
  button: (theme) => ({
    maxHeight: '3.125rem',
    marginTop: '1.875rem',
    alignContent: 'center',
    width: '6.6875rem',
    [theme.breakpoints.up('mobile')]: {
      width: '21.4375rem',
      paddingLeft: '1rem',
      paddingRight: '1rem',
      marginBottom: '4rem',
      marginTop: '2rem',
    },
    [theme.breakpoints.up('tablet')]: { width: '6.6875rem' },
  }),
  emptyDiv: (theme) => ({
    [theme.breakpoints.up('mobile')]: { marginTop: '1.875rem' },
  }),
  link: (theme) => ({
    color: theme.palette.secondary.main,
    textDecoration: 'underline',
    cursor: 'pointer',
    whiteSpace: 'nowrap',
  }),
  faqDataContainer: (theme) => ({
    display: 'flex',
    padding: '0rem 6.5rem 2.5rem 3.5rem',
    backgroundColor: tokens.colors.white,
    maxHeight: '68.9375rem',
    overflow: 'hidden',
    paddingLeft: '7.25rem',
    paddingRight: '7.25rem',
    [theme.breakpoints.only('tablet')]: {
      padding: '0rem 2.75rem 2.5rem 2.75rem',
    },
    [theme.breakpoints.only('mobile')]: {
      padding: '0rem 0.75rem 2.5rem 0.75rem',
      maxHeight: '100%',
    },
  }),
  quickNavLinks: {
    paddingRight: '2rem',
    width: '12.875rem',
    fontFamily: 'prelo-medium',
    fontSize: '1.125rem',
    lineHeight: '2rem',
    fontWeight: 500,
  },
  expandButton: (theme) => ({
    backgroundColor: tokens.colors.white,
    paddingBottom: 0,
    paddingLeft: '7.25rem',
    paddingRight: '9.75rem',
    [theme.breakpoints.only('mobile')]: {
      paddingLeft: '1.75rem',
      paddingRight: '1.75rem',
      paddingBottom: '1rem',
    },
    [theme.breakpoints.only('tablet')]: {
      paddingLeft: '2.75rem',
      paddingRight: '5.25rem',
    },
  }),
  faqContainer: {
    display: 'grid',
    width: '100%',
  },
}

const FaqRedesign = () => {
  const { t } = useTranslation()
  const [isLoading, setIsLoading] = useState(true)
  const dispatch = useDispatch()
  const faqRequest = useSelector(getFaqs)
  const { isDesktop } = useDeviceType()
  const scrollableContainerRef = useRef<HTMLDivElement>(null)
  const linkElementRef = useRef<HTMLDivElement>(null)
  const [faqContainerPadding, setFaqContainerPadding] = useState(0)
  const [currentTopicIndex, setCurrentTopicIndex] = useState(0)

  const getFaqData = () => {
    setIsLoading(true)
    dispatch(setfaqData(faqData.faqs as FaqTopicModel[]))
    setIsLoading(false)
  }

  const scrollToSection = (topicIndex: number) => {
    if (topicIndex === 0) {
      setFaqContainerPadding(0)
      setCurrentTopicIndex(0)
      document.getElementById(`topicHeader${topicIndex}`)?.focus()
    }
    if (topicIndex === 1) {
      setFaqContainerPadding(0)
      setCurrentTopicIndex(1)
    }
    if (topicIndex === 2) {
      setFaqContainerPadding(8.5)
      setCurrentTopicIndex(2)
    }
    if (topicIndex === 3) {
      setFaqContainerPadding(25.5)
      setCurrentTopicIndex(3)
    }
    if (topicIndex === 4) {
      setFaqContainerPadding(49.5)
      setCurrentTopicIndex(4)
    }
  }

  const computedStyles = {
    faqTopic: useCallback(
      (theme: Theme) => ({
        overflowY: 'scroll',
        paddingBottom: `${2.5 + faqContainerPadding}rem`,
        backgroundColor: 'white',
        paddingTop: '0.375rem',
        [theme.breakpoints.only('mobile')]: {
          paddingLeft: '1rem',
          paddingRight: '1rem',
          overflowY: 'unset',
        },
        [theme.breakpoints.up('tablet')]: {
          paddingRight: '2rem',
        },
        [theme.breakpoints.up('desktop')]: { paddingLeft: '3.75rem' },
      }),
      [faqContainerPadding],
    ),
  }

  useEffect(() => {
    const item = document.getElementById(`topic${currentTopicIndex}`)
    const header = document.getElementById(`topicHeader${currentTopicIndex}`)
    const parent = scrollableContainerRef.current

    if (parent && item) {
      parent.scrollTop = item.offsetTop - parent.offsetTop - 5
    }

    if (header) {
      header.focus()
    }
  }, [currentTopicIndex])

  useAITracking(t('FaqPage.Title'), window.location.href)

  useEffect(() => {
    document.title = t('FaqPage.Title')
    getFaqData()
  }, [])

  return (
    <Box>
      {isLoading && <Spinner open={isLoading} />}
      <Box sx={styles.faqSection}>
        <Box component="section">
          <Heading variant="h1" testId="Hed_Faq_Title">
            {t('FaqPage.Title')}
          </Heading>
          <Box
            component="p"
            sx={styles.formDescription}
            data-testid="Lbl_Faq_Description">
            {t('FaqPage.Description')}
          </Box>
        </Box>
      </Box>
      {(!isLoading && faqRequest?.length) === 0 ? (
        <Box sx={styles.dataErrorCard}>
          <Box sx={styles.faqCard}>
            <Box sx={styles.faqIcon}>
              <RoboIcon />
            </Box>
            <Box sx={styles.faqErrorSection}>
              <Box sx={styles.errorTitle}>
                <Box sx={styles.error}>{t('FaqPage.FaqErrorTitle')}</Box>
                <Box sx={styles.faqErrorDescription}>
                  {t('FaqPage.FaqErrorDesc')}
                </Box>
                <Button
                  sx={styles.button}
                  variant="contained"
                  type="submit"
                  onClick={() => window.location.reload()}
                  color="primary">
                  {t('FaqPage.Refresh')}
                </Button>
              </Box>
            </Box>
          </Box>
        </Box>
      ) : (
        <Box sx={styles.faqContainer}>
          <Box sx={styles.expandButton}>
            <ExpandButton
              sx={{ float: 'right', padding: '0rem' }}
              testId="Btn_Faq_ExpandAll"
            />
          </Box>
          <Box sx={styles.faqDataContainer}>
            {isDesktop && (
              <Box sx={styles.quickNavLinks}>
                {faqRequest?.map(
                  (faqTopic: FaqTopicModel, topicIndex: number) => (
                    <Box
                      id={`topicLnk_${topicIndex}`}
                      ref={topicIndex === 0 ? linkElementRef : null}
                      key={`${faqTopic?.topic}_Lnk`}
                      sx={styles.link}
                      aria-label={faqTopic.topic}
                      role="link"
                      tabIndex={0}
                      onKeyDown={(e) => {
                        if (e.key === 'Enter') {
                          scrollToSection(topicIndex)
                        }
                      }}
                      onClick={() => scrollToSection(topicIndex)}>
                      {t(`FaqPage.${faqTopic.topic.replace(/\s+/g, '')}`)}
                    </Box>
                  ),
                )}
              </Box>
            )}
            <Box sx={computedStyles.faqTopic} ref={scrollableContainerRef}>
              {faqRequest?.map(
                (faqTopic: FaqTopicModel, topicIndex: number) => (
                  <FaqTopic
                    key={faqTopic?.topic}
                    topic={faqTopic}
                    topicIndex={topicIndex}
                  />
                ),
              )}
            </Box>
          </Box>
        </Box>
      )}
      <Box sx={styles.emptyDiv} />
    </Box>
  )
}

export default FaqRedesign
