import React, { useState, useEffect, useCallback, useRef, useMemo } from 'react'
import { Box, styled, Button, Checkbox } from '@mui/material'
import {
  InvoicePayerViewModel,
  PaymentInstallmentFrequencyEnum,
  ScheduledInvoicesPaymentViewModel,
} from '@rsmus/ecp-financeservice'
import { useSelector, useDispatch } from 'react-redux'
import { Trans, useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import { FormProvider, useForm } from 'react-hook-form'
import { useDeviceType } from '../../../rsmCoreComponents/hooks/useDeviceType'
import ScheduledInvoicesDialog from './ScheduledInvoicesDialog'
import InvoicesHeader from './InvoicesHeader'
import { Styles } from '../../../types'
import {
  getPayableAmount,
  getInvoicePayments,
  getIsEditAutoPay,
  getPayOnAccountClient,
  ImplementAutoPayLimitType,
  setImplementAutoPayLimit,
  getEditAutoPaySetup,
  getPartialAmount,
} from '../../../store/invoices/invoiceSelectedInvoicesSlice'
import {
  setSelectedBankAccount,
  setSelectedCreditCard,
  setSelectedPayer,
  getSelectedBankAccount,
  getSelectedCreditCard,
  getSelectedPaymentMethod,
  setAccountSelectedType,
  SelectedAccountTypeState,
  setNewPaymentMethod,
  getPaymentMethodError,
  setPaymentMethodError,
  getConfirmPaymentError,
  setConfirmPaymentError,
  setDoNotSave,
  sethandleClicked,
} from '../../../store/invoices/paymentInfoSlice'
import api from '../../../api'
import CustomErrorAlert from '../../forms/Alert/CustomErrorAlert/CustomErrorAlert'
import Spinner from '../../forms/Spinner/Spinner'
import PaymentTypePayNow from './PaymentTypePayNow'
import PaymentTypeScheduled from './PaymentTypeScheduled'
import PaymentTypeInstallments from './PaymentTypeInstallments'
import PaymentCategory from './PaymentComponents/PaymentCategory'
import PaymentCategoriesErrosDialog from './PaymentComponents/PaymentCategoriesErrorsDialog'
import PayOnAccount from './PayOnAccount'
import { isCemFeatureEnabled } from '../../../rsmCoreComponents/utils/featureFlagUtils'
import { CEM_FEATURE_PAYMENT_ACCOUNT_MANAGEMENT } from '../../../utils/constants/constants'
import { getCemFeatures } from '../../../store/userInfo/userInfoSlice'
import AutoPay from './AutoPay'
import {
  CheckboxIcon,
  CheckboxOutlinedIcon,
} from '../../../rsmCoreComponents/components/icons'
import { tokens } from '../../../styles/materialTheme'
import { getNextProcessingDate } from './dateUtils'

const styles: Styles = {
  PaymentTypeContainer: (theme) => ({
    gridColumn: 'col 1 / span 12',
    gridRow: 'row 1 / span 1',
    color: theme.palette.text.primary,
    minHeight: '50vh',
    fontFamily: 'Prelo-Book, sans-serif',
    [theme.breakpoints.only('desktop')]: {
      paddingLeft: '6.5rem',
      paddingRight: '6.5rem',
      paddingBottom: '6.5rem',
    },
    [theme.breakpoints.only('tablet')]: {
      paddingLeft: '2rem',
      paddingRight: '2rem',
    },
    [theme.breakpoints.only('mobile')]: {
      paddingLeft: '1rem',
      paddingRight: '1rem',
    },
  }),
  AutoPayEditPaymentTypeContainer: (theme) => ({
    gridColumn: 'col 1 / span 12',
    gridRow: 'row 2 / span 1',
    color: theme.palette.text.primary,
    minHeight: '50vh',
    fontFamily: 'Prelo-Book, sans-serif',
    [theme.breakpoints.only('desktop')]: {
      paddingLeft: '6.5rem',
      paddingRight: '6.5rem',
      paddingBottom: '6.5rem',
      gridRow: 'row 1 / span 1',
    },
    [theme.breakpoints.only('tablet')]: {
      paddingLeft: '2rem',
      paddingRight: '2rem',
    },
    [theme.breakpoints.only('mobile')]: {
      paddingLeft: '1rem',
      paddingRight: '1rem',
    },
  }),
  ErrorSumContainer: (theme) => ({
    [theme.breakpoints.only('desktop')]: {
      width: 'fit-content',
    },
    [theme.breakpoints.only('mobile')]: {
      paddingLeft: '0rem',
      paddingRight: '0rem',
      width: '130%',
      marginLeft: '-1.9rem',
      marginRight: '-1.2rem',
    },
    [theme.breakpoints.only('tablet')]: {
      paddingLeft: '0rem',
      paddingRight: '0rem',
      width: '130%',
      marginLeft: '-1.9rem',
      marginRight: '-1.2rem',
    },
  }),
  ConfirmPaymentContainer: {
    display: 'flex',
    justifyContent: 'center',
  },
  ButtonContainer: (theme) => ({
    display: 'flex',
    justifyContent: 'center',
    textAlign: 'center',
    marginTop: '6rem',
    width: '100%',
    backgroundColor: tokens.colors.white,
    marginBottom: '2rem',
    [theme.breakpoints.only('mobile')]: {
      display: 'inline-block',
    },
    [theme.breakpoints.down('desktop')]: {
      marginTop: '1rem',
    },
  }),
  ConfirmPaymentButton: {
    '&.Mui-disabled': {
      color: tokens.colors.warning.contrastText,
    },
  },
  link: (theme) => ({
    color: theme.palette.secondary.main,
    padding: 0,
  }),
  payOnAccountHeaderDescription: (theme) => ({
    fontSize: '1rem',
    lineHeight: '1.5rem',
    marginTop: '-1rem',
    [theme.breakpoints.only('mobile')]: {
      marginTop: '-2rem',
    },
  }),
  cancelAutopayContainer: {
    fontSize: '1.5rem',
    lineHeight: '1.875rem',
  },
  AutoPayDetail: (theme) => ({
    backgroundColor: tokens.colors.floralWhite,
    [theme.breakpoints.only('desktop')]: {
      justifyContent: 'end',
      marginTop: '-0.4rem',
      marginLeft: '0',
      height: 'fit-content',
    },
    [theme.breakpoints.only('mobile')]: {
      justifyContent: 'end',
      paddingLeft: '0rem',
      paddingRight: '0rem',
      width: '100%',
    },
    [theme.breakpoints.only('tablet')]: {
      justifyContent: 'start',
      paddingLeft: '0rem',
      paddingRight: '0rem',
      width: '100%',
    },
  }),
  FormArea: (theme) => ({
    gridColumn: 'col 1 / span 7',
    gridRow: 'row 1 / span 1',
    [theme.breakpoints.down('desktop')]: {
      gridColumn: 'col 1 / span 12',
    },
  }),
  MainForm: {
    gridColumn: 'col 1 / span 12',
    gridRow: 'row 2 / span 1',
  },
  AutopayCancellingMainForm: {
    gridColumn: 'col 1 / span 12',
    gridRow: 'row 0 / span 1',
  },
  AutoPayHeaderDescriptionOverride: (theme) => ({
    fontStyle: 'normal',
    color: tokens.colors.rsmText.primary,
    opacity: 1,
    fontSize: '1rem',
    lineHeight: '1.5rem',
    margin: '1rem 0 0',
    [theme.breakpoints.only('tablet')]: {
      paddingRight: '5rem',
    },
    [theme.breakpoints.only('mobile')]: {
      fontSize: '0.75rem',
      lineHeight: '1rem',
      fontWeight: 400,
    },
  }),
}

const BodyArea = styled('div')(() => ({
  backgroundColor: tokens.colors.white,
  display: 'flex',
  flexDirection: 'row',
  flexBasis: 'auto',
  flexWrap: 'wrap',
}))

const FormArea = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'row',
  flexBasis: 'auto',
  backgroundColor: tokens.colors.white,
  maxWidth: '65.625rem',
  [theme.breakpoints.down('desktop')]: {
    width: '100%',
  },
}))

const AutopayArea = styled('div')(() => ({
  backgroundColor: tokens.colors.white,
  width: '33%',
}))

interface PaymentTypeProps {
  isPayOnAccount?: boolean
  isAutopay?: boolean
}

const PaymentType = ({
  isPayOnAccount = false,
  isAutopay = false,
}: PaymentTypeProps) => {
  const methods = useForm({
    mode: 'onSubmit',
    shouldFocusError: false, // This is handled manually below
    reValidateMode: 'onChange',
  })
  const {
    handleSubmit,
    clearErrors,
    setValue,
    reset,
    formState: { errors },
    setFocus,
  } = methods
  const { t } = useTranslation()
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const { isDesktop } = useDeviceType()

  const hasPartialAmount = useSelector(getPartialAmount)
  const payableAmount = useSelector(getPayableAmount)
  const invoicePayments = useSelector(getInvoicePayments)
  const selectedBankAccount = useSelector(getSelectedBankAccount)
  const selectedCreditCard = useSelector(getSelectedCreditCard)
  const selectedPaymentMethod = useSelector(getSelectedPaymentMethod)
  const paymentMethodError = useSelector(getPaymentMethodError)
  const confirmPaymentError = useSelector(getConfirmPaymentError)
  const cemFeatures = useSelector(getCemFeatures)
  const payOnAccountClient = useSelector(getPayOnAccountClient)
  const isEditAutoPay = useSelector(getIsEditAutoPay)
  const editAutoPaySetupData = useSelector(getEditAutoPaySetup)
  const [formSubmitCount, setFormSubmitCount] = useState(0)
  const confirmButtonRef = useRef<HTMLButtonElement>(null)
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const [isAutopayCancelling, setIsAutopayCancelling] = useState(false)
  const [isAutopayCancelled, setIsAutopayCancelled] = useState(false)
  const [isGenericError, setIsGenericError] = useState(false)
  const [scheduledInvoicesData, setScheduledInvoicesData] = useState<
    ScheduledInvoicesPaymentViewModel[]
  >([])
  const [isDialogOpen, setIsDialogOpen] = useState(false)
  const paymentCategoryPayOnAccountAutoPay = isPayOnAccount
    ? t('Invoicing.PayOnAccount')
    : t('Invoicing.SetupAutoPay')
  const [selectedPaymentCategory, setSelectedPaymentCategory] =
    useState<string>(
      isPayOnAccount || isAutopay
        ? paymentCategoryPayOnAccountAutoPay
        : t('Invoicing.PayNow'),
    )
  const [closedCategories, setClosedCategories] = useState<string[]>([])
  const [isScheduledPaymentDisabled, setIsScheduledPaymentDisabled] =
    useState<boolean>(false)

  const unavailbleStatus: SelectedAccountTypeState = 'Unavailable'
  const unselectedStatus: SelectedAccountTypeState = 'Unselected'

  const isAccountManager = useMemo(
    () =>
      isCemFeatureEnabled(
        CEM_FEATURE_PAYMENT_ACCOUNT_MANAGEMENT,
        cemFeatures,
        'any',
      ),
    [cemFeatures],
  )

  const handleCloseDialog = () => {
    setClosedCategories([selectedPaymentCategory]) // Add closed category to the list
    setIsDialogOpen(false) // Close the dialog
  }

  const handleFormSubmit = async () => {
    dispatch(sethandleClicked(true))
    handleCloseDialog()

    if (Object.keys(errors).length > 0) {
      setFocus(String('errorsum'))
    }

    setFormSubmitCount((prev) => prev + 1)
  }

  const handlePaymentCategoryChange = (category: string) => {
    setSelectedPaymentCategory(category)
    reset({
      payer: null,
      paymentDate: null,
      numberOfInstallments: 2,
      numberOfInstallmentsOther: '',
      frequency: PaymentInstallmentFrequencyEnum.Monthly,
      paymentMethod: '',
      paymentCategory: category,
    })
    clearErrors([
      'payer',
      'paymentDate',
      'numberOfInstallments',
      'numberOfInstallmentsOther',
      'frequency',
      'paymentMethod',
    ])
    setFormSubmitCount(0)
    dispatch(setSelectedBankAccount(undefined))
    dispatch(setSelectedCreditCard(undefined))
    dispatch(setSelectedPayer(undefined))
  }

  const handlePayerChange = async (
    payer: InvoicePayerViewModel | undefined,
  ) => {
    dispatch(setSelectedBankAccount(undefined))
    dispatch(setSelectedCreditCard(undefined))
    dispatch(setSelectedPayer(payer))
    if (!payer) {
      // want want to disable the choose account/card dropdown when no payer selected
      // this occurrs for external users that have multiple payer options
      dispatch(setAccountSelectedType(unavailbleStatus))
    } else {
      dispatch(setAccountSelectedType(unselectedStatus))
    }
    setValue('payer', payer)
    clearErrors('payer')
  }

  const handleSessionChange = (sessionId: string, isError: boolean) => {
    if (isError) {
      setIsGenericError(true)
    }
    setIsLoading(false)
  }
  const defaultImplementAutoPayLimitType: ImplementAutoPayLimitType = 'No'

  const resetPaymentMethodStates = () => {
    dispatch(setSelectedBankAccount(undefined))
    dispatch(setSelectedCreditCard(undefined))
    dispatch(setSelectedPayer(undefined))
    dispatch(setAccountSelectedType(unselectedStatus))
    dispatch(setNewPaymentMethod(false))
    dispatch(setDoNotSave(false))
    dispatch(setImplementAutoPayLimit(defaultImplementAutoPayLimitType))
  }

  const handleBackToPreviousPage = () => {
    resetPaymentMethodStates()
    navigate('/invoicing/invoices/pay-invoices')
  }

  const handleCloseConfirmPaymentError = () => {
    setFormSubmitCount(0)
    dispatch(setDoNotSave(false))
    dispatch(setNewPaymentMethod(false))
    dispatch(setConfirmPaymentError(false))
    if (selectedPaymentMethod === 'BankAccount') {
      dispatch(setSelectedBankAccount(undefined))
    } else {
      dispatch(setSelectedCreditCard(undefined))
    }
    dispatch(setAccountSelectedType(unselectedStatus))
    reset(
      {
        paymentMethod: '',
      },
      { keepValues: true },
    )
    confirmButtonRef.current?.focus()
  }

  useEffect(() => {
    // Prevent user from accessing this page directly.
    if (
      (!isPayOnAccount && !isAutopay && invoicePayments.length === 0) ||
      (isPayOnAccount && !payOnAccountClient) ||
      (isAutopay && !payOnAccountClient)
    ) {
      navigate('/invoicing')
      // only set to unavilable if user is trying to directly access page
      dispatch(setAccountSelectedType(unavailbleStatus))
    }

    // unmount useEffect to reset store values when navigating away from this page
    return () => {
      resetPaymentMethodStates()
      setIsAutopayCancelled(false)
    }
  }, [])

  // When selected credit card or bank account has been updated programmatically, set the value in the form.
  useEffect(() => {
    setValue(
      'paymentMethod',
      selectedPaymentMethod === 'BankAccount'
        ? selectedBankAccount
        : selectedCreditCard,
    )
  }, [selectedBankAccount, selectedCreditCard])

  const handleButtonClick = () => {
    // checking to see if a bank account or credit card has not been selected
    // then we set the value of paymentMethod default and set shouldValidate to true to trigger validation
    if (!selectedBankAccount && !selectedCreditCard) {
      setValue('paymentMethod', 'default', { shouldValidate: true })
    }
    dispatch(sethandleClicked(true)) // Set state indicating button click
  }

  const invoiceNumbers = useMemo(
    () => invoicePayments.map((payment) => payment.invoiceNumber).join(', '),
    [invoicePayments],
  )

  const loadScheduledInvoicesData = useCallback(async () => {
    if (invoiceNumbers) {
      const { data: ScheduledInvoices } =
        await api.finance.payment_GetScheduledInvoices(invoiceNumbers, 1, true)
      const ScheduledInvoicesDataArray: ScheduledInvoicesPaymentViewModel[] =
        ScheduledInvoices ?? []
      setScheduledInvoicesData(ScheduledInvoicesDataArray)
    }
  }, [invoiceNumbers])

  useEffect(() => {
    if (invoiceNumbers) {
      loadScheduledInvoicesData()
    }
  }, [invoiceNumbers, loadScheduledInvoicesData])

  useEffect(() => {
    const shouldOpenDialog =
      scheduledInvoicesData.length > 0 &&
      !closedCategories.includes(selectedPaymentCategory) &&
      (selectedPaymentCategory === t('Invoicing.SchedulePayment') ||
        selectedPaymentCategory === t('Invoicing.SetupInstallments'))

    setIsDialogOpen(shouldOpenDialog)
  }, [
    scheduledInvoicesData,
    selectedPaymentCategory,
    closedCategories, // Add closedCategories as a dependency
  ])

  useEffect(() => {
    setClosedCategories([]) // Reset closed categories when selected category changes
  }, [selectedPaymentCategory])

  useEffect(() => {
    if (
      (scheduledInvoicesData && scheduledInvoicesData.length > 0) ||
      hasPartialAmount
    ) {
      setIsScheduledPaymentDisabled(true)
    } else {
      setIsScheduledPaymentDisabled(false)
    }
  }, [scheduledInvoicesData])

  const getAutoPayPrimaryHeaderText = () => {
    if (isAutopayCancelled) {
      return t('Invoicing.AutoPayPage.AutoPayCancelled')
    }
    return isEditAutoPay
      ? t('Invoicing.AutoPayPage.EditCancelAutoPay')
      : t('Invoicing.SetupAutoPay')
  }

  const getAutoPayHeaderDescription = () => {
    if (isAutopayCancelled || isAutopayCancelling) {
      return null
    }

    if (isEditAutoPay) {
      return (
        <Box>
          <Box>
            <Trans
              i18nKey="Invoicing.AutoPayPage.Note"
              components={{ b: <b /> }}
            />
          </Box>
          <Box>
            <Trans
              i18nKey="Invoicing.AutoPayPage.NextProcessingDate"
              components={{ b: <b /> }}
              values={{
                date:
                  getNextProcessingDate(
                    editAutoPaySetupData?.startDate,
                    editAutoPaySetupData?.recurrenceInterval,
                  ) ?? '',
              }}
            />
          </Box>
          <Box sx={{ paddingTop: '1rem' }}>
            {t('Invoicing.AutoPayPage.HeaderDescriptionEdit')}
          </Box>
        </Box>
      )
    }
    return (
      <Box>
        <Box>
          {isAccountManager
            ? t('Invoicing.AutoPayPage.HeaderDescriptionAccountManagement')
            : t('Invoicing.AutoPayPage.HeaderDescriptionDashboard')}
        </Box>
        <Box sx={{ paddingTop: '1rem' }}>
          <Trans
            i18nKey="Invoicing.AutoPayPage.Note"
            components={{ b: <b /> }}
          />
        </Box>
      </Box>
    )
  }

  const getCancelAutopayCheckBox = () => {
    if (!isEditAutoPay || isAutopayCancelled || isPayOnAccount) {
      return null
    }

    return (
      <Box sx={styles.cancelAutopayContainer}>
        <Checkbox
          id="cancelAutoPay"
          icon={<CheckboxOutlinedIcon />}
          checkedIcon={<CheckboxIcon />}
          data-testid="Chk_Cancel_AutoPay"
          onChange={(e) => setIsAutopayCancelling(e.target.checked)}
        />
        <label htmlFor="cancelAutoPay">
          {t('Invoicing.AutoPayPage.CancelAutoPay')}
        </label>
      </Box>
    )
  }
  const getPayOnAccountHeader = () => {
    const openAmount = Number(payOnAccountClient?.totalOpenAmount)

    const shouldHideOpenAmountText = isAutopay
    let headerText = ''

    if (!(isPayOnAccount && !isAutopayCancelled)) {
      if (!shouldHideOpenAmountText) {
        headerText = t('Invoicing.OpenAmount')
      }
    }

    const displayedAmount = isAutopay || isPayOnAccount ? undefined : openAmount

    const headerDescription = (
      <Box sx={styles.payOnAccountHeaderDescription}>
        {isPayOnAccount
          ? t('Invoicing.PayOnAccountPage.HeaderDescription')
          : getAutoPayHeaderDescription()}
        {isPayOnAccount && (
          <Button
            data-testid="Btn_PayOnAccount_ReturnLink"
            component="span"
            disableFocusRipple
            disableRipple
            role="link"
            sx={styles.link}
            onClick={() => {
              resetPaymentMethodStates()
              navigate('/invoicing/invoices')
            }}>
            <Box component="span" sx={{ textDecoration: 'underline' }}>
              {t('Invoicing.PayOnAccountPage.OpenInvoices')}
            </Box>
          </Button>
        )}
      </Box>
    )
    return (
      <InvoicesHeader
        primaryHeaderId="PayOnAccount"
        primaryHeaderText={
          isPayOnAccount
            ? t('Invoicing.PayOnAccount')
            : getAutoPayPrimaryHeaderText()
        }
        primaryHeaderDescription={headerDescription}
        headerDescriptionStyleOverride={styles.AutoPayHeaderDescriptionOverride}
        backButtonId={
          isAccountManager
            ? 'BackToAccountManagementPage'
            : 'BackToDashboardPage'
        }
        backButtonText={
          isAccountManager
            ? t('Invoicing.PayOnAccountPage.BackToAccountManagement')
            : t('Invoicing.PayOnAccountPage.BackToDashboard')
        }
        handleNavigation={() => {
          resetPaymentMethodStates()
          if (isAccountManager) {
            navigate(
              `/invoicing/account-management/${payOnAccountClient?.clientId}`,
            )
          } else {
            navigate(`/invoicing/dashboard/${payOnAccountClient?.clientId}`)
          }
        }}
        amountHeaderId="OpenAmount"
        amountHeaderText={isAutopayCancelled ? '' : headerText}
        amount={isAutopayCancelled ? undefined : displayedAmount}
        bottomSlot={getCancelAutopayCheckBox()}
      />
    )
  }

  return (
    <>
      <Spinner open={isLoading} />
      <CustomErrorAlert
        testId="Banner_PaymentType_PaymentUnableToProcess"
        open={confirmPaymentError}
        close={handleCloseConfirmPaymentError}
        disableAutoHide
        header="Alert.PaymentFailed"
        message="Alert.PaymentUnableToProcess"
      />
      <CustomErrorAlert
        testId="Banner_PaymentType_GenericErrorMessage"
        header="Alert.GenericErrorHeader"
        open={isGenericError}
        close={() => setIsGenericError(false)}
        disableAutoHide
        message="Alert.GenericErrorMessage"
      />
      <CustomErrorAlert
        testId="Banner_PaymentType_PaymentMethodError"
        open={paymentMethodError}
        close={() => dispatch(setPaymentMethodError(false))}
        disableAutoHide
        header="Alert.PaymentMethodErrorHeader"
        message="Alert.PaymentMethodError"
      />
      {isPayOnAccount || isAutopay ? (
        getPayOnAccountHeader()
      ) : (
        <InvoicesHeader
          primaryHeaderId="PaymentType"
          primaryHeaderText={t('Invoicing.Payment')}
          primaryHeaderDescription={t('Invoicing.PaymentSelectionWillApply')}
          backButtonId="BackToPreviousPage"
          backButtonText={t('Invoicing.BackToPreviousPage')}
          handleNavigation={handleBackToPreviousPage}
          amountHeaderId="TotalPayableAmount"
          amountHeaderText={t('Invoicing.TotalPayableAmount')}
          amount={payableAmount}
        />
      )}
      <Box
        sx={{
          display: 'grid',
          gridTemplateColumns: 'repeat(12, [col] 1fr)',
          gridTemplateRows: '[row] auto [row] auto',
        }}>
        <BodyArea
          sx={
            isEditAutoPay
              ? styles.AutoPayEditPaymentTypeContainer
              : styles.PaymentTypeContainer
          }>
          {!isAutopayCancelled && (
            <Box
              sx={{
                display: 'grid',
                gridTemplateColumns: 'repeat(12, [col] 1fr)',
                width: '100%',
              }}>
              {!isAutopayCancelling && (
                <FormArea sx={styles.FormArea}>
                  <Box sx={styles.ErrorSumContainer}>
                    {/* eslint-disable-next-line react/jsx-props-no-spreading */}
                    <FormProvider {...methods}>
                      <PaymentCategoriesErrosDialog
                        selectedPaymentCategory={selectedPaymentCategory}
                      />
                    </FormProvider>
                  </Box>
                </FormArea>
              )}
              <Box
                sx={
                  isAutopayCancelling
                    ? styles.AutopayCancellingMainForm
                    : styles.MainForm
                }>
                <form
                  style={{ width: '100%', marginTop: '0rem' }}
                  onSubmit={handleSubmit(() => handleFormSubmit())}>
                  <FormArea
                    sx={isAutopayCancelling ? { maxWidth: '100%' } : null}>
                    <Box style={{ padding: '0 ', width: '100%' }}>
                      {/* eslint-disable-next-line react/jsx-props-no-spreading */}
                      <FormProvider {...methods}>
                        {isPayOnAccount && (
                          <PayOnAccount
                            formSubmitCount={formSubmitCount}
                            sessionCreating={() => setIsLoading(true)}
                            sessionChange={handleSessionChange}
                            payerChange={handlePayerChange}
                          />
                        )}
                        {isAutopay && (
                          <AutoPay
                            formSubmitCount={formSubmitCount}
                            payerChange={handlePayerChange}
                            sessionCreating={() => setIsLoading(true)}
                            sessionChange={handleSessionChange}
                            isCancelling={isAutopayCancelling}
                            onCancelled={() => setIsAutopayCancelled(true)}
                          />
                        )}
                        {!isPayOnAccount && !isAutopay && (
                          <>
                            <PaymentCategory
                              paymentCategoryChanged={
                                handlePaymentCategoryChange
                              }
                              isScheduledPaymentDisabled={
                                isScheduledPaymentDisabled
                              }
                            />

                            {selectedPaymentCategory ===
                              t('Invoicing.PayNow') && (
                              <PaymentTypePayNow
                                formSubmitCount={formSubmitCount}
                                sessionCreating={() => setIsLoading(true)}
                                sessionChange={handleSessionChange}
                                payerChange={handlePayerChange}
                              />
                            )}

                            {selectedPaymentCategory ===
                              t('Invoicing.SchedulePayment') && (
                              <PaymentTypeScheduled
                                formSubmitCount={formSubmitCount}
                                sessionCreating={() => setIsLoading(true)}
                                sessionChange={handleSessionChange}
                                payerChange={handlePayerChange}
                              />
                            )}

                            {selectedPaymentCategory ===
                              t('Invoicing.SetupInstallments') && (
                              <PaymentTypeInstallments
                                formSubmitCount={formSubmitCount}
                                sessionCreating={() => setIsLoading(true)}
                                sessionChange={handleSessionChange}
                                payerChange={handlePayerChange}
                              />
                            )}
                          </>
                        )}
                      </FormProvider>
                    </Box>
                  </FormArea>
                  {isDesktop && (
                    <AutopayArea>
                      <span /> {/* Autopay box will go here */}
                    </AutopayArea>
                  )}
                  {!isAutopayCancelling && (
                    <Box sx={styles.ButtonContainer}>
                      <Box sx={styles.ConfirmPaymentContainer}>
                        <Button
                          type="submit"
                          disableFocusRipple
                          ref={confirmButtonRef}
                          sx={styles.ConfirmPaymentButton}
                          variant="contained"
                          disabled={
                            scheduledInvoicesData.length > 0 &&
                            (selectedPaymentCategory ===
                              t('Invoicing.SchedulePayment') ||
                              selectedPaymentCategory ===
                                t('Invoicing.SetupInstallments'))
                          }
                          data-test-id="Btn_Payment_ConfirmPayment"
                          onClick={handleButtonClick}>
                          {t('Invoicing.Continue')}
                        </Button>
                      </Box>
                    </Box>
                  )}
                  {isPayOnAccount && (
                    <Box sx={{ paddingBottom: '2rem', textAlign: 'center' }}>
                      {t('Invoicing.PayOnAccountPage.AssistanceMessage')}
                    </Box>
                  )}
                  <ScheduledInvoicesDialog
                    Dialogtext={t(
                      'Invoicing.ScheduledinvoicesDialog.DialogText',
                    )}
                    Subtext1={t('Invoicing.ScheduledinvoicesDialog.SubText1')}
                    Subtext2={t('Invoicing.ScheduledinvoicesDialog.SubText2')}
                    buttonCloseText={t(
                      'Invoicing.ScheduledinvoicesDialog.Close',
                    )}
                    open={isDialogOpen}
                    onClose={handleCloseDialog}
                    scheduledInvoicesData={scheduledInvoicesData}
                  />
                </form>
              </Box>
            </Box>
          )}
        </BodyArea>
      </Box>
    </>
  )
}

export default PaymentType
