import {
  faAngleDoubleDown,
  faAngleDoubleUp,
} from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Button, SxProps, Theme, Typography } from '@mui/material'
import { t } from 'i18next'
import React, { useEffect, useRef } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { RootState } from '../../../store'
import { setExpandAll } from '../../../store/faqs/faqsSlice'
import { Styles } from '../../../types'
import { isFeatureFlagEnabled } from '../../../rsmCoreComponents/utils/featureFlagUtils'

const styles: Styles = {
  buttonText: {
    marginRight: '1rem',
    fontSize: '1.125rem',
  },
  buttonRedesignText: {
    marginLeft: '1rem',
    fontSize: '1.125rem',
  },
}

interface ExpandButtonProps {
  testId?: string
  sx?: SxProps<Theme> | undefined
}

const ExpandButton = ({
  testId = undefined,
  sx = undefined,
}: ExpandButtonProps) => {
  const dispatch = useDispatch()
  const { expandAll, hasFocus } = useSelector((state: RootState) => state.faqs)
  const buttonRef = useRef<HTMLButtonElement>(null)
  const isFAQRedesignEnabled = isFeatureFlagEnabled('FAQRedesign')

  const handleToggle = () => {
    dispatch(setExpandAll(!expandAll))
  }

  useEffect(() => {
    if (hasFocus === 'expand-all') {
      buttonRef?.current?.focus()
    }
  }, [expandAll])

  const testIdWithState = testId + (expandAll ? '_Collapse' : '_Expand')

  return (
    <Button
      sx={sx}
      ref={buttonRef}
      aria-live="polite"
      onClick={handleToggle}
      data-testid={testIdWithState}
      disableFocusRipple>
      {!isFAQRedesignEnabled && (
        <Typography component="span" variant="h4" sx={styles.buttonText}>
          {expandAll
            ? t('DocumentRequests.Filters.CollapseAll')
            : t('DocumentRequests.Filters.ExpandAll')}
        </Typography>
      )}
      <FontAwesomeIcon
        height="0.75rem"
        icon={expandAll ? faAngleDoubleUp : faAngleDoubleDown}
      />
      {isFAQRedesignEnabled && (
        <Typography
          component="span"
          variant="h4"
          sx={styles.buttonRedesignText}>
          {expandAll
            ? t('DocumentRequests.Filters.CollapseAll').toLowerCase()
            : t('DocumentRequests.Filters.ExpandAll').toLowerCase()}
        </Typography>
      )}
    </Button>
  )
}

export default ExpandButton
