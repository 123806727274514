import React, { useState, useRef } from 'react'
import { Box, Button, CircularProgress } from '@mui/material'
import FileDownloadOutlinedIcon from '@mui/icons-material/FileDownloadOutlined'
import { useSelector } from 'react-redux'
import { getInvoiceCounts } from 'src/store/invoices/invoiceSelectedFiltersSlice'
import { getPaymentCounts } from 'src/store/invoices/paymentSelectedFiltersSlice'
import InvoiceExportModal from './InvoiceExportModal'
import PaymentHistoryExportModal from './PaymentHistoryExportModal'
import { Styles } from '../../../types'
import { isFeatureFlagEnabled } from '../../../rsmCoreComponents/utils/featureFlagUtils'

export type CountPair = {
  filtered: number
  total: number
}

export const isLoadingCount = ({ filtered, total }: CountPair): boolean =>
  filtered === 0.5 || (filtered >= 0 && total === 0)

export const isDisabledCount = ({ filtered, total }: CountPair): boolean =>
  (filtered === 0 && total === 0) ||
  isLoadingCount({ filtered, total }) ||
  (filtered >= 0 && total === 0)

const styles: Styles = {
  ExportAlign: () => ({
    display: 'flex',
    justifyContent: { xs: 'flex-start', md: 'flex-end', sm: 'center' },
    width: { xs: '100%', sm: 'auto', md: 'auto' },
    mt: { xs: 2, md: 0 },
    flex: { md: 1 },
  }),
  ExportButton: (theme) => ({
    color: theme.palette.secondary.main,
    fontSize: '1rem',
    fontWeight: 'bold',
    textTransform: 'none',
    textDecoration: 'underline',
    minWidth: '2.75rem',
    minHeight: '2.75rem',
    padding: '0.5rem',
    '&:hover': {
      backgroundColor: 'transparent',
      textDecoration: 'none',
    },
    [theme.breakpoints.up('mobile')]: {
      textDecoration: 'none',
    },
    [theme.breakpoints.up('desktop')]: {
      paddingLeft: '3.125rem',
    },
  }),
}

interface ExportButtonProps {
  isPaymentHistory?: boolean
  buttonText?: string
}

const ExportButton = ({
  isPaymentHistory = false,
  buttonText = 'Export',
}: ExportButtonProps) => {
  const [openExportModal, setOpenExportModal] = useState<boolean>(false)
  const exportButtonRef = useRef<HTMLButtonElement>(null)
  const { filteredInvoices, totalInvoices } = useSelector(getInvoiceCounts)
  const { filteredPayments, totalPayments } = useSelector(getPaymentCounts)

  const invoiceCounts: CountPair = {
    filtered: filteredInvoices,
    total: totalInvoices,
  }
  const paymentCounts: CountPair = {
    filtered: filteredPayments,
    total: totalPayments,
  }

  const currentCounts = isPaymentHistory ? paymentCounts : invoiceCounts
  const isLoading = isLoadingCount(currentCounts)
  const isDisabled = isDisabledCount(currentCounts)

  const ExportModal = isPaymentHistory
    ? PaymentHistoryExportModal
    : InvoiceExportModal

  return (
    <Box sx={styles.ExportAlign}>
      {isFeatureFlagEnabled('Invoicing_ExportTableData') && (
        <Box sx={{ display: 'flex', alignItems: 'center', gap: '0.5rem' }}>
          <Button
            data-testid="Btn_Invoice_Export"
            variant="text"
            color="primary"
            startIcon={<FileDownloadOutlinedIcon aria-hidden="true" />}
            sx={styles.ExportButton}
            onClick={() => setOpenExportModal(true)}
            disabled={isDisabled}
            ref={exportButtonRef}>
            {buttonText}
          </Button>

          {isLoading && (
            <CircularProgress
              size="1.4rem"
              color="secondary"
              data-testid="Spn_AccountInformation_Loading"
            />
          )}
        </Box>
      )}

      <ExportModal
        open={openExportModal}
        onClose={() => setOpenExportModal(false)}
      />
    </Box>
  )
}

export default ExportButton
