import React, { ReactNode } from 'react'
import { Box, Button, styled } from '@mui/material'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { Heading } from '../../../../components/typography'
import { tokens } from '../../../../styles/materialTheme'
import { getUserInfo } from '../../../../store/userInfo/userInfoSlice'
import { Styles } from '../../../../types'

const StyledSection = styled('section')(({ theme }) => ({
  backgroundColor: `${tokens.colors.white}`,
  paddingTop: '2.5rem',
  paddingBottom: '2.5rem',
  paddingLeft: '7.25rem',
  paddingRight: '7.25rem',
  [theme.breakpoints.only('mobile')]: {
    paddingLeft: '1.75rem',
    paddingRight: '1.75rem',
  },
  [theme.breakpoints.only('tablet')]: {
    paddingLeft: '2.75rem',
    paddingRight: '2.75rem',
  },
}))

const styles: Styles = {
  boxStyles: (theme) => ({
    display: 'flex',
    alignItems: 'center',
    gap: '0.5rem',
    fontSize: '1rem',
    fontFamily: 'Prelo-Book, sans-serif',
    color: theme.palette.secondary.main,
    fontWeight: '800',
    lineHeight: '1.5rem',
  }),
  dashButton: (theme) => ({
    maxHeight: '3.125rem',
    [theme.breakpoints.only('mobile')]: {
      width: '100%',
    },
  }),
}

interface SupportFormCardProps {
  desc: string
  children: ReactNode
  handleSubmit: () => void
  submitBtnDisabled?: boolean
  testId: string
  isDashUser?: boolean
  isDashIssue?: boolean | undefined
  dashDropdown?: ReactNode | undefined
}

const SupportFormCard = ({
  desc,
  children,
  handleSubmit,
  submitBtnDisabled = false,
  testId,
  isDashUser = false,
  isDashIssue = undefined,
  dashDropdown = undefined,
}: SupportFormCardProps) => {
  const { t } = useTranslation()
  const userInfo = useSelector(getUserInfo)
  const internalPhone = '877-462-7573'
  const externalPhone = '833-518-3394'
  const supportPhone = userInfo.isEmployee ? internalPhone : externalPhone

  return (
    <form onSubmit={handleSubmit}>
      <Box
        borderColor={tokens.colors.rsmGray.disabled}
        sx={{
          flexWrap: 'wrap',
        }}>
        <StyledSection data-testid="Sec_Support_FormBox">
          <Heading variant="h1" testId="Hed_Support_SupportFormPage">
            {t('RSMSupportHeading')}
          </Heading>
          {(!isDashUser ||
            (isDashUser && isDashIssue !== undefined && !isDashIssue)) && (
            <>
              <Box
                data-testid={`Lbl_${testId}_Description`}
                sx={{
                  display: 'grid',
                  gridTemplateColumns: 'repeat(1,1fr)',
                  gap: '0.5rem',
                  paddingBottom: '1.0rem',
                  paddingTop: '1.5rem',
                  fontSize: '1rem',
                  fontFamily: 'Prelo-Book, sans-serif',
                }}>
                {desc}
              </Box>
              <Box
                data-testid={`Lbl_${testId}_CustomerService`}
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  gap: '0.5rem',
                  paddingBottom: '2.5rem',
                  fontFamily: 'Prelo-Book, sans-serif',
                }}>
                {t('CustomerService')}
                <Box sx={styles.boxStyles}>
                  <a
                    data-testid="Lnk_Support_CallUsCardPhoneNumber"
                    href={`tel:${supportPhone?.replaceAll('-', '')}`}
                    className="font-prelo-book mobile:text-[1rem] mobile:leading-[1.5rem] text-[1rem] leading-[1.5rem] block mt-[0.25rem]">
                    {supportPhone}
                  </a>
                </Box>
              </Box>
              {isDashUser && dashDropdown}
              {children}
            </>
          )}
          {isDashUser && (isDashIssue === undefined || isDashIssue) && (
            <Box sx={{ marginTop: '2rem' }}>{dashDropdown}</Box>
          )}
          <Box
            sx={{
              display: 'flex',
              flex: '1 1 0%',
              justifyContent: 'center',
              paddingTop: '2rem',
            }}>
            {isDashUser && isDashIssue ? (
              <Button
                variant="contained"
                target="_blank"
                role="button"
                href="https://dash.rsmus.com/csm"
                sx={styles.dashButton}
                color="primary"
                data-testid={`Btn_${testId}_ProceedToDash`}>
                {t('ProceedToDash')}
              </Button>
            ) : (
              <Button
                variant="contained"
                type="submit"
                sx={{ maxHeight: '3.125rem' }}
                color="primary"
                disabled={submitBtnDisabled}
                data-testid={`Btn_${testId}_Submit`}>
                {t('Submit')}
              </Button>
            )}
          </Box>
          {(!isDashUser ||
            (isDashUser && isDashIssue !== undefined && !isDashIssue)) && (
            <Box
              data-testid={`Lbl_${testId}_Description`}
              sx={{
                display: 'flex',
                flex: '1 1 0%',
                justifyContent: 'center',
                paddingTop: '2rem',
                fontSize: '1rem',
                fontFamily: 'Prelo-Book, sans-serif',
              }}>
              {t('SupportPageFormFooterDesc')}
            </Box>
          )}
        </StyledSection>
      </Box>
    </form>
  )
}

export default SupportFormCard
