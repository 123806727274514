import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import type { RootState } from '..'

interface SupportState {
  isDashIssue: boolean
  selectedSupportCategory: number | undefined
  showSuccessBanner: boolean
  showFeedbackSuccessBanner: boolean
}

const initialState: SupportState = {
  isDashIssue: false,
  selectedSupportCategory: undefined,
  showSuccessBanner: false,
  showFeedbackSuccessBanner: false,
}

export const supportSlice = createSlice({
  name: 'support',
  initialState,
  reducers: {
    setIsDashIssue: (state, action: PayloadAction<boolean>) => {
      state.isDashIssue = action.payload
    },
    setSelectedSupportCategory: (
      state,
      action: PayloadAction<number | undefined>,
    ) => {
      state.selectedSupportCategory = action.payload
    },
    setShowSuccessBanner: (state, action: PayloadAction<boolean>) => {
      state.showSuccessBanner = action.payload
    },
    setFeedBackShowSuccessBanner: (state, action: PayloadAction<boolean>) => {
      state.showFeedbackSuccessBanner = action.payload
    },
  },
})

export const getIsDashIssue = (state: RootState) => state.support.isDashIssue
export const getSelectedSupportCategory = (state: RootState) =>
  state.support.selectedSupportCategory
export const getShowSuccessBanner = (state: RootState) =>
  state.support.showSuccessBanner
export const getFeedbackShowSuccessBanner = (state: RootState) =>
  state.support.showFeedbackSuccessBanner

export const {
  setIsDashIssue,
  setSelectedSupportCategory,
  setShowSuccessBanner,
  setFeedBackShowSuccessBanner,
} = supportSlice.actions

export default supportSlice.reducer
