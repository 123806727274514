import React, { useState, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import {
  FormControl,
  Radio,
  RadioGroup,
  styled,
  FormControlLabel,
} from '@mui/material'
import { Control, Controller } from 'react-hook-form'

const StyledFormControlLabel = styled(FormControlLabel)(
  ({ theme: { palette } }) => ({
    '.MuiFormControlLabel-label': {
      fontFamily: 'Prelo-Book',
      color: palette.text.primary,
    },
  }),
)

interface FormPreferredContactRadioProps {
  control: Control<any>
  handleRadioChange: (value: string) => void
  contactMethod: string
}

const FormFeedbackRadioButtons: React.FC<FormPreferredContactRadioProps> = ({
  control,
  handleRadioChange,
  contactMethod,
}) => {
  const [selectedContact, setSelectedContact] = useState(contactMethod)
  const { t } = useTranslation()

  useEffect(() => {
    setSelectedContact(contactMethod)
  }, [contactMethod])

  return (
    <FormControl component="fieldset" style={{ padding: '1rem 0' }}>
      <Controller
        name="preferredContactMethod"
        control={control}
        defaultValue={selectedContact}
        render={() => (
          <RadioGroup
            row
            name="preferredContactMethod"
            value={selectedContact}
            onChange={(e) => {
              const { value } = e.target
              setSelectedContact(value)
              handleRadioChange(value)
            }}
            sx={{
              textAlign: 'left',
              marginBottom: '1rem',
              gap: '2rem',
              justifyContent: 'flex-start',
            }}>
            <StyledFormControlLabel
              value="Email"
              control={<Radio />}
              label={t('FeedbackPage.ContactPreferenceEmail')}
            />
            <StyledFormControlLabel
              value="Phone"
              control={<Radio />}
              label={t('FeedbackPage.ContactPreferencePhone')}
            />
            <StyledFormControlLabel
              value="None"
              control={<Radio />}
              label={t('FeedbackPage.ContactPreferenceNone')}
            />
          </RadioGroup>
        )}
      />
    </FormControl>
  )
}

export default FormFeedbackRadioButtons
