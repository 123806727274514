/* eslint-disable react/jsx-props-no-spreading */
import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { Box, styled } from '@mui/material'
import { useDebounce } from 'usehooks-ts'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider'
import { DatePicker as MuiDatePicker } from '@mui/x-date-pickers/DatePicker'
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCalendar } from '@fortawesome/free-regular-svg-icons'
import { useTranslation } from 'react-i18next'
import RsmPickersDay from '../../../rsmCoreComponents/components/DynamicForm/FormControls/FormDatePicker/RsmPickersDay'
import { Styles } from '../../../types'
import '../../../rsmCoreComponents/components/FiltersGroup/FiltersGroup.css'
import {
  getPaymentSelectedFilters,
  PaymentSelectedFiltersConstants,
  setFilterValue,
} from '../../../store/invoices/paymentSelectedFiltersSlice'
import InvoicesFilterCategoryContainer from './InvoicesFilterCategoryContainer'
import usePaymentsSearch from './usePaymentsSearch'
import tokens from '../../../styles/tokens.json'
import { handleStartDateError, handleEndDateError } from './dateUtils'
import {
  getEndDateError,
  getStartDateError,
} from './InvoicesDateRangeFilters.service'

const zIndex = 4000

const styles: Styles = {
  datePickerBox: {
    padding: '0.5rem 2.75rem 0.5rem 0.5625rem',
    '& .MuiInputBase-root:focus-within': {
      boxShadow: 'revert !important',
    },
  },
  datePickerTextInput: {
    '& .MuiOutlinedInput-input': {
      textTransform: 'uppercase',
    },
  },
  styledLink: () => ({
    color: 'primary',
  }),
  titleBox: {
    width: '100%',
    paddingRight: '2.75rem',
    justifyContent: 'space-between',
    display: 'flex',
  },
  error: {
    color: tokens.colors.rsmRed.secondary,
    marginLeft: '0.5rem',
    fontSize: '0.75rem',
    lineHeight: '0.75rem',
  },
}

const DatePicker = styled(MuiDatePicker)(() => ({
  '& .MuiPaper-root': {},
}))

interface PaymentFiltersDateRangeProps {
  filterCategory: string
  clearCategoryFilter: (s: string) => void
  changeFilterAndSearch: (s: string) => void
}

const PaymentsFiltersDateRange = ({
  filterCategory,
  clearCategoryFilter,
  changeFilterAndSearch,
}: PaymentFiltersDateRangeProps) => {
  const { t } = useTranslation()
  const { changeFilterValue } = usePaymentsSearch()
  const closeOnSelect = true
  const showToolbar = false
  const unmountOnExit = false
  // Use '50' to set min year to 1/1/1950 at midnight CST. (Using '1950' would set it to 12/31/1949 at 6pm CST)
  const minYear = new Date('50')
  const maxYear = new Date((new Date().getFullYear() + 28).toString())
  const startDateName = 'startdate'
  const endDateName = 'enddate'
  const CalendarIcon = React.memo(() => <FontAwesomeIcon icon={faCalendar} />)
  const selectedFilters = useSelector(getPaymentSelectedFilters)
  const [startDateError, setStartDateError] = useState<boolean>(false)
  const [endDateError, setEndDateError] = useState<boolean>(false)
  const [startDate, setStartDate] = useState<Date | null>(null)
  const [endDate, setEndDate] = useState<Date | null>(null)
  const [startDateDebounceValue, setStartDateDebounce] = useState<Date | null>(
    null,
  )
  const [endDateDebounceValue, setEndDateDebounce] = useState<Date | null>(null)
  const startDateDebounce = useDebounce(startDateDebounceValue, 500)
  const endDateDebounce = useDebounce(endDateDebounceValue, 500)
  const localeOptions: Intl.DateTimeFormatOptions = {
    month: '2-digit',
    day: '2-digit',
    year: 'numeric',
  }

  const clearDatePickers = () => {
    setStartDate(null)
    setEndDate(null)
  }

  const handleOnChange = () => {
    if (
      selectedFilters.paymentStartDate &&
      selectedFilters.paymentEndDate &&
      selectedFilters.paymentStartDate >= minYear &&
      selectedFilters.paymentEndDate <= maxYear &&
      selectedFilters.paymentStartDate <= selectedFilters.paymentEndDate
    ) {
      const invoiceDateRange = `${selectedFilters.paymentStartDate.toLocaleDateString(
        'en-US',
        localeOptions,
      )} - ${selectedFilters.paymentEndDate.toLocaleDateString(
        'en-US',
        localeOptions,
      )}`
      changeFilterAndSearch(
        setFilterValue(
          PaymentSelectedFiltersConstants.paymentDates,
          invoiceDateRange,
        ),
      )
    }
  }

  const onDateChange = (filter: string, newDate: Date | null) => {
    const filterString = setFilterValue(filter, newDate)

    if (filter === PaymentSelectedFiltersConstants.paymentStartDate) {
      setStartDateDebounce(newDate)
      setStartDate(newDate)
    } else {
      setEndDateDebounce(newDate)
      setEndDate(newDate)
    }

    if (newDate === null) {
      changeFilterAndSearch(filterString)
    } else {
      changeFilterValue(filterString)
    }
  }

  useEffect(() => {
    if (startDateDebounce) {
      handleOnChange()
    }
  }, [startDateDebounce])

  useEffect(() => {
    if (endDateDebounce) {
      handleOnChange()
    }
  }, [endDateDebounce])

  useEffect(() => {
    if (selectedFilters.paymentStartDate === null && startDate !== null) {
      setStartDate(null)
    } else if (
      selectedFilters.paymentStartDate !== null &&
      startDate === null
    ) {
      setStartDate(selectedFilters.paymentStartDate)
    }
  }, [selectedFilters.paymentStartDate])

  useEffect(() => {
    if (selectedFilters.paymentEndDate === null && endDate !== null) {
      setEndDate(null)
    } else if (selectedFilters.paymentEndDate !== null && endDate === null) {
      setEndDate(selectedFilters.paymentEndDate)
    }
  }, [selectedFilters.paymentEndDate])

  useEffect(() => {
    handleStartDateError(startDate, endDate, setStartDateError)
    handleEndDateError(startDate, endDate, setEndDateError)
  }, [startDate, endDate])

  return (
    <InvoicesFilterCategoryContainer
      filterCategory={filterCategory}
      clearCategoryFilter={() => {
        clearDatePickers()
        clearCategoryFilter(filterCategory)
      }}
      unmountOnExit={unmountOnExit}>
      <LocalizationProvider dateAdapter={AdapterDateFns}>
        <>
          <Box sx={styles.datePickerBox}>
            <DatePicker
              label={t('Invoicing.FilterGroups.startdate')}
              value={
                startDate !== null &&
                startDate !== selectedFilters.paymentStartDate
                  ? startDate
                  : selectedFilters.paymentStartDate
              }
              minDate={minYear} // January 1, 1950
              maxDate={endDate === null ? maxYear : endDate}
              format="MM/dd/yyyy"
              timezone="system"
              slots={{ day: RsmPickersDay, openPickerIcon: CalendarIcon }}
              slotProps={{
                textField: {
                  id: startDateName,
                  name: startDateName,
                  sx: styles.datePickerTextInput,
                  fullWidth: true,
                  autoComplete: 'off',
                  error: startDateError,
                  inputProps: {
                    'aria-describedby': `${startDateName}`,
                    'data-testid': `${startDateName}-test-id`,
                  },
                },
                popper: {
                  placement: 'bottom',
                  style: { zIndex },
                  'aria-label': 'Calendar',
                },
                dialog: {
                  style: { zIndex },
                },
                actionBar: {
                  sx: { display: 'none' },
                },
                toolbar: {
                  hidden: !showToolbar,
                },
              }}
              onChange={(newDate) => {
                onDateChange(
                  PaymentSelectedFiltersConstants.paymentStartDate,
                  newDate,
                )
              }}
              onError={(reason) => {
                if (reason) {
                  setStartDateError(true)
                } else {
                  setStartDateError(false)
                }
              }}
              closeOnSelect={closeOnSelect}
            />
            {startDateError && (
              <Box
                id={`${startDateName}`}
                component="span"
                sx={styles.error}
                role="alert">
                {getStartDateError(selectedFilters.paymentStartDate)}
              </Box>
            )}
          </Box>
          <Box sx={styles.datePickerBox}>
            <DatePicker
              label={t('Invoicing.FilterGroups.enddate')}
              value={
                endDate !== null && endDate !== selectedFilters.paymentEndDate
                  ? endDate
                  : selectedFilters.paymentEndDate
              }
              minDate={startDate === null ? undefined : startDate}
              maxDate={maxYear}
              format="MM/dd/yyyy"
              timezone="system"
              slots={{ day: RsmPickersDay, openPickerIcon: CalendarIcon }}
              slotProps={{
                textField: {
                  id: endDateName,
                  name: endDateName,
                  sx: styles.datePickerTextInput,
                  fullWidth: true,
                  autoComplete: 'off',
                  error: endDateError,
                  inputProps: {
                    'aria-describedby': `${endDateName}`,
                    'data-testid': `${endDateName}-test-id`,
                  },
                },
                popper: {
                  placement: 'bottom',
                  style: { zIndex },
                  'aria-label': 'Calendar',
                },
                dialog: {
                  style: { zIndex },
                },
                actionBar: {
                  sx: { display: 'none' },
                },
                toolbar: {
                  hidden: !showToolbar,
                },
              }}
              onChange={(newDate) => {
                onDateChange(
                  PaymentSelectedFiltersConstants.paymentEndDate,
                  newDate,
                )
              }}
              onError={(reason) => {
                if (reason) {
                  setEndDateError(true)
                } else {
                  setEndDateError(false)
                }
              }}
              closeOnSelect={closeOnSelect}
            />
            {endDateError && (
              <Box
                id={`${endDateName}`}
                component="span"
                sx={styles.error}
                role="alert">
                {getEndDateError(selectedFilters.paymentEndDate)}
              </Box>
            )}
          </Box>
        </>
      </LocalizationProvider>
    </InvoicesFilterCategoryContainer>
  )
}

export default PaymentsFiltersDateRange
