import { Box } from '@mui/material'
import React, { useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { isCemFeatureEnabled } from 'src/rsmCoreComponents/utils/featureFlagUtils'
import { getCemFeatures } from 'src/store/userInfo/userInfoSlice'
import { useSelector, useDispatch } from 'react-redux'
import {
  getExportStatus,
  clearExportStatus,
} from 'src/store/invoices/invoiceSelectedFiltersSlice'
import {
  getPaymentExportStatus,
  clearPaymentExportStatus,
} from 'src/store/invoices/paymentSelectedFiltersSlice'
import CustomErrorAlert from '../../forms/Alert/CustomErrorAlert'
import SearchBox from '../../../rsmCoreComponents/components/Search/SearchBox'
import { Styles } from '../../../types'
import ProcessedBySearch from './ProcessedBySearch'
import { CEM_FEATURE_PAYMENT_ACCOUNT_MANAGEMENT } from '../../../utils/constants/constants'
import ExportButton from './ExportButton'
import { useDeviceType } from '../../../rsmCoreComponents/hooks/useDeviceType'

const styles: Styles = {
  searchContainer: (theme) => ({
    width: '100%',
    [theme.breakpoints.up('desktop')]: {
      width: '28.125rem',
    },
    [theme.breakpoints.up('tablet')]: {
      width: '25rem',
      marginRight: '0.31rem',
    },
  }),
  processByContainer: (theme) => ({
    width: '100%',
    [theme.breakpoints.up('desktop')]: {
      width: '17.18rem',
    },
    [theme.breakpoints.up('tablet')]: {
      width: '12rem',
    },
  }),
  filterChipsContainer: () => ({
    display: 'flex',
    flexDirection: { xs: 'column', sm: 'column', md: 'row' },
    alignItems: { xs: 'flex-start', md: 'center' },
    justifyContent: 'flex-start',
    gap: '1.25rem',
    width: '100%',
  }),
  ExportAlign: () => ({
    display: 'flex',
    justifyContent: { xs: 'flex-start', md: 'flex-end' },
    width: { xs: '100%', sm: 'auto', md: 'auto' },
    mt: { xs: 2, md: 0 },
    flex: { md: 1 },
  }),
}

interface InvoicesHeaderProps {
  setFilter: React.Dispatch<React.SetStateAction<string>>
  isPaymentHistory?: boolean
  changeFilterAndSearch?: (filterValue: string) => void
}

const InvoicesSearch = ({
  setFilter,
  isPaymentHistory = false,
  changeFilterAndSearch = () => {},
}: InvoicesHeaderProps) => {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const exportStatus = useSelector(getExportStatus)
  const paymentExportStatus = useSelector(getPaymentExportStatus)

  const { isMobile, isTablet } = useDeviceType()
  const cemFeatures = useSelector(getCemFeatures)
  const isAccountManager = useMemo(
    () =>
      isCemFeatureEnabled(
        CEM_FEATURE_PAYMENT_ACCOUNT_MANAGEMENT,
        cemFeatures,
        'any',
      ),
    [cemFeatures],
  )

  return (
    <>
      <Box
        id="invoices-filter-chips-clear-all-focus-target"
        sx={styles.filterChipsContainer}>
        <Box
          sx={styles.searchContainer}
          role="search"
          aria-label={t('SearchRegion')}>
          <SearchBox
            setFilter={setFilter}
            placeholderText={t('SearchByKeyword')}
            testId="Txt_Invoice_Search"
          />
        </Box>
        {!(isMobile || isTablet) && isPaymentHistory && isAccountManager && (
          <Box
            sx={styles.processByContainer}
            role="search"
            aria-label={t('SearchRegion')}>
            <ProcessedBySearch
              changeFilterAndSearch={changeFilterAndSearch}
              testId="Txt_Processedby_Search"
            />
          </Box>
        )}
        {!isMobile && (
          <Box sx={styles.ExportAlign}>
            <ExportButton
              isPaymentHistory={isPaymentHistory}
              buttonText="Export"
            />
          </Box>
        )}
      </Box>
      {isPaymentHistory
        ? paymentExportStatus?.showAlert &&
          !paymentExportStatus.isSuccess && (
            <CustomErrorAlert
              testId="Banner_PaymentExport_Error"
              disableAutoHide
              open
              header="SupportError"
              message="Invoicing.PaymentHistoryExport.ExportErrorDownload"
              close={() => dispatch(clearPaymentExportStatus())}
            />
          )
        : exportStatus?.showAlert &&
          !exportStatus.isSuccess && (
            <CustomErrorAlert
              testId="Banner_Export_Error"
              disableAutoHide
              open
              header="SupportError"
              message="Invoicing.InvoiceExport.ExportErrorDownload"
              close={() => dispatch(clearExportStatus())}
            />
          )}
    </>
  )
}

export default InvoicesSearch
