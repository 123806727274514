import React, { useEffect, useRef, useState } from 'react'
import { Trans, useTranslation } from 'react-i18next'
import { Box, Button, Checkbox, styled } from '@mui/material'
import { Link, useNavigate, useParams } from 'react-router-dom'
import { useDispatch } from 'react-redux'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPlus } from '@fortawesome/free-solid-svg-icons'
import {
  PayerPaymentAccountViewModel,
  ScheduledInvoicesPaymentViewModel,
} from '@rsmus/ecp-financeservice'
import SectionHeader from '../../layouts/SectionHeader'
import { Styles } from '../../../types'
import { tokens } from '../../../styles/materialTheme'
import api from '../../../api'
import SearchIcon2 from '../../icons/SearchIcon2/SearchIcon2'
import DocumentCheckedIcon from '../../icons/DocumentCheckedIcon/DocumentCheckedIcon'
import BankIcon from '../../icons/BankIcon/BankIcon'
import { CreditCardIcon, CheckboxOutlinedIcon } from '../../icons'
import CreditCardLogo from '../Invoices/CreditCardLogo'
import PaymentMethodNew from '../Invoices/PaymentMethodNew'
import ConfirmPaymentMethodDeleteDialog from './ConfirmPaymentMethodDeleteDialog'
import ConfirmPaymentMethodDelete, {
  PaymentMethod,
} from './ConfirmPaymentMethodDelete'
import ConfirmCreditCardEnable from './ConfirmCreditCardEnable'
import { CustomErrorAlert, CustomSuccessAlert } from '../../forms/Alert'
import CheckboxDisabledIcon from '../../../rsmCoreComponents/components/icons/CheckboxDisabledIcon'
import { setScheduledPaymentCount } from '../../../store/invoices/invoiceSelectedInvoicesSlice'
import {
  ClientFilterType,
  updateClientFilter,
  clearAllFilters,
  updateFilterValue,
  setFilterValue,
  PaymentSelectedFiltersConstants,
} from '../../../store/invoices/paymentSelectedFiltersSlice'
import { INVOICE_FILTER_STATUS_PAID } from '../../../utils/constants/constants'
import ActionMenu from '../Invoices/ActionMenu'

const styles: Styles = {
  cardContainer: (theme) => ({
    background: theme.palette.common.white,
    border: `0.0625rem solid ${tokens.colors.rsmGray.disabled}`,
    padding: '1.5rem',
    fontFamily: 'prelo-book',
    fontWeight: 500,
  }),
  innerContainer: (theme) => ({
    margin: '0 1rem',
    [theme.breakpoints.down('tablet')]: {
      display: 'flex',
      flexDirection: 'column-reverse',
    },
  }),
  linkSection: (theme) => ({
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-around',
    gap: '1.5rem',
    marginTop: '1.5rem',
    [theme.breakpoints.down('tablet')]: {
      flexDirection: 'column',
      justifyContent: 'flex-start',
      marginTop: '0',
    },
  }),
  iconContainer: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    gap: '0.5rem',
    height: '3rem',
  },
  logoContainer: {
    textAlign: 'center',
    width: '3.25rem',
    marginRight: '0.25rem',
  },
  accountsSection: (theme) => ({
    display: 'flex',
    flexDirection: 'row',
    gap: '3rem',
    marginTop: '1.5rem',
    '& h3': {
      fontWeight: 800,
    },
    '& > div': {
      flex: '1 1 50%',
    },
    [theme.breakpoints.only('tablet')]: {
      gap: '1rem',
    },
    [theme.breakpoints.down('tablet')]: {
      flexDirection: 'column',
      justifyContent: 'flex-start',
      '& > div': {
        flex: 'none',
      },
    },
  }),
  savedPaymentMethodsHeader: {
    display: 'inline-block',
    fontWeight: 800,
    marginBottom: '0.5rem',
    marginRight: '0.5rem',
    color: tokens.colors.rsmBlue.accessibility,
  },
  footer: {
    marginTop: '2rem',
    textAlign: 'center',
  },
  paymentOptions: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    fontStyle: 'italic',
    margin: '2rem',
  },
  link: (theme) => ({
    fontFamily: 'prelo-book',
    fontSize: '1rem',
    color: theme.palette.secondary.main,
    fontWeight: '800',
    textDecoration: 'underline',
  }),
}

const StyledLink = styled(Link)(({ theme }) => ({
  color: theme.palette.secondary.main,
  fontWeight: '800',
  textDecoration: 'underline',
}))

const StyledHr = styled('hr')(() => ({
  margin: '2rem 0',
}))

const StyledPlus = styled(FontAwesomeIcon)(({ theme }) => ({
  color: theme.palette.secondary.main,
}))
const Rotate90 = styled('span')(() => ({
  transform: 'rotate(90deg)',
}))

const StyledList = styled('ul')(() => ({
  listStyleType: 'none',
  padding: 0,
  marginTop: '1rem',
  maxHeight: '9rem',
  overflowY: 'auto',
}))

const StyledListItem = styled('li')(() => ({
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  height: '3.25rem',
  paddingLeft: '0.5rem',
  paddingRight: '0.5rem',
}))

export interface PaymentManagementProps {
  layoutContainer: 'dashboard' | 'accountManagement'
}

const PaymentManagement = ({ layoutContainer }: PaymentManagementProps) => {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const { clientId } = useParams()
  const navigate = useNavigate()
  const numericClientId = Number(clientId)
  const [client, setClient] = useState<string | undefined>(undefined)
  const [creditCardEnabled, setCreditCardEnabled] = useState<boolean>(false)
  const [bankAccounts, setBankAccounts] = useState<
    PayerPaymentAccountViewModel[] | undefined
  >(undefined)
  const [creditCards, setCreditCards] = useState<
    PayerPaymentAccountViewModel[] | undefined
  >([])
  const [showConfirmCreditCardEnable, setShowConfirmCreditCardEnable] =
    useState(false)
  const [showConfirmPaymentDelete, setShowConfirmPaymentDelete] =
    useState(false)
  const [deletionSuccessful, setDeletionSuccessful] = useState(false)
  const [deletionSuccessMessage, setDeletionSuccessMessage] =
    useState<string>('')
  const [hasDeletionError, setHasDeletionError] = useState(false)
  const [hasCreditCardEnableError, setHasCreditCardEnableError] =
    useState(false)
  const [creditCardEnableSuccessful, setCreditCardEnableSuccessful] =
    useState(false)
  const [selectedPaymentMethod, setSelectedPaymentMethod] =
    useState<PaymentMethod>({} as PaymentMethod)
  const bankAccountsRef = useRef<HTMLButtonElement[]>([])
  const creditCardsRef = useRef<HTMLButtonElement[]>([])
  const addNewBankAccounRef = useRef<HTMLButtonElement>()
  const addNewCreditCardRef = useRef<HTMLButtonElement>()
  const enableCreditCardRef = useRef<HTMLInputElement>(null)
  const [isDialogOpen, setIsDialogOpen] = useState(false)
  const [focusOnCheckbox, setFocusOnCheckbox] = useState(false)
  const [scheduledInvoicesData, setScheduledInvoicesData] = useState<
    ScheduledInvoicesPaymentViewModel[]
  >([])
  const [payerPaymentAccounts, setPayerPaymentAccounts] = useState<
    PayerPaymentAccountViewModel[]
  >([])
  const [
    filteredPayerPaymentAccountsData,
    setFilteredPayerPaymentAccountsData,
  ] = useState<PayerPaymentAccountViewModel[]>([])
  const [autoPayExistsForPaymentMethod, setAutoPayExistsForPaymentMethod] =
    useState<boolean>(false)
  const [isGenericError, setIsGenericError] = useState(false)
  const [isAddPaymentSuccess, setIsAddPaymentSuccess] = useState(false)

  const loadCreditCards = () => {
    api.finance
      .payment_GetPayerCreditCards(clientId)
      .then((response) => {
        setCreditCards(response.data)
      })
      .catch(() => {
        setCreditCards([])
      })
  }

  const loadAchAccounts = () => {
    api.finance
      .payment_GetPayerBankAccounts(clientId)
      .then((response) => {
        setBankAccounts(response.data)
      })
      .catch(() => {
        setBankAccounts([])
      })
  }

  useEffect(() => {
    if (Number.isNaN(numericClientId)) return
    api.finance
      .client_GetClientInfo(numericClientId)
      .then((response) => {
        setClient(response?.data?.clientName)
        setCreditCardEnabled(response?.data?.creditCardEnabled || false)
      })
      .catch(() => {
        setClient('client')
        setCreditCardEnabled(false)
      })

    loadAchAccounts()
    loadCreditCards()
  }, [clientId])

  useEffect(() => {
    if (bankAccounts && creditCards) {
      setPayerPaymentAccounts([...bankAccounts, ...creditCards])
    }
  }, [bankAccounts, creditCards])

  const handleConfirmPaymentDelete = async (paymentMethod: PaymentMethod) => {
    const { data: ScheduledInvoices } =
      await api.finance.payment_GetScheduledInvoices(
        null,
        paymentMethod.id,
        false,
      )

    const { data: autopayExists } =
      await api.finance.payment_PaymentMethodAutopayExist(paymentMethod.id)
    setAutoPayExistsForPaymentMethod(autopayExists)

    const ScheduledInvoicesDataArray = ScheduledInvoices ?? []
    setScheduledInvoicesData(ScheduledInvoicesDataArray)

    const handleSuccessMessage = (isBankAccount: boolean) =>
      isBankAccount
        ? t(
            'Invoicing.PaymentManagement.ConfirmDeletePaymentMethod.BankAccountDeletionSuccess',
            {
              accountSuffix: paymentMethod.accountNumberSuffix,
            },
          )
        : t(
            'Invoicing.PaymentManagement.ConfirmDeletePaymentMethod.CreditCardDeletionSuccess',
            {
              cardType: paymentMethod.institutionName,
              cardNumber: paymentMethod.accountNumberSuffix,
            },
          )

    const successMessage = handleSuccessMessage(paymentMethod.isBankAccount)

    if (ScheduledInvoicesDataArray.length > 0 || autopayExists) {
      if (!creditCardEnabled) {
        const filteredData = payerPaymentAccounts.filter(
          (account) => account.paymentMethod !== 2,
        )
        setFilteredPayerPaymentAccountsData(filteredData)
      } else {
        setFilteredPayerPaymentAccountsData(payerPaymentAccounts)
      }

      setIsDialogOpen(true) // Show the ScheduledInvoicesDialog
    } else {
      setShowConfirmPaymentDelete(true)
    }

    setDeletionSuccessMessage(successMessage)
    setSelectedPaymentMethod(paymentMethod)
  }

  const handleCloseConfirmPaymentDelete = () => {
    setShowConfirmPaymentDelete(false)
    setSelectedPaymentMethod({} as PaymentMethod)
    setIsDialogOpen(false)
  }

  const handleOnErrorConfirmPaymentDelete = () => {
    setShowConfirmPaymentDelete(false)
    setHasDeletionError(true)
    if (selectedPaymentMethod.isBankAccount) {
      bankAccountsRef?.current[selectedPaymentMethod.index]?.focus()
    } else {
      creditCardsRef?.current[selectedPaymentMethod.index]?.focus()
    }
  }

  const handleCloseSuccessAlert = () => {
    setDeletionSuccessful(false)
    setSelectedPaymentMethod({} as PaymentMethod)
  }

  const handleOnSuccessfulDeletion = () => {
    let accountLength = 0
    if (selectedPaymentMethod.isBankAccount) {
      const newBankAccounts = bankAccounts?.filter(
        (e) => e.id !== selectedPaymentMethod.id,
      )
      if (newBankAccounts !== undefined && newBankAccounts.length >= 0) {
        accountLength = newBankAccounts.length
        setBankAccounts(newBankAccounts)
      }
    } else {
      const newCreditCards = creditCards?.filter(
        (e) => e.id !== selectedPaymentMethod.id,
      )
      if (newCreditCards !== undefined && newCreditCards.length >= 0) {
        accountLength = newCreditCards.length
        setCreditCards(newCreditCards)
      }
    }

    const newIndex =
      accountLength > selectedPaymentMethod.index
        ? selectedPaymentMethod.index
        : accountLength - 1

    if (selectedPaymentMethod.isBankAccount) {
      if (accountLength === 0) {
        addNewBankAccounRef?.current?.focus()
        setDeletionSuccessful(true)
        return
      }
      bankAccountsRef.current[newIndex].focus()
    } else {
      if (accountLength === 0) {
        addNewCreditCardRef?.current?.focus()
        setDeletionSuccessful(true)
        return
      }
      creditCardsRef.current[newIndex].focus()
    }

    setDeletionSuccessful(true)
    dispatch(setScheduledPaymentCount(1))
  }

  const handleCloseCreditSuccessAlert = () => {
    setCreditCardEnableSuccessful(false)
    enableCreditCardRef.current?.focus()
  }

  const handleCloseCreditCardFailureAlert = () => {
    setHasCreditCardEnableError(false)
    setFocusOnCheckbox(true)
  }

  useEffect(() => {
    if (focusOnCheckbox) {
      setTimeout(() => {
        enableCreditCardRef.current?.focus()
        setFocusOnCheckbox(false)
      }, 100)
    }
  }, [focusOnCheckbox])

  const handleEnableCreditCard = () => {
    setShowConfirmCreditCardEnable(true)
  }

  const handleCloseConfirmCreditCardEnable = () => {
    setShowConfirmCreditCardEnable(false)
    enableCreditCardRef.current?.focus()
  }

  const handleOnSuccessfulCreditCardEnable = () => {
    setCreditCardEnabled(true)
    setCreditCardEnableSuccessful(true)
  }

  const handleOnErrorConfirmCreditCardEnable = () => {
    setShowConfirmCreditCardEnable(false)
    setHasCreditCardEnableError(true)
  }

  const handleSessionChange = (sessionId: string, isError: boolean) => {
    if (isError) {
      setIsGenericError(true)
    }
  }

  return (
    <Box sx={styles.cardContainer}>
      <CustomErrorAlert
        testId="Banner_PaymentManagement_PaymentMethodDeletionError"
        header="Alert.GenericErrorHeader"
        message="Invoicing.PaymentManagement.ConfirmDeletePaymentMethod.PaymentMethodDeletionError"
        open={hasDeletionError}
        close={() => setHasDeletionError(false)}
        disableAutoHide
      />
      <CustomSuccessAlert
        testId="Banner_PaymentManagement_PaymentMethodDeletionSuccess"
        header="Invoicing.PaymentManagement.ConfirmDeletePaymentMethod.PaymentMethodDeleteSuccessHeader"
        message={deletionSuccessMessage}
        open={deletionSuccessful}
        close={() => handleCloseSuccessAlert()}
        overrideToastMsgTimeLimit={30000}
      />
      <CustomErrorAlert
        testId="Banner_PaymentManagement_CreditCardEnableError"
        header="Alert.GenericErrorHeader"
        message="Invoicing.PaymentManagement.ConfirmEnableCreditCard.CreditCardEnableError"
        open={hasCreditCardEnableError}
        close={() => handleCloseCreditCardFailureAlert()}
        disableAutoHide
      />
      <CustomSuccessAlert
        testId="Banner_PaymentManagement_CreditCardEnableSuccess"
        header="Invoicing.PaymentManagement.ConfirmEnableCreditCard.CreditCardEnableSuccessHeader"
        message="Invoicing.PaymentManagement.ConfirmEnableCreditCard.CreditCardEnableSuccess"
        open={creditCardEnableSuccessful}
        close={() => handleCloseCreditSuccessAlert()}
        overrideToastMsgTimeLimit={30000}
      />
      <CustomSuccessAlert
        testId="Banner_PaymentManagement_AddPaymentSuccess"
        header="Invoicing.PaymentManagement.PaymentMethodAdded"
        message="Invoicing.PaymentManagement.PaymentMethodAddedMessage"
        open={isAddPaymentSuccess}
        close={() => setIsAddPaymentSuccess(false)}
        overrideToastMsgTimeLimit={30000}
      />
      <CustomErrorAlert
        testId="Banner_PaymentManagement_GenericErrorMessage"
        header="Alert.GenericErrorHeader"
        open={isGenericError}
        close={() => setIsGenericError(false)}
        disableAutoHide
        message="Alert.GenericErrorMessage"
      />
      <SectionHeader
        title={t('Invoicing.PaymentManagement.PaymentManagementTitle')}
        testId="Lbl_PaymentManagement_Header"
      />
      <Box sx={styles.innerContainer}>
        <Box sx={styles.linkSection}>
          <Box sx={styles.iconContainer}>
            <SearchIcon2 />
            <StyledLink
              to={`/invoicing/invoices?clientId=${clientId}`}
              data-testid="Lnk_PaymentManagement_LookingForInvoice3">
              {t('Invoicing.PaymentManagement.LookingForInvoice')}
            </StyledLink>
          </Box>
          <Box sx={styles.iconContainer}>
            <DocumentCheckedIcon />
            <Button
              data-testid="Btn_PaymentManagement_FindAndDownloadReceipts"
              onClick={() => {
                const clientFilter: ClientFilterType = {
                  clientId: clientId || '',
                  name: client || '',
                }
                // ensure no category filters are selected on payment history page
                dispatch(clearAllFilters())
                dispatch(
                  updateFilterValue(
                    setFilterValue(
                      PaymentSelectedFiltersConstants.paymentStatus,
                      INVOICE_FILTER_STATUS_PAID,
                    ),
                  ),
                )
                dispatch(updateClientFilter(clientFilter))
                navigate('/invoicing/payments')
              }}
              sx={styles.link} // styling
              role="link">
              {t('Invoicing.PaymentManagement.FindAndDownloadReceipts')}
            </Button>
          </Box>
        </Box>
        <StyledHr />
        <Box>
          <Box>
            <Box component="h3" sx={styles.savedPaymentMethodsHeader}>
              {t('Invoicing.PaymentManagement.SavedPaymentMethods')}
            </Box>
            <Box component="span">
              <Trans
                i18nKey="Invoicing.PaymentManagement.SavedPaymentMethodsSub"
                components={{
                  outer: <i />,
                  clientName: <b />,
                }}
                values={{ clientName: client }}
              />
            </Box>
          </Box>
          <Box sx={styles.accountsSection}>
            <Box>
              <Box sx={styles.iconContainer}>
                <BankIcon />
                <Box component="h3" id="achAccountList">
                  {t('Invoicing.PaymentManagement.AchAccounts')}
                </Box>
              </Box>
              <StyledList aria-labelledby="achAccountList">
                {bankAccounts?.map((bankAccount, index) => (
                  <StyledListItem
                    key={`${bankAccount.id}-${bankAccount.accountNumberSuffix}`}>
                    <Box sx={styles.logoContainer}>&nbsp;</Box>
                    <Box id={`bankAccount-${index}`} sx={{ flex: 'auto' }}>
                      {t('Invoicing.PaymentManagement.BankAccountEnding', {
                        accountNumber: bankAccount.accountNumberSuffix,
                      })}
                    </Box>
                    <Rotate90>
                      <ActionMenu
                        fowardedRef={(e: HTMLButtonElement) => {
                          bankAccountsRef.current[index] = e
                          return e
                        }}
                        data-testid={`Btn_Bank_Account_${index}_Delete)`}
                        actionButtonAriaControls={`bankAccount-${bankAccount.id}-menu`}
                        actionButtonDescribedBy={`bankAccount-${index}`}
                        id={`bankAccount-${index}`}
                        data={{ ...bankAccount, isBankAccount: true, index }}
                        errorHeader="Invoicing.DeleteError"
                        errorMessage="Invoicing.DeleteErrorMessage"
                        errorMessageForNotFound="Invoicing.DeleteErrorMessageForNotFound"
                        menuItems={[
                          {
                            label: 'Delete',
                            handleAction: handleConfirmPaymentDelete,
                          },
                        ]}
                      />
                    </Rotate90>
                  </StyledListItem>
                ))}
              </StyledList>
              <Box sx={styles.iconContainer}>
                <PaymentMethodNew
                  sessionChange={handleSessionChange}
                  buttonRef={(e: HTMLButtonElement) => {
                    addNewBankAccounRef.current = e
                  }}
                  clientId={clientId}
                  paymentsAccountsCount={creditCards?.length || 0}
                  paymentMethodType="BankAccount"
                  accountAdded={(paymentMethod) => {
                    setIsAddPaymentSuccess(true)
                    if (paymentMethod === 'BankAccount') {
                      loadAchAccounts()
                    }
                  }}
                  trackNewPaymentMethod={false}
                  paymethodBankAcctLimit={bankAccounts?.length || 0}>
                  <Box sx={styles.logoContainer}>
                    <StyledPlus fontSize={24} icon={faPlus} />
                  </Box>
                  {t('Invoicing.PaymentManagement.AddNewBankAccount')}
                </PaymentMethodNew>
              </Box>
            </Box>
            {!creditCardEnabled && layoutContainer === 'dashboard' ? (
              <Box sx={styles.paymentOptions}>
                {t('Invoicing.PaymentManagement.PaymentOptions')}
              </Box>
            ) : (
              <Box>
                <Box sx={styles.iconContainer}>
                  <CreditCardIcon width="3.5rem" height="2.25rem" />
                  <Box component="h3" id="ccAccountList">
                    {t('Invoicing.PaymentManagement.CreditCards')}
                  </Box>
                  {layoutContainer === 'accountManagement' && (
                    <Box>
                      <Checkbox
                        id="creditCardEnabled"
                        icon={<CheckboxOutlinedIcon />}
                        checkedIcon={<CheckboxDisabledIcon />}
                        checked={creditCardEnabled}
                        disabled={creditCardEnabled}
                        onChange={handleEnableCreditCard}
                        inputRef={enableCreditCardRef}
                        data-testid="Chk_Credit_Card_Enabled"
                        aria-describedby="ccAccountList"
                      />
                      <label htmlFor="creditCardEnabled">
                        {creditCardEnabled
                          ? t('Invoicing.PaymentManagement.CreditCardEnabled')
                          : t('Invoicing.PaymentManagement.EnableCreditCard')}
                      </label>
                    </Box>
                  )}
                </Box>
                <StyledList aria-labelledby="ccAccountList">
                  {creditCards?.map((creditCard, index) => (
                    <StyledListItem
                      key={`${creditCard.id}-${creditCard.accountNumberSuffix}`}>
                      <Box sx={styles.logoContainer}>
                        <CreditCardLogo
                          cardType={creditCard.institutionName}
                          height={52}
                          width={42.56}
                        />
                      </Box>
                      <Box id={`creditCard-${index}`} sx={{ flex: 'auto' }}>
                        {t('Invoicing.PaymentManagement.CreditCardEnding', {
                          cardType: creditCard.institutionName,
                          cardNumber: creditCard.accountNumberSuffix,
                        })}
                      </Box>

                      <Rotate90>
                        <ActionMenu
                          fowardedRef={(e: HTMLButtonElement) => {
                            creditCardsRef.current[index] = e
                            return e
                          }}
                          actionButtonAriaControls={`creditCard-${creditCard.id}-menu`}
                          actionButtonDescribedBy={`creditCard-${index}`}
                          id={`creditCard-${index}`}
                          data={{ ...creditCard, isBankAccount: false, index }}
                          errorHeader="Invoicing.DeleteError"
                          errorMessage="Invoicing.DeleteErrorMessage"
                          errorMessageForNotFound="Invoicing.DeleteErrorMessageForNotFound"
                          menuItems={[
                            {
                              label: 'Delete',
                              handleAction: handleConfirmPaymentDelete,
                            },
                          ]}
                        />
                      </Rotate90>
                    </StyledListItem>
                  ))}
                </StyledList>
                {creditCardEnabled ? (
                  <Box sx={styles.iconContainer}>
                    <PaymentMethodNew
                      sessionChange={handleSessionChange}
                      buttonRef={(e: HTMLButtonElement) => {
                        addNewCreditCardRef.current = e
                      }}
                      clientId={clientId}
                      paymentsAccountsCount={creditCards?.length || 0}
                      paymentMethodType="CreditCard"
                      accountAdded={(paymentMethod) => {
                        setIsAddPaymentSuccess(true)
                        if (paymentMethod === 'CreditCard') {
                          loadCreditCards()
                        }
                      }}
                      paymethodCredCardLimit={creditCards?.length || 0}>
                      <Box sx={styles.logoContainer}>
                        <StyledPlus fontSize={24} icon={faPlus} />
                      </Box>
                      {t('Invoicing.PaymentManagement.AddNewCreditCard')}
                    </PaymentMethodNew>
                  </Box>
                ) : null}
              </Box>
            )}
          </Box>
          <Box sx={styles.footer}>
            <Trans
              i18nKey="Invoicing.PaymentManagement.Footer"
              components={{
                tel: (
                  <StyledLink
                    to="tel:8335972118"
                    sx={{ textDecoration: 'none' }}
                  />
                ),
                email: <StyledLink to="mailto:accounts_receivable@rsmus.com" />,
              }}
            />
          </Box>
        </Box>
      </Box>
      <ConfirmPaymentMethodDelete
        open={showConfirmPaymentDelete}
        onClose={() => handleCloseConfirmPaymentDelete()}
        onError={() => handleOnErrorConfirmPaymentDelete()}
        onSuccess={() => handleOnSuccessfulDeletion()}
        paymentMethod={selectedPaymentMethod}
      />
      <ConfirmCreditCardEnable
        open={showConfirmCreditCardEnable}
        onClose={handleCloseConfirmCreditCardEnable}
        onSuccess={handleOnSuccessfulCreditCardEnable}
        onError={handleOnErrorConfirmCreditCardEnable}
        clientId={numericClientId || 0}
        clientName={client || ''}
      />
      <ConfirmPaymentMethodDeleteDialog
        Dialogtext={t('Invoicing.ScheduledinvoicesDialog.DeleteCardDialogText')}
        Subtext1={t('Invoicing.ScheduledinvoicesDialog.DeleteCardSubText1')}
        Subtext2={t('Invoicing.ScheduledinvoicesDialog.DeleteCardSubText2')}
        buttonCloseText={t('Invoicing.ScheduledinvoicesDialog.DeleteCardClose')}
        clientId={numericClientId || 0}
        open={isDialogOpen}
        onClose={() => handleCloseConfirmPaymentDelete()}
        onError={() => handleOnErrorConfirmPaymentDelete()}
        onSuccess={() => handleOnSuccessfulDeletion()}
        scheduledInvoicesData={scheduledInvoicesData}
        PayerPaymentAccountsData={filteredPayerPaymentAccountsData}
        paymentMethod={selectedPaymentMethod}
        autoPayExistsForPaymentMethod={autoPayExistsForPaymentMethod}
      />
    </Box>
  )
}
export default PaymentManagement
