import React, { useCallback, useState, useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import { Trans, useTranslation } from 'react-i18next'
import { useForm, Controller } from 'react-hook-form'
import { useDispatch, useSelector } from 'react-redux'
import { Button, Box, Typography, styled, TextField } from '@mui/material'
import { CustomErrorAlert } from 'src/components/forms/Alert'
import { Heading } from 'src/components/typography'
import { Styles } from '../../types'
import tokens from '../../styles/tokens.json'
import { setFeedBackShowSuccessBanner } from '../../store/support/supportSlice'
import CustomSuccessAlert from '../../components/forms/Alert/CustomSuccessAlert'
import api from '../../api'
import { getUserInfo } from '../../store/userInfo/userInfoSlice'
import { useGetUserContactPreferenceDetailsQuery } from '../../store/userService'
import FormPreferredContactRadio from '../../rsmCoreComponents/components/DynamicForm/FormControls/FormFeedbackRadioButtons'
import FormFeedbackBusinessPhoneNumber from '../../rsmCoreComponents/components/DynamicForm/FormControls/FormFeedbackBusinessPhoneNumber'

const styles: Styles = {
  formContainer: (theme) => ({
    backgroundColor: tokens.colors.white,
    color: tokens.colors.rsmGray.copy,
    display: 'flex',
    flexDirection: 'column',
    paddingTop: '2.5rem',
    paddingBottom: '2.5rem',
    paddingLeft: '7.25rem',
    paddingRight: '7.25rem',
    [theme.breakpoints.only('mobile')]: {
      paddingLeft: '1.75rem',
      paddingRight: '1.75rem',
    },
    [theme.breakpoints.only('tablet')]: {
      paddingLeft: '2.75rem',
      paddingRight: '2.75rem',
    },
  }),
  inputField: (theme) => ({
    '& .MuiInputBase-root': {
      padding: '0.5rem',
      border: `0.0625rem solid ${tokens.colors.rsmGray.disabled}`,
      borderRadius: '0.25rem',
    },
    [theme.breakpoints.down('tablet')]: {
      '& .MuiInputBase-root': {
        padding: '0.5rem',
      },
    },
    [theme.breakpoints.down('mobile')]: {
      '& .MuiInputBase-root': {
        padding: '0.3125rem',
        fontSize: '0.9rem',
      },
    },
  }),
  radioGroup: (theme) => ({
    display: 'flex',
    flexDirection: 'row',
    gap: '1rem',
    'justify-content': 'flex-start',
    'flex-wrap': 'nowrap',
    [theme.breakpoints.down('tablet')]: {
      flexDirection: 'column',
      gap: '0.5rem',
    },
  }),
  contactMethodContainer: (theme) => ({
    display: 'flex',
    flexDirection: 'row',
    gap: '7rem',
    [theme.breakpoints.down('tablet')]: {
      flexDirection: 'column',
      gap: '1rem',
    },
  }),
  feedbackDescriptionStyles: (theme) => ({
    paddingBottom: '2.5rem',
    color: tokens.colors.rsmGray.copy,
    fontSize: '1rem',
    paddingTop: '1.5rem',
    [theme.breakpoints.down('mobile')]: {
      fontSize: '0.9rem',
      marginBottom: '1rem',
    },
  }),
  submitButton: (theme) => ({
    alignSelf: 'center',
    backgroundColor: tokens.colors.rsmGreen.primary,
    color: 'white',
    [theme.breakpoints.down('tablet')]: {
      flexDirection: 'column',
    },
  }),
  error: {
    display: 'inline',
    float: 'left',
    fontFamily: 'Prelo-Book, sans-serif',
    fontSize: '.875rem',
    fontWeight: 400,
    color: tokens.colors.rsmRed.secondary,
    paddingTop: '0rem',
  },
  descriptionContainer: (theme) => ({
    width: '70%',
    maxWidth: '100%',
    [theme.breakpoints.down('tablet')]: {
      width: '100%',
      maxWidth: '100%',
    },
  }),
  descriptionLimit: (theme) => ({
    fontFamily: 'Prelo-Book, sans-serif',
    fontSize: '1rem',
    fontWeight: 400,
    color: theme.palette.text.primary,
    paddingTop: '0rem',
    float: 'right',
  }),
}

const FeedbackForm = () => {
  const { t } = useTranslation()
  const { control, handleSubmit, reset, formState, setValue } = useForm({
    mode: 'onSubmit',
  })
  const [open, setOpen] = useState(false)
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const [isSuccess, setIsSuccess] = useState(false)
  const userInfo = useSelector(getUserInfo)
  const businessEmail = userInfo?.emailAddress
  const { data } = useGetUserContactPreferenceDetailsQuery()

  const firstName = userInfo?.firstName || ''
  const lastName = userInfo?.lastName || ''

  useEffect(() => {
    setValue('firstName', firstName)
    setValue('lastName', lastName)
  }, [firstName, lastName, setValue])
  const contactMethodTxtKey = data?.data.preferredContactMethod?.contactMedium
  const contactMethod = contactMethodTxtKey
    ? t(
        `UserProfile.ContactPreferenceForm.ContactMethods.${contactMethodTxtKey}`,
      )
    : t(`UserProfile.ContactPreferenceForm.ContactMethods.EmailContactMethod`)
  const [selectedContact, setSelectedContact] = useState(contactMethod)

  const handleRadioChange = (value: string) => {
    setSelectedContact(value)
  }

  const StyledLabel = styled('label')(({ theme }) => ({
    fontFamily: 'Prelo-Black, sans-serif',
    fontSize: '1rem',
    paddingBottom: '0rem',
    marginBottom: '0.1rem',
    color: theme.palette.text.primary,
    display: 'block',
  }))

  const issueTextLimit = 2000
  const StyledTextField = styled(TextField)(({ theme }) => ({
    '.MuiInputBase-root': {
      width: '70%',
      maxWidth: '100%',
      [theme.breakpoints.down('tablet')]: {
        width: '100%',
        maxWidth: '100%',
      },
    },
    '& .MuiFormHelperText-root': { margin: '0rem' },
  }))
  const handleClose = useCallback(() => {
    setOpen(false)
  }, [setOpen])
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const onSubmit = async (formData: any) => {
    const payload = {
      firstName: formData.firstName,
      lastName: formData.lastName,
      businessEmail,
      preferredContactMethod: selectedContact,
      businessPhone: formData.businessPhone,
      feedback: formData.feedback,
    }
    try {
      const response = await api.communication.feedback_SubmitFeedback(payload)
      if (response?.data) {
        dispatch(setFeedBackShowSuccessBanner(true))
        reset()
        navigate('/')
      } else setIsSuccess(false)
    } catch (e) {
      setIsSuccess(false)
    }
    setOpen(true)
  }

  const renderBusinessEmail = () => (
    <Box sx={styles.paddingContainer}>
      <Box
        sx={{
          fontFamily: 'Prelo-Black, sans-serif',
          fontSize: '1rem',
          paddingBottom: '0.5rem',
          color: tokens.colors.rsmGray.copy,
        }}
        data-testid="Lbl_SupportForm_BusinessEmailField_Label">
        {t('BusinessEmail')}
      </Box>
      <Box
        sx={{
          wordBreak: 'break-all',
          fontFamily: 'Prelo-Book, sans-serif',
          fontSize: '1rem',
        }}
        data-testid="Lbl_SupportForm_BusinessEmailField_Value">
        {userInfo?.emailAddress}
      </Box>
    </Box>
  )
  const renderBusinessPhoneNumber = () => (
    <Box>
      <FormFeedbackBusinessPhoneNumber
        formState={formState}
        setValue={setValue}
        control={control}
        required={selectedContact === 'Phone'}
      />
    </Box>
  )

  return (
    <Box
      component="form"
      onSubmit={handleSubmit(onSubmit)}
      sx={styles.formContainer}>
      {isSuccess && (
        <CustomSuccessAlert
          data-testid="successAlert"
          testId="Banner_Feedback_Success"
          header={t('FeedbackPage.FeedbackSuccessHeader')}
          message={t('FeedbackPage.FeedbackSuccessMessage')}
          open={open}
          close={handleClose}
          disableAutoHide
        />
      )}
      {!isSuccess && (
        <CustomErrorAlert
          testId="Banner_Feedback_Error"
          disableAutoHide
          header="FeedbackPage.FeedbackErrorHeader"
          open={open}
          close={handleClose}
          message="FeedbackPage.FeedbackErrorMessage"
        />
      )}
      <Heading variant="h1" testId="Hed_Feedback_Title">
        {t('FeedbackPage.Title')}
      </Heading>
      <Typography
        variant="body1"
        data-testid="Lbl_Feedback_Description"
        sx={styles.feedbackDescriptionStyles}>
        {t('FeedbackPage.Description')}
      </Typography>
      <Typography>
        <StyledLabel htmlFor="feedback" id="Lbl_FeedbackDetails">
          <span data-testid="Lbl_FeedbackDetails">
            <Trans
              i18nKey="FeedbackPage.FeedbackDetailsLabel"
              components={{ b: <b /> }}
            />
          </span>
        </StyledLabel>

        <Controller
          name="feedback"
          control={control}
          defaultValue=""
          rules={{
            maxLength: issueTextLimit,
            required: `${t('FeedbackDesciption')}`,
          }}
          render={({
            field: { onChange, value, ref },
            fieldState: { error },
          }) => (
            <StyledTextField
              id="Description"
              variant="outlined"
              fullWidth
              multiline
              value={value}
              rows={4}
              inputRef={ref}
              inputProps={{
                maxLength: issueTextLimit,
                'data-testid': 'Inp_FeedbackIssueValue',
                'aria-required': true,
              }}
              onChange={(e) => onChange(e)}
              error={!!error}
              FormHelperTextProps={{
                sx: styles.descriptionContainer,
              }}
              helperText={
                <>
                  {!!error && (
                    <Typography
                      component="span"
                      sx={styles.error}
                      data-testid="Txt_Feedback_IssueError">
                      {error?.message}
                    </Typography>
                  )}
                  <Typography
                    component="span"
                    sx={styles.descriptionLimit}
                    data-testid="Txt_Feedback_IssueLength">
                    {value.length}/{issueTextLimit}
                  </Typography>
                </>
              }
              aria-labelledby="Lbl_FeedbackDetails"
              data-testid="Txt_FeedbackDetails"
            />
          )}
        />
        <Controller
          name="firstName"
          control={control}
          defaultValue={firstName}
          render={({ field }) => (
            // eslint-disable-next-line react/jsx-props-no-spreading
            <input type="hidden" {...field} />
          )}
        />
        <Controller
          name="lastName"
          control={control}
          defaultValue={lastName}
          render={({ field }) => (
            // eslint-disable-next-line react/jsx-props-no-spreading
            <input type="hidden" {...field} />
          )}
        />
      </Typography>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          gap: '0.001rem',
          color: tokens.colors.rsmGray.copy,
          paddingTop: '3rem',
        }}>
        <fieldset>
          <legend
            id="Lbl_Feedback_ContactPreference"
            data-testid="Lbl_Feedback_ContactPreference">
            <Trans
              i18nKey="FeedbackPage.contactPreferenceHelperText"
              components={{ b: <b /> }}
            />
          </legend>

          <FormPreferredContactRadio
            control={control}
            handleRadioChange={handleRadioChange}
            contactMethod={selectedContact}
            data-testid="RdBtn_Feedback_preferredContactRadio"
            aria-labelledby="Lbl_Feedback_ContactPreference"
          />
        </fieldset>
        <Box>
          <input type="hidden" name="BusinessEmail" value={businessEmail} />
        </Box>
        <Box sx={styles.radioGroup}>
          {selectedContact === 'Email' && renderBusinessEmail()}
          {selectedContact === 'Phone' && (
            <Box sx={styles.contactMethodContainer}>
              {renderBusinessEmail()}
              {renderBusinessPhoneNumber()}
            </Box>
          )}
          {selectedContact === 'None' && renderBusinessEmail()}
        </Box>
      </Box>
      <Box sx={styles.submitButton}>
        <Button
          variant="contained"
          type="submit"
          data-testid="Btn_Feedback_SubmitButton">
          {t('FeedbackPage.SubmitButton')}
        </Button>
      </Box>
    </Box>
  )
}

export default FeedbackForm
