import {
  Box,
  Button,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tooltip,
  Checkbox,
  FormControlLabel,
} from '@mui/material'
import { visuallyHidden } from '@mui/utils'
import React, { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { v4 as uuidv4 } from 'uuid'
import {
  getPaymentSelectedFilters,
  getPaymentCounts,
  setPaymentCounts,
} from 'src/store/invoices/paymentSelectedFiltersSlice'
import { toPaymentSearchFilter } from 'src/utils/helpers/filterAdapter'
import ExclamationTriangleIcon from '../../icons/ExclamationTriangleIcon/ExclamationTriangleIcon'
import CalendarInCircleIcon from '../../icons/CalendarInCircleIcon/CalendarInCircleIcon'
import {
  PaymentData,
  PaymentsData,
} from '../../../store/invoices/paymentSearchService'
import tokens from '../../../styles/tokens.json'
import { Styles } from '../../../types'
import OverflowTooltip from '../../OverFlowToolTip'
import {
  formatCurrency,
  formatDate,
} from '../../../rsmCoreComponents/utils/formatters'
import ActionMenu, { ActionMenuItem } from './ActionMenu'
import useActionMenu from './useActionMenu'
import SortableHeader from '../../../rsmCoreComponents/components/SortableHeader/SortableHeader'
import {
  setSelectedPayments,
  getSelectedPayments,
} from '../../../store/invoices/paymentSelectedPaymentsSlice'
import api from '../../../api'
import CheckboxIcon from '../../../rsmCoreComponents/components/icons/CheckboxIcon'
import CheckboxOutlinedIcon from '../../../rsmCoreComponents/components/icons/CheckboxOutlinedIcon'
import { isFeatureFlagEnabled } from '../../../rsmCoreComponents/utils/featureFlagUtils'
import { useDeviceType } from '../../../rsmCoreComponents/hooks/useDeviceType'

const statusList = {
  PAID: 'paid',
  PARTIAL: 'partially paid',
  SCHEDULED: 'scheduled',
  CANCELLED: 'cancelled',
  PENDING: 'pending',
  FAILED: 'failed',
  COMPLETE: 'complete',
}

const styles: Styles = {
  paymentsTable: (theme) => ({
    '&.MuiTable-root': {
      tableLayout: 'fixed',
      width: '100%',
      '& .MuiTableCell-root': {
        borderBottom: 'none',
      },
      '& th.MuiTableCell-root': {
        fontFamily: 'Prelo-Bold, sans-serif',
      },
      '& td.MuiTableCell-root': {
        fontFamily: 'Prelo-Book, sans-serif',
      },
      '& .MuiTableCell-head': {
        padding: '1rem',
        paddingRight: 'revert',
        fontSize: '0.875rem',
        lineHeight: '1rem',
        textTransform: 'uppercase',
        verticalAlign: 'top',
        alignItems: 'center',
      },
      '& tbody': {
        '& .MuiTableRow-root': {
          '&:nth-of-type(odd)': {
            backgroundColor: tokens.colors.rsmGray.accessibility,
          },
        },
      },
      '& .MuiTableCell-body': {
        padding: '1.5rem 1rem',
        fontSize: '1rem',
        lineHeight: '1.5rem',
      },
      '.MuiFormControlLabel-root': {
        margin: '0rem',
      },
      '.MuiCheckbox-root': {
        boxSizing: 'content-box',
        padding: '0.5rem',
      },
    },
    '& .MuiTableCell-root': {
      '&:nth-of-type(1), &:nth-of-type(12)': {
        width: '3.5rem',
        padding: '0.5rem',
      },
    },
    '.sortable-header': {
      cursor: 'pointer',
    },
    '& .MuiTableCell-body': {
      '&:nth-of-type(2), &:nth-of-type(3), :nth-of-type(5), :nth-of-type(6)': {
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
      },
    },
    [theme.breakpoints.only('desktop')]: {
      '& .MuiTableCell-root': {
        '&:nth-of-type(1)': {
          width: '5%',
        },
        '&:nth-of-type(2)': {
          width: '16%',
        },
        '&:nth-of-type(3)': {
          width: '12%',
        },
        '&:nth-of-type(4)': {
          width: '11%',
        },
        '&:nth-of-type(5)': {
          width: '13%',
        },
        '&:nth-of-type(6)': {
          width: '10%',
        },
        '&:nth-of-type(7)': {
          width: '10%',
        },
        '&:nth-of-type(8)': {
          width: '10%',
        },
        '&:nth-of-type(9)': {
          width: '6%',
        },
        '&:nth-of-type(10)': {
          width: '11%',
        },
        '&:nth-of-type(11)': {
          width: '10%',
        },
      },
    },
    [theme.breakpoints.only('tablet')]: {
      '& .MuiTableCell-root': {
        '&:nth-of-type(6), &:nth-of-type(7), &:nth-of-type(8), &:nth-of-type(9), &:nth-of-type(10), &:nth-of-type(11)':
          {
            display: 'none',
          },
        '&:nth-of-type(2)': {
          width: '32%',
        },
        '&:nth-of-type(3)': {
          width: '25%',
        },
        '&:nth-of-type(4)': {
          width: '18%',
        },
        '&:nth-of-type(5)': {
          width: '19%',
          minWidth: '6.25rem',
        },
      },
    },
    [theme.breakpoints.down('tablet')]: {
      '& .MuiTableCell-root': {
        '&:nth-of-type(2), &:nth-of-type(3), &:nth-of-type(6), &:nth-of-type(7), &:nth-of-type(8), &:nth-of-type(9), &:nth-of-type(10), &:nth-of-type(11)':
          {
            display: 'none',
          },
        '&:nth-of-type(4)': {
          width: '50%',
        },
        '&:nth-of-type(5)': {
          width: '50%',
        },
      },
    },
  }),
  date: {
    textTransform: 'uppercase',
  },
  tooltipText: {
    padding: '0rem',
  },
  tooltipIconButton: {
    fontSize: '1rem',
    fontWeight: 400,
    fontFamily: 'Prelo-Book, sans-serif',
    marginLeft: '-0.5rem',
  },
  disclaimer: (theme) => ({
    lineHeight: '1.5rem',
    display: 'flex',
    width: '100%',
    fontWeight: 600,
    fontSize: '1rem',
    fontFamily: 'Prelo-Book, sans-serif',
    justifyContent: 'center',
    paddingBottom: '1rem',
    [theme.breakpoints.only('desktop')]: {
      paddingRight: '3rem',
      paddingLeft: '3rem',
    },
    [theme.breakpoints.only('tablet')]: {
      paddingRight: '3.75rem',
      paddingLeft: '3.75rem',
    },
    [theme.breakpoints.only('mobile')]: {
      fontSize: '0.75rem',
      paddingRight: '0.5rem',
      paddingLeft: '0.5rem',
    },
  }),
}

interface PaymentHistoryTableProps {
  paymentsData: PaymentsData
  dataLoading: boolean
  onSort: (columnName: string, direction: 'asc' | 'desc') => void
  sortColumn: string
  sortDirection: 'asc' | 'desc'
  isMounted: boolean
}

const PaymentHistoryTable = ({
  paymentsData,
  dataLoading,
  onSort,
  sortColumn,
  sortDirection,
  isMounted,
}: PaymentHistoryTableProps) => {
  const { t } = useTranslation()
  const [open, setOpen] = useState<string | null>(null)
  const selectedPayments = useSelector(getSelectedPayments)
  const dispatch = useDispatch()

  const {
    handleDownloadReceipt,
    handleDownloadPaymentDetails,
    handleDownloadFailedPaymentNotice,
    handleDownloadCancelledPaymentNotice,
    handleDownloadFailedPayOnAccountNotice,

    // Future use: This code is for handling scheduled payments and should not be deleted
    // handleManageScheduledPayment,

    handleDownloadPayonAccountReciept,
  } = useActionMenu()

  const generateMenuItems = (
    paymentStatus: string,
    invoiceNumber: string,
  ): ActionMenuItem<PaymentData>[] => {
    let menuItems: ActionMenuItem<PaymentData>[] = []
    if (
      (paymentStatus.toLowerCase() === statusList.PAID ||
        paymentStatus.toLowerCase() === statusList.PARTIAL ||
        paymentStatus.toLowerCase() === statusList.COMPLETE) &&
      invoiceNumber === ''
    ) {
      menuItems = [
        {
          label: t('Invoicing.DownloadReceipt'),
          handleAction: handleDownloadPayonAccountReciept,
        },
      ]
    }
    if (
      (paymentStatus.toLowerCase() === statusList.PAID ||
        paymentStatus.toLowerCase() === statusList.PARTIAL ||
        paymentStatus.toLowerCase() === statusList.COMPLETE) &&
      invoiceNumber !== ''
    ) {
      menuItems = [
        {
          label: t('Invoicing.DownloadReceipt'),
          handleAction: handleDownloadReceipt,
        },

        // Future use: This code is for handling scheduled payments and should not be deleted
        // {
        //   label: t('Invoicing.ManageScheduledPayment'),
        //   handleAction: handleManageScheduledPayment,
        // },
      ]
    }
    if (paymentStatus.toLowerCase() === statusList.SCHEDULED) {
      menuItems = [
        {
          label: t('Invoicing.DownloadPaymentDetails'),
          handleAction: handleDownloadPaymentDetails,
        },

        // Future use: This code is for handling scheduled payments and should not be deleted
        // {
        //   label: t('Invoicing.ManageScheduledPayment'),
        //   handleAction: handleManageScheduledPayment,
        // },
      ]
    }
    if (
      paymentStatus.toLowerCase() === statusList.FAILED &&
      invoiceNumber !== ''
    ) {
      menuItems = [
        {
          label: t('Invoicing.DownloadFailedPaymentNotice'),
          handleAction: handleDownloadFailedPaymentNotice,
        },
      ]
    }
    if (
      paymentStatus.toLowerCase() === statusList.CANCELLED &&
      invoiceNumber !== ''
    ) {
      menuItems = [
        {
          label: t('Invoicing.DownloadCancelledPaymentNotice'),
          handleAction: handleDownloadCancelledPaymentNotice,
        },
      ]
    }
    if (
      paymentStatus.toLowerCase() === statusList.FAILED &&
      invoiceNumber === ''
    ) {
      menuItems = [
        {
          label: t('Invoicing.DownloadFailedPaymentNotice'),
          handleAction: handleDownloadFailedPayOnAccountNotice,
        },
      ]
    }

    return menuItems
  }

  const handleSort = (columnName: string) => {
    // Determine the new sort direction based on the current state
    const newSortDirection: 'asc' | 'desc' =
      sortColumn === columnName && sortDirection === 'asc' ? 'desc' : 'asc'
    // Pass both the column name and sort direction to the onSort callback
    onSort(columnName, newSortDirection)
  }

  const getSortableHeader = (columnName: string, displayValue: string) => (
    <TableCell>
      <SortableHeader
        columnName={columnName}
        currentSortColumn={sortColumn || ''}
        sortDirection={sortDirection || ''}
        isMounted={isMounted}
        handleSort={handleSort}>
        {displayValue}
      </SortableHeader>
    </TableCell>
  )

  const getTooltipText = (payment: {
    paymentInstallmentTypeId: string
    paymentDate: string
  }) => {
    if (payment.paymentInstallmentTypeId === '1') {
      return `${t('Invoicing.ScheduledFor')} ${formatDate(payment.paymentDate)}`
    }
    if (payment.paymentInstallmentTypeId === '2') {
      return `${t('Invoicing.InstallmentScheduledFor')} ${formatDate(
        payment.paymentDate,
      )}`
    }
    return null
  }

  const togglePaymentSelection = (payment: PaymentData) => {
    if (
      selectedPayments.find(
        (row) => row?.transactionId === payment.transactionId,
      )
    ) {
      dispatch(
        setSelectedPayments(
          selectedPayments.filter(
            (row) => row.transactionId !== payment.transactionId,
          ),
        ),
      )
    } else {
      dispatch(setSelectedPayments([...selectedPayments, payment]))
    }
  }

  const paymentFilters = useSelector(getPaymentSelectedFilters)

  const { totalPaymentsSet } = useSelector(getPaymentCounts)
  const { isMobile } = useDeviceType()

  useEffect(() => {
    if (!isFeatureFlagEnabled('Invoicing_ExportTableData')) return

    const fetchCounts = async () => {
      try {
        const res = await api.finance.payment_GetPaymentCounts(
          toPaymentSearchFilter(paymentFilters),
        )
        if (res?.data) {
          dispatch(
            setPaymentCounts({
              filteredPayments: paymentsData.totalRows ?? 0,
              totalPayments: res.data.totalPayments ?? 0,
            }),
          )
        }
      } catch (error) {
        console.error('[Payments] Error fetching payment counts:', error)
      }
    }

    if (!totalPaymentsSet) {
      fetchCounts()
    } else {
      dispatch(
        setPaymentCounts({
          filteredPayments: paymentsData.totalRows ?? 0,
        }),
      )
    }
  }, [paymentsData.totalRows])

  return (
    <TableContainer component={Paper} sx={styles.tableContainer}>
      {isMobile ? (
        <Box sx={styles.disclaimer}>
          <Box data-testid="Lbl_PaymentHistory_DisclaimerHeading">
            <Box
              data-testid="Lbl_PaymentHistory_DisclaimerHeading"
              component="span"
              sx={{ fontFamily: 'Prelo-Black, sans-serif' }}>
              {t('Invoicing.PaymentHistoryDisclaimerHeading')}
            </Box>
            {t('Invoicing.PaymentHistoryDisclaimer')}
          </Box>
        </Box>
      ) : (
        <Box sx={styles.disclaimer}>
          <Box data-testid="Icn_PaymentHistory_ExclamationTriangleIcon">
            <ExclamationTriangleIcon />
          </Box>
          <Box data-testid="Lbl_PaymentHistory_DisclaimerHeading">
            {t('Invoicing.PaymentHistoryDisclaimerHeading')}
            {t('Invoicing.PaymentHistoryDisclaimer')}
          </Box>
        </Box>
      )}
      <Table id="paymentsTable" sx={styles.paymentsTable}>
        <Box component="caption" sx={visuallyHidden}>
          {t('Invoicing.PaymentHistory')}
        </Box>
        <TableHead>
          <TableRow>
            <TableCell />
            {getSortableHeader('Initiated', t('Invoicing.Initiated'))}
            {getSortableHeader('customerName', t('Invoicing.CustomerName'))}
            {getSortableHeader('CustomerNumber', t('Invoicing.CustomerNumber'))}
            {getSortableHeader('TransactionId', t('Invoicing.TransactionId'))}
            {getSortableHeader('PaidAmount', t('Invoicing.PaidAmount'))}
            {getSortableHeader('InvoiceNumber', t('Invoicing.InvoiceNumber'))}
            {getSortableHeader('Status', t('Invoicing.Status'))}
            <TableCell />
            {getSortableHeader('ProcessedBy', t('Invoicing.ProcessedBy'))}
            {getSortableHeader('Method', t('Invoicing.Method'))}
            <TableCell>
              <Box component="span" sx={visuallyHidden}>
                {t('Invoicing.Actions')}
              </Box>
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {!dataLoading && paymentsData?.totalRows === 0 && (
            <TableRow>
              <TableCell
                sx={{ textAlign: 'center' }}
                colSpan={9}
                aria-label={t('Invoicing.NoDataFound')}
                data-testid={t('Invoicing.NoDataFound')}>
                {t('Invoicing.NoDataFound')}
              </TableCell>
            </TableRow>
          )}
          {paymentsData?.data?.map((payment, index) => (
            <TableRow
              key={`invoice-${uuidv4()}-${payment.transactionId}`}
              data-testid={`PaymentHistoryTable_Row_${index}`}>
              <TableCell
                data-testid={`Checkbox_PaymentHistoryTable_Row_${index}`}>
                <FormControlLabel
                  label=""
                  aria-label={`${t('Invoicing.SelectInvoice')} ${
                    payment.transactionId
                  }`}
                  htmlFor={`select-invoice-checkbox-${payment.transactionId}`}
                  control={
                    <Checkbox
                      id={`select-invoice-checkbox-${payment.transactionId}`}
                      data-testid={`Checkbox_PaymentHistory_Row_${index}`}
                      icon={<CheckboxOutlinedIcon />}
                      checkedIcon={<CheckboxIcon />}
                      checked={
                        !!selectedPayments.find(
                          (row) => row.transactionId === payment.transactionId,
                        )
                      }
                      disableRipple
                      onClick={() => togglePaymentSelection(payment)}
                    />
                  }
                />
              </TableCell>
              <TableCell
                data-testid={`PaymentDate_PaymentHistoryTable_Row_${index}`}>
                <Box component="span" sx={styles.paymentDate}>
                  <OverflowTooltip>
                    {formatDate(payment.paymentDate)}
                  </OverflowTooltip>
                </Box>
              </TableCell>
              <TableCell
                data-testid={`CustomerName_PaymentHistoryTable_Row_${index}`}>
                <OverflowTooltip>{payment.customerName}</OverflowTooltip>
              </TableCell>
              <TableCell
                data-testid={`CustomerNumber_PaymentHistoryTable_Row_${index}`}>
                <OverflowTooltip>{payment.customerNumber}</OverflowTooltip>
              </TableCell>
              <TableCell
                data-testid={`TransactionId_PaymentHistoryTable_Row_${index}`}>
                <OverflowTooltip id={payment.transactionId}>
                  {payment.paymentStatus.toLowerCase() !==
                    statusList.SCHEDULED && payment.transactionId}
                </OverflowTooltip>
              </TableCell>
              <TableCell
                data-testid={`PaidAmount_PaymentHistoryTable_Row_${index}`}>
                <OverflowTooltip>
                  {formatCurrency(payment.paidAmount)}
                </OverflowTooltip>
              </TableCell>
              <TableCell
                data-testid={`InvoiceNumber_PaymentHistoryTable_Row_${index}`}>
                <OverflowTooltip>{payment.invoiceNumber}</OverflowTooltip>
              </TableCell>
              <TableCell
                data-testid={`Status_PaymentHistoryTable_Row_${index}`}>
                <OverflowTooltip>{payment.paymentStatus}</OverflowTooltip>
              </TableCell>
              <TableCell
                data-testid={`CalendarIcon_PaymentHistoryTable_Cell_${index}`}>
                {payment.paymentStatus.toLowerCase() ===
                  statusList.SCHEDULED && (
                  <Tooltip
                    // sx={styles.tooltipIconButton}
                    id={`${payment.transactionId}-${index.toString()}`}
                    data-testid={`Tooltip_PaymentHistory_Row_${index}`}
                    disableTouchListener
                    open={open === payment.transactionId}
                    onOpen={() => setOpen(payment.transactionId)}
                    onClose={() => setOpen(null)}
                    PopperProps={{
                      modifiers: [
                        {
                          name: 'offset',
                          options: {
                            offset: [0, -20],
                          },
                        },
                      ],
                    }}
                    title={
                      <Box sx={styles.tooltipText} role="status">
                        {getTooltipText(payment)}
                      </Box>
                    }
                    placement="right"
                    describeChild>
                    <Button
                      variant="text"
                      onClick={() => {
                        setOpen(open === null ? payment.transactionId : null)
                      }}
                      aria-label="scheduled date tooltip"
                      onBlurCapture={() => setOpen(null)}
                      aria-expanded={open !== null}
                      disableRipple>
                      <Box component="span">
                        <CalendarInCircleIcon width="2.063rem" />
                      </Box>
                    </Button>
                  </Tooltip>
                )}
              </TableCell>
              <TableCell
                data-testid={`ProcessedBy_PaymentHistoryTable_Row_${index}`}>
                <OverflowTooltip>{payment.processedByEmail}</OverflowTooltip>
              </TableCell>
              <TableCell
                data-testid={`Paymethod_PaymentHistoryTable_Row_${index}`}>
                {payment.paymentMethod}
              </TableCell>
              <TableCell
                data-testid={`Actions_PaymentHistoryTable_Row_${index}`}>
                {payment.paymentStatus.toLowerCase() !== statusList.PENDING && (
                  <ActionMenu
                    actionButtonAriaControls={`payment-${payment.transactionId}-menu`}
                    actionButtonDescribedBy={payment.transactionId.toString()}
                    id={payment.transactionId}
                    data={payment}
                    menuItems={generateMenuItems(
                      payment.paymentStatus,
                      payment.invoiceNumber,
                    )}
                  />
                )}
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  )
}

export default PaymentHistoryTable
