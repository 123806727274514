import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import {
  ApiResponseOfAutopaySetupResponseViewModel,
  AutopayDetailsResponseViewModel,
  AutoPayRecurrenceInterval,
  AutopaySetupViewModel,
  InvoicePayerViewModel,
} from '@rsmus/ecp-financeservice'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router'
import { useFormContext } from 'react-hook-form'
import { Box, Button, styled } from '@mui/material'
import { TFunction } from 'i18next'
import AutoPayLimit from './PaymentComponents/AutoPayLimit'
import PaymentDate from './PaymentDate'
import Payer from './PaymentComponents/Payer'
import PaymentMethodRadioGroup from './PaymentComponents/PaymentMethodRadioGroup'
import {
  getAccountSelectedType,
  getPayerPaymentAccountsModel,
  getSelectedBankAccount,
  getSelectedCreditCard,
  getSelectedPayer,
  getSelectedPaymentMethod,
  SelectedAccountTypeState,
  setAccountSelectedType,
  setConfirmPaymentError,
  setDoNotSave,
  setNewPaymentMethod,
  setPayerPaymentAccountsModel,
  setSelectedBankAccount,
  setSelectedCreditCard,
  setSelectedPayer,
  setSelectedPaymentMethod,
} from '../../../store/invoices/paymentInfoSlice'
import api from '../../../api'
import AutoPayPaymentMethod from './PaymentComponents/AutoPayPaymentMethod'
import {
  AutoPaySetup,
  getImplementAutoPayLimit,
  getInvoicePayers,
  getIsEditAutoPay,
  getPayOnAccountClient,
  ImplementAutoPayLimitType,
  setAutoPaySetup,
  setEditAutoPaySetup,
  setImplementAutoPayLimit,
} from '../../../store/invoices/invoiceSelectedInvoicesSlice'
import TermsAndConditionsDialog from './TermsAndConditionsDialog'
import AccountInfoSubText from './PaymentComponents/AccountInfoSubText'
import PaymentAmount from './PaymentComponents/PaymentAmount'
import Spinner from '../../forms/Spinner/Spinner'
import AutoPayFrequency, {
  AutoPayFrequencyIntervals,
} from './PaymentComponents/AutoPayFrequency'
import Heading from '../../typography/Heading/Heading'
import { Styles } from '../../../types'
import {
  REACT_APP_AUTOPAY_MAX_PAYMENT_AMOUNT,
  REACT_APP_AUTOPAY_MIN_PAYMENT_AMOUNT,
} from '../../../envVariables'
import { getNextProcessingDate } from './dateUtils'

interface AutoPayProps {
  formSubmitCount: number
  payerChange: (payer: InvoicePayerViewModel | undefined) => void
  sessionCreating: () => void
  sessionChange: (sessionId: string, isError: boolean) => void
  isCancelling: boolean
  onCancelled: () => void
}

const paymentLimitErrorValidationRules = (
  value: string,
  t: TFunction<'translation', undefined>,
) => {
  const isValidInput = /^(0|[1-9]\d*)([.,]\d+)?$/.test(value) // Only positivie, decimal numbers
  const isValidCurrency = /^\d*[.,]?\d{0,2}$/.test(value) // no more than two decimal points
  const isLessThanMinAmount =
    parseFloat(value) < REACT_APP_AUTOPAY_MIN_PAYMENT_AMOUNT // minimum that can be paid for autopay
  const isMoreThanMaxAmount =
    parseFloat(value) > REACT_APP_AUTOPAY_MAX_PAYMENT_AMOUNT // maximum that can be paid for autopay

  if (value.length === 0) {
    return `${t('Invoicing.AutoPayPage.LimitAmountRequired')}`
  }

  if (value.length > 0 && !isValidInput) {
    return `${t('Invoicing.PayOnAccountPage.InvalidCharacters')} ${t(
      'Invoicing.AutoPayPage.ValidAmount',
    )}`
  }

  if (!isValidCurrency) {
    return t('Invoicing.AutoPayPage.ValidAmount').toString()
  }

  if (value.length > 0 && isLessThanMinAmount) {
    return `${t('Invoicing.AutoPayPage.LimitAmountMinimumRequired', {
      minAmount: REACT_APP_AUTOPAY_MIN_PAYMENT_AMOUNT,
    })}`
  }

  if (value.length > 0 && isMoreThanMaxAmount) {
    return `${t('Invoicing.AutoPayPage.LimitAmountMaximumRequired', {
      maxAmount: REACT_APP_AUTOPAY_MAX_PAYMENT_AMOUNT.toString().replace(
        /\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g,
        ',',
      ),
    })}`
  }
  return true
}

const RowArea = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'row',
  flexBasis: 'auto',
  marginTop: '1.5rem',
  marginBottom: '1.5rem',
  [theme.breakpoints.only('mobile')]: {
    flexDirection: 'column',
  },
}))

const StyledLabel = styled('label')(({ theme }) => ({
  fontFamily: 'Prelo-Black, sans-serif',
  fontSize: '1rem',
  paddingBottom: '0.5rem',
  color: theme.palette.text.primary,
  display: 'block',
}))

const styles: Styles = {
  fieldContainer: (theme) => ({
    width: '50%',
    paddingTop: '-1rem',
    marginTop: '-12rem',
    [theme.breakpoints.only('mobile')]: {
      width: '100%',
      paddingLeft: 0,
      marginTop: '0rem',
    },
    [theme.breakpoints.only('tablet')]: {
      paddingLeft: 0,
      marginTop: '0rem',
    },
  }),
  fieldContainers: (theme) => ({
    width: '50%',
    paddingTop: 0,

    [theme.breakpoints.only('mobile')]: {
      width: '100%',
      paddingLeft: 0,
      marginTop: '0rem',
    },
    [theme.breakpoints.only('tablet')]: {
      paddingLeft: 0,
      marginTop: '0rem',
    },
  }),
  CategoryHeader: {
    fontFamily: 'Prelo-Bold, sans-serif',
    fontSize: '1.25rem',
    lineHeight: '1.5rem',
    paddingBottom: '1.25rem',
  },
  cancelContainer: {
    padding: '3rem 1rem',
    textAlign: 'center',
  },
}

const AutoPay = ({
  formSubmitCount,
  payerChange,
  sessionCreating,
  sessionChange,
  isCancelling,
  onCancelled,
}: AutoPayProps) => {
  const { getValues, reset, clearErrors, setValue, control } = useFormContext()
  const { t } = useTranslation()
  const navigate = useNavigate()
  const dispatch = useDispatch()

  const selectedPaymentMethod = useSelector(getSelectedPaymentMethod)
  const payOnAccountClient = useSelector(getPayOnAccountClient)
  const selectedBankAccount = useSelector(getSelectedBankAccount)
  const selectedCreditCard = useSelector(getSelectedCreditCard)
  const implementAutoPayLimit = useSelector(getImplementAutoPayLimit)
  const selectedPayer = useSelector(getSelectedPayer)
  const isEditAutoPay = useSelector(getIsEditAutoPay)
  const payerPaymentAccounts = useSelector(getPayerPaymentAccountsModel)
  const accountSelectedType = useSelector(getAccountSelectedType)
  const invoicePayers = useSelector(getInvoicePayers)

  const [openTermsDialog, setOpenTermsDialog] = useState<boolean>(false)
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const [isInitialEditLoad, setInitialEditLoad] = useState<boolean>(false)
  const [paymentStartDateSubMessage, setPaymentStartDateSubMessage] =
    useState<string>('')
  const [paymentLimit, setPaymentLimit] = useState<number>(0)
  const [selectedFrequency, setSelectedFrequency] =
    useState<AutoPayRecurrenceInterval>()
  const unselectedStatus: SelectedAccountTypeState = 'Unselected'
  const existingStatus: SelectedAccountTypeState = 'Existing'
  const defaultImplementAutoPayLimitType: ImplementAutoPayLimitType = 'No'
  const [existingAutoPay, setExistingAutoPay] = useState<
    AutopaySetupViewModel | undefined
  >(undefined)
  const [editAutoPayDetails, setEditAutoPayDetails] = useState<
    AutopayDetailsResponseViewModel | undefined
  >(undefined)

  const currentEditAutopayPayer = useMemo(() => {
    let payer
    if (isEditAutoPay && invoicePayers.length > 1) {
      payer = invoicePayers.find(
        (x) => x.payerId === editAutoPayDetails?.payerId.toString(),
      )
      dispatch(setSelectedPayer(payer))
    }

    return payer
  }, [invoicePayers, isEditAutoPay, editAutoPayDetails])

  const resetPaymentMethodState = () => {
    dispatch(setSelectedBankAccount(undefined))
    dispatch(setSelectedCreditCard(undefined))
    dispatch(setSelectedPayer(undefined))
    dispatch(setAccountSelectedType(unselectedStatus))
    dispatch(setNewPaymentMethod(false))
    dispatch(setDoNotSave(false))
    dispatch(setPayerPaymentAccountsModel([]))
    dispatch(setImplementAutoPayLimit(defaultImplementAutoPayLimitType))
  }

  const handleSetupAutopay = useCallback(async () => {
    setIsLoading(true)

    try {
      const clientId = Number(payOnAccountClient?.clientId)
      const startDate = new Date(getValues('paymentDate'))

      let response: ApiResponseOfAutopaySetupResponseViewModel
      if (isEditAutoPay) {
        response = await api.finance.payment_UpdateAutoPay({
          clientId,
          clientPaymentMethodId:
            selectedPaymentMethod === 'BankAccount'
              ? selectedBankAccount ?? 0
              : selectedCreditCard ?? 0,
          paymentLimit:
            paymentLimit === 0 ||
            implementAutoPayLimit === defaultImplementAutoPayLimitType
              ? undefined
              : paymentLimit,
          startDate,
          paymentFrequency:
            selectedFrequency ?? AutoPayRecurrenceInterval.Monthly,
        })
      } else {
        response = await api.finance.payment_SetupAutoPay({
          clientId,
          clientPaymentMethodId:
            selectedPaymentMethod === 'BankAccount'
              ? selectedBankAccount ?? 0
              : selectedCreditCard ?? 0,
          paymentLimit:
            paymentLimit === 0 ||
            implementAutoPayLimit === defaultImplementAutoPayLimitType
              ? undefined
              : paymentLimit,
          startDate,
          paymentFrequency:
            selectedFrequency ?? AutoPayRecurrenceInterval.Monthly,
        })
      }

      const autoPaySetup: AutoPaySetup = {
        companyName: (payOnAccountClient?.name as string) ?? '',
        payer: selectedPayer?.name ?? '',
        paymentMethod: selectedPaymentMethod,
        frequency: response.data?.recurrenceInterval
          ? t(`Invoicing.AutoPayPage.${response.data?.recurrenceInterval}`)
          : '',
        startDate: startDate.toString(),
        autoPaylimit: response.data?.paymentLimit,
        accountNumber: response.data?.paymentAccountNumber,
        clientId: payOnAccountClient?.clientId,
      }

      reset()
      dispatch(setAutoPaySetup(autoPaySetup))
      setIsLoading(false)
      resetPaymentMethodState()
      navigate('/invoicing/invoices/payment-success')
    } catch {
      dispatch(setConfirmPaymentError(true))
      setIsLoading(false)
    }
  }, [
    selectedPaymentMethod,
    selectedBankAccount,
    selectedCreditCard,
    selectedFrequency,
    implementAutoPayLimit,
    selectedPayer,
    payOnAccountClient,
    paymentLimit,
    getValues,
    dispatch,
    navigate,
  ])

  const handleFrequencyChange = (
    frequency: AutoPayRecurrenceInterval | undefined,
  ) => {
    let subMessage = ''

    if (frequency === AutoPayRecurrenceInterval.Weekly) {
      subMessage = 'Invoicing.AutoPayPage.PaymentStartDateWeeklyMessage'
    }

    if (frequency === AutoPayRecurrenceInterval.BiWeekly) {
      subMessage = 'Invoicing.AutoPayPage.PaymentStartDateBiWeeklyMessage'
    }

    if (frequency === AutoPayRecurrenceInterval.Monthly) {
      subMessage = 'Invoicing.AutoPayPage.PaymentStartDateMonthlyMessage'
    }

    setPaymentStartDateSubMessage(subMessage)
    setSelectedFrequency(frequency)
    clearErrors('autoPayFrequency')
  }

  const handleCancelAutoPay = () => {
    const clientId = Number(payOnAccountClient?.clientId)
    api.finance.payment_CancelAutoPay(clientId).then(() => {
      resetPaymentMethodState()
      onCancelled()
    })
  }

  useEffect(() => {
    // check if accountSelectedType is not equal to new to prevent reloading of initial values
    if (isEditAutoPay && accountSelectedType.toLowerCase() !== 'new') {
      const frequency = AutoPayFrequencyIntervals.find(
        (item) => item.value === editAutoPayDetails?.recurrenceInterval,
      )
      const paymentMethodValue =
        editAutoPayDetails?.paymentMethod === 1 ? 'BankAccount' : 'CreditCard'
      const nextPaymentDate =
        getNextProcessingDate(
          editAutoPayDetails?.startDate,
          editAutoPayDetails?.recurrenceInterval,
        ) ?? ''
      const toggleValue = editAutoPayDetails?.paymentLimit ? 'Yes' : 'No'

      setValue('autoPayFrequency', frequency)
      setSelectedFrequency(frequency?.value)
      setValue('paymentDate', nextPaymentDate)
      setValue('autoPayLimitToggle', toggleValue)
      dispatch(setImplementAutoPayLimit(toggleValue))
      if (editAutoPayDetails?.paymentLimit) {
        setValue('paymentAmount', editAutoPayDetails?.paymentLimit)
        setPaymentLimit(editAutoPayDetails?.paymentLimit)
      }
      setValue('paymentMethodType', paymentMethodValue)
      dispatch(setSelectedPaymentMethod(paymentMethodValue))
      // sets loading to false to give components enough time to load data
      setIsLoading(false)
    }
  }, [editAutoPayDetails])

  // sets edit details for payerAccount Info
  useEffect(() => {
    if (isEditAutoPay && accountSelectedType.toLowerCase() !== 'new') {
      const payerAccount = payerPaymentAccounts.find(
        (acc) => acc.id === editAutoPayDetails?.clientPaymentMethodId,
      )
      const paymentMethodValue =
        editAutoPayDetails?.paymentMethod === 1 ? 'BankAccount' : 'CreditCard'

      // check initial edit load to prevent reloading selected payment account when toggling payment methods
      if (
        editAutoPayDetails?.clientPaymentMethodId &&
        payerAccount &&
        isInitialEditLoad
      ) {
        setInitialEditLoad(false)
        setValue('paymentMethod', payerAccount?.id)
        dispatch(setAccountSelectedType(existingStatus))
        if (paymentMethodValue === 'BankAccount') {
          dispatch(setSelectedBankAccount(payerAccount?.id))
        } else {
          dispatch(setSelectedCreditCard(payerAccount?.id))
        }
      }
    }
  }, [editAutoPayDetails, payerPaymentAccounts])

  // loads payer when edit autopay invoicePayers has more than 1 option
  useEffect(() => {
    if (currentEditAutopayPayer) {
      setValue('payer', currentEditAutopayPayer)
    }
  }, [currentEditAutopayPayer])

  useEffect(() => {
    const clientId = Number(payOnAccountClient?.clientId)
    if (clientId && isEditAutoPay) {
      api.finance.payment_GetClientAutoPay(clientId).then((response) => {
        setExistingAutoPay(response?.data)
      })
      api.finance.payment_GetAutopayDetails(clientId).then((response) => {
        if (response?.data) {
          dispatch(setEditAutoPaySetup(response?.data))
          setEditAutoPayDetails(response?.data)
        }
      })
    } else {
      setExistingAutoPay(undefined)
    }
  }, [payOnAccountClient?.clientId, isEditAutoPay])

  // When the form is submitted, open the terms and conditions dialog.
  useEffect(() => {
    if (formSubmitCount > 0) {
      setOpenTermsDialog(true)
    }
  }, [formSubmitCount])

  useEffect(() => {
    if (isEditAutoPay && accountSelectedType.toLowerCase() !== 'new') {
      // sets loading on initial render
      setIsLoading(true)
      setInitialEditLoad(true)
    }
    dispatch(setSelectedPaymentMethod('BankAccount'))
    dispatch(setAccountSelectedType(unselectedStatus))
  }, [])

  return !isCancelling ? (
    <Box style={{ width: '100%', height: 'fit-content' }}>
      <Spinner open={isLoading} />
      <RowArea>
        <Box sx={styles.fieldContainers}>
          <Box component="h2" sx={styles.CategoryHeader}>
            {t('Invoicing.AutoPayPage.PaymentInformationHeader')}
          </Box>
          <StyledLabel sx={{ paddingBottom: 0 }}>
            {t('Invoicing.PayOnAccountPage.Account')}
            <span className="sr-only">{t('srOnlyRequired')}</span>
          </StyledLabel>
          <Heading
            variant="h2"
            data-testid={`Hed_AutoPay_${payOnAccountClient?.name}`}>{`${payOnAccountClient?.name} - ${payOnAccountClient?.clientId}`}</Heading>
        </Box>
      </RowArea>
      <RowArea>
        <AutoPayFrequency
          control={control}
          frequencyChange={handleFrequencyChange}
        />
      </RowArea>
      <RowArea>
        <PaymentDate
          labelKey={
            isEditAutoPay
              ? 'Invoicing.AutoPayPage.NextAutopayDate'
              : 'Invoicing.AutoPayPage.PaymentStartDate'
          }
          subMessage={paymentStartDateSubMessage}
          errorKey="Invoicing.AutoPayPage.PaymentStartDateRequired"
        />
      </RowArea>
      <RowArea>
        <AutoPayLimit />
      </RowArea>
      {implementAutoPayLimit === 'Yes' && (
        <RowArea>
          <PaymentAmount
            label={t('Invoicing.AutoPayPage.AutoPayLimitAmount')}
            errorValidationRules={paymentLimitErrorValidationRules}
            handleChange={(amount: number) => setPaymentLimit(amount)}
          />
        </RowArea>
      )}
      <RowArea>
        <Payer
          payerChange={payerChange}
          errorKey="Invoicing.AutoPayPage.PayerRequired"
        />
      </RowArea>
      {payOnAccountClient?.isCreditCardEnabled && (
        <RowArea>
          <PaymentMethodRadioGroup />
        </RowArea>
      )}
      <AccountInfoSubText />
      <RowArea sx={{ marginTop: '0.5rem' }}>
        <AutoPayPaymentMethod
          formControl={control}
          paymentMethodType={selectedPaymentMethod}
          isScheduledPayment
          sessionCreating={sessionCreating}
          sessionChange={sessionChange}
          errorKey={
            selectedPaymentMethod === 'BankAccount'
              ? t('Invoicing.AutoPayPage.BankAccountRequired')
              : t('Invoicing.AutoPayPage.CreditCardRequired')
          }
        />
      </RowArea>
      <TermsAndConditionsDialog
        isAutomaticPayment
        open={openTermsDialog}
        onAccept={() => {
          setOpenTermsDialog(false)
          handleSetupAutopay()
        }}
        onClose={() => setOpenTermsDialog(false)}
        hasUserReadTerms={false}
      />
    </Box>
  ) : (
    <>
      <Box sx={styles.cancelContainer}>
        {t('Invoicing.AutoPayPage.CancelAutoPayMessage', {
          frequency: t(
            `Invoicing.AutoPayPage.${existingAutoPay?.paymentFrequency}`,
          ),
        })}
      </Box>
      <Box sx={styles.cancelContainer}>
        <Button
          data-testid="Btn_EditCancelAutopayPage_ConfirmAndCancelAutopay"
          variant="contained"
          onClick={handleCancelAutoPay}>
          {t('Invoicing.AutoPayPage.CancelAutoPayConfirm')}
        </Button>
      </Box>
    </>
  )
}

export default AutoPay
