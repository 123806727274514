import locales from './locales'

export default {
  [locales.ENGLISH]: {
    translation: {
      srOnlyRequired: 'required',
      Navigation: {
        AccountSettings: 'Account settings',
        AccountManagement: 'Account Management',
        CloseMainNavigationMenu: 'Close main navigation menu',
        Dashboard: 'Dashboard',
        DocumentRequests: 'Document requests',
        Documents: 'Documents',
        Engagements: 'Engagements',
        HighContrast: 'High contrast',
        Home: 'Home',
        Insights: 'Insights',
        Invoices: 'Invoices',
        Invoicing: 'Invoicing',
        Logout: 'Logout',
        Main: 'Main',
        OpenMainNavigationMenu: 'Open main navigation menu',
        Payments: 'Payment History',
        PayMyBill: 'Pay My Bill',
        PleaseSelect: 'Please select',
        Profile: 'Profile',
        SkipToMainContent: 'Skip to main content',
        Support: 'Support',
        Welcome: 'Welcome',
        ContactUs: 'Contact Us',
        Feedback: 'Feedback',
        FAQ: 'FAQ',
      },
      Pagination: {
        RowsPerPage: 'Rows per page',
      },
      ActivityTemplates: {
        RequestCreatedSubject: 'Request created by {{FirstName}} {{LastName}}',
        RequestUpdatedSubject:
          'Request details changed by {{FirstName}} {{LastName}}',
        RequestNameUpdatedBody:
          'Request name changed from "{{OldRequestName}}" to "{{NewRequestName}}".',
        RequestFunctionalAreaUpdatedBody:
          'Functional area changed from "{{OldArea}}" to "{{NewArea}}".',
        RequestGroupUpdatedBody:
          'Group changed from "{{OldGroup}}" to "{{NewGroup}}".',
        RequestSubgroupUpdatedBody:
          'Subgroup changed from "{{OldSubgroup}}" to "{{NewSubgroup}}".',
        RequestDescriptionUpdatedBody:
          'Description changed from "{{OldDescription}}" to "{{NewDescripion}}".',
        RequestStatusUpdatedSubject:
          'Status updated from $t({{From}}) to $t({{To}}) by {{FirstName}} {{LastName}}.',
        RequestDueDateUpdatedSubject:
          'Due date updated from {{FromDate, Datetime}} to {{ToDate, Datetime}} by {{FirstName}} {{LastName}}',
        RequestPrioritySetSubject:
          'Marked as priority by {{FirstName}} {{LastName}}',
        RequestPriorityUnsetSubject:
          'Not Marked as priority by {{FirstName}} {{LastName}}',
        RequestLockSetSubject: 'Request locked by {{FirstName}} {{LastName}}',
        RequestLockUnsetSubject:
          'Request unlocked by {{FirstName}} {{LastName}}',
        RequestDeletedSubject: 'Request deleted by {{FirstName}} {{LastName}}',
        RequestAssignedSubject: 'Assignees added by {{FirstName}} {{LastName}}',
        RequestAssignedBody: '{{Val, List}} added to the request.',
        RequestUnassignedSubject:
          'Assignees removed by {{FirstName}} {{LastName}}',
        RequestUnassignedBody: '{{Val, List}} removed from the request.',
        DocumentsAddedSubject:
          '{{DocumentCount}} document(s) uploaded by {{FirstName}} {{LastName}}.',
        DocumentsAddedBody: '{{Val, List}} have been uploaded.',
        DocumentsDeletedSubject:
          '{{DocumentCount}} document(s) deleted by {{FirstName}} {{LastName}}.',
        DocumentsDeletedBody: '{{Val, List}} have been deleted.',
        DocumentsDownloadedSubject:
          '{{DocumentCount}} document(s) downloaded by {{FirstName}} {{LastName}}.',
        DocumentsDownloadedBody: '{{Val, List}} have been downloaded.',
        CommentAddedSubject: 'Comments added by {{FirstName}} {{LastName}}',
        FollowupCommentAddedSubject:
          'Request follow-up comments added by {{FirstName}} {{LastName}}',
      },
      Alert: {
        CloseAlert: 'Close alert',
        GenericErrorHeader: 'Error',
        GenericErrorMessage: 'Something went wrong',
        PaymentFailed: 'Payment unsuccessful',
        PaymentMethodError:
          'Please validate the payment information and retry. If the issue persists, contact your bank or card provider for assistance.',
        PaymentMethodErrorHeader: 'The Payment information could not be saved',
        PaymentUnableToProcess:
          'The payment was unsuccessful. Please try again or attempt with another payment method. If the issue persists, contact your bank or card provider for assistance.',
        ErrorSummaryAlertmessage:
          'The following mandatory fields require input and must be completed before confirming payment.',
        ErrorScheduledPaymentDownlaodmessage: 'Failed to Download',
      },
      Authorization: {
        NotAuthorizedForApplication: {
          NotAuthorizedHeader: "Something's not working",
          NotAuthorizedReason: "You don't have permission to view this page.",
          NotAuthorizedPrimarySuggestion: 'Please double-check your request.',
          NotAuthorizedSecondarySuggestion:
            'If you think this is a mistake, please contact your RSM account administrator to get access.',
        },
      },
      UserProfile: {
        ECPProfileTitle: 'myRSM - Profile',
        PageContactInfoPrefsHeading: 'Contact Information & Preferences',
        PageInterestsHeading: 'Interests',
        PageNoInterestsMessage:
          'You haven’t selected any interests yet. It only takes a few minutes and helps us customize your myRSM experience.',
        PageAddInterestsButton: 'Add interests',
        PageUpdateInterestsButton: 'Update interests',
        IndustryLabel: 'Industries',
        ServiceLabel: 'Service Lines',
        TopicLabel: 'Topics',
        ProfileWizard: {
          StepCounter: 'Step {{currentStep}} of {{totalSteps}} ',
          WelcomeMessageDesktop: 'Hi {{firstName}}, welcome to myRSM!',
          WelcomeMessageTablet: 'Welcome to myRSM!',
          WelcomeMessageMobile: 'Welcome, {{firstName}}!',
          CustomizationMessage:
            'Tell us how you plan on using myRSM and we will customize the experience for you. This information will not be shared outside of myRSM and will only be used to enhance this tool for you.',
          CustomizationMessageShort:
            'Tell us how you plan on using myRSM and we will customize the experience for you. This information will not be shared outside of myRSM.',
          SelectThe: 'Select the',
          Industries: 'Industries',
          ServiceLines: 'Service Lines',
          Topics: 'Topics',
          ContactInfoPrefs: 'Contact Information & Preferences',
          YouAreInterestedIn: 'you are interested in.',
          ContactInfoPrefsMessage:
            'Please verify and/or add the following information. All fields are optional.',
          Back: 'Back',
          Next: 'Next',
          SaveAndContinueLater: 'Save and continue later',
          SaveAndExit: 'Save and exit',
        },
        ProfilePopover: {
          IncompleteMessage:
            "We noticed you weren't able to finish your profile setup.",
          CompleteMessage:
            'You can adjust your interest selections at any time in the profile section of myRSM.',
          ContinueMessage: 'Go to profile to finish',
          AdjustProfileMessage: 'Go to profile',
          FinishProfile: 'Finish profile',
          CloseDialog: 'Close dialog',
        },
        ContactPreferenceForm: {
          FirstName: 'First Name',
          LastName: 'Last Name',
          Name: 'Name',
          ClientOrBusinessName: 'Client/Business name(s)',
          RegionOrTimeZone: 'Region/time zone',
          BusinessPhoneNumber: 'Business phone',
          BusinessEmailAddress: 'Business email',
          PreferredContactMethod: 'Preferred contact method',
          Email: 'Email',
          Phone: 'Phone',
          EngagementActivityNotificationFrequency:
            'Engagement activity notification frequency',
          InsightsActivityNotificationFrequency:
            'Insights activity notification frequency',
          Submit: 'Submit',
          Edit: 'Edit',
          Save: 'Save',
          Cancel: 'Cancel',
          TimeZones: {
            AlaskaTimeZone: 'Alaska',
            ArizonaTimeZone: 'Arizona',
            CentralTimeZone: 'Central Time (US & Canada)',
            PacificTimeZone: 'Pacific Time (US & Canada)',
            MountainTimeZone: 'Mountain Time (US & Canada)',
            HawaiiTimeZone: 'Hawaii',
            EasternTimeZone: 'Eastern Time (US & Canada)',
          },
          NotificationFrequencies: {
            WeeklyTextAlert: 'Weekly text alert',
            DailyTextAlert: 'Daily text alert',
            NewItemTextAlert: 'Text alert as soon as a new item is added',
            NoTextAlert: 'No text alerts, just in app notifications',
            WeeklyEmailDigest: 'Weekly email digest',
            DailyEmailDigest: 'Daily email digest',
            NewItemEmailDigest: 'Email as soon as a new item is added',
            NoEmailDigest: 'No emails, just in app notifications',
          },
          ContactMethods: {
            EmailContactMethod: 'Email',
            PhoneContactMethod: 'Phone',
          },
        },
      },
      Download: 'Download',
      DownloadAll: 'Download All',
      Delete: 'Delete',
      DocumentRequests: {
        Title: 'Document Requests',
        Results: 'Results',
        Actions: {
          CreateNewRequest: 'Create a new request',
          DownloadRequest: 'Download request list',
          MoreOptions: 'More actions',
          Upload: 'Upload request list (.csv)',
          Download: 'Download.csv template',
          Export: 'Export request list',
        },
        Statistics: {
          TotalRequests: 'total requests',
          RequestAssign: 'requests assigned to you',
          OverdueRequests: 'overdue requests',
          InProgress: 'In progress',
          FollowUp: 'Require follow-up',
          Submitted: 'Submitted',
        },
        Filters: {
          SearchByKeyword: 'Search by keyword',
          ExpandAll: 'Expand all',
          CollapseAll: 'Collapse all',
        },
        List: {
          Columns: {
            RequestName: 'Request Name',
            Assignee: 'Assignee',
            DueDate: 'Due Date',
            Status: 'Status',
            Activity: 'Activity',
            Priority: 'Priority',
            Locked: 'Locked',
          },
        },
        LoadMore: 'Load more',
      },
      DocumentRequestListHeader: 'DOCUMENT REQUEST LIST',
      DocumentRequestButtonCreateRequest: 'Create a new request',
      DocumentRequestSearchLabel: 'Search by doc name',
      DocumentRequestCreateRequestModalTitle: 'Create a new document request',
      DocumentRequestCreateRequestModalSubtitle:
        'Please fill in the fields below to create a document request',
      DocumentRequestCsvUploadErrorModalTitle: 'Error Report',
      DocumentRequestCsvUploadErrorModalSubtitle:
        'Failed to create a new document request.',
      Docs: {
        ECPDocsTitle: 'myRSM - Docs',
        DocumentListHeader: 'ALL DOCUMENTS',
        DocumentSearchLabel: 'Search By title:',
        ConfirmDeleteTitle_one: '{{count}} selected item will be deleted.',
        ConfirmDeleteTitle_other: '{{count}} selected items will be deleted.',
        ConfirmDeleteDescription:
          'Are you sure you wish to delete the selected document(s)?',
        AlertDeletedTitle: 'Delete Successful.',
        AlertDeletedDescription: 'The selected document(s) are deleted.',
        NoDocumentsForCurrentUser: 'You don’t have any documents yet.',
        NoSearchResult: 'No results found',
        NoDocumentsFound: 'No requests have uploaded documents.',
        TrySearchText:
          'Try broadening your search term or removing filters to see results.',
        ViewDocRequesButtonText: 'Visit Document Requests page',
        Headers: {
          documentName: 'Document Name',
          createdBy: 'Uploaded By',
          createdDate: 'Date',
          group: 'Group',
          subgroup: 'Subgroup',
          status: 'Status',
          contentType: 'Content Type',
          request: 'Request',
        },
      },
      EngagementList: {
        NoSearchResultsTitle: 'No results found',
        NoSearchResultsSubTitle: 'Try broadening your search term(s).',
        GoToTop: 'Go to top',
      },
      EngagementDashboard: {
        RequestSummaryHeader: 'REQUEST SUMMARY',
        ViewAllEngagements: 'View all engagements',
        RequestTaskCompletionHeader: 'STATUS BREAKDOWN',
        DueDateCalendarHeader: 'DUE DATE CALENDAR',
        TeamMembersHeader: 'TEAM MEMBERS',

        TeamMembersSubHeader:
          'These people currently have access to this engagement.',
        EmptyStatusBreakdownMainHeader: "You're all caught up!",
        EmptyStatusBreakdownSubHeader:
          'No requests need attention at this time.',
        Summary: {
          AssignedToYou: 'Assigned to you',
          CreatedByYou: 'Created by you',
          AllRequests: 'All requests',
          OutOf: 'out of',
          ViewAllRequests: 'View all requests',
          OverdueRequestsPhrase: 'requests are currently overdue',
          OutstandingRequestsPhrase: 'priority requests are outstanding',
          FollowUpRequestsPhrase: 'requests require follow-up',
          StatusBreakDown: {
            TotalRequests: 'This engagement currently has {{count}} requests',
            CompletedLabel: 'Completed',
            FollowUpLabel: 'Follow-up',
            SubmittedLabel: 'Submitted',
            InProgressLabel: 'In Progress',
            RequestedLabel: 'Requested',
          },
        },
        TeamMembers: {
          RSMTeam: 'RSM team',
          ClientTeam: 'Client team',
        },
      },
      ECPAllEngagementsTitle: 'myRSM - All Engagements',
      ECPEngagementsTitle: 'myRSM - Engagement',
      EngagementListHeader: 'ALL ENGAGEMENTS',
      EngagementMembersHeader: 'TEAM MEMBERS',
      ErrorBoundary: {
        DefaultErrorMessage: 'Sorry... there was an error',
      },
      FilterChips: {
        ClearAll: 'Clear all',
        ClearAllAppliedFilters: 'Clear all applied filters',
        RemoveFilter: 'Remove',
        ShowLess: 'Show less',
        ShowLessAppliedFilters: 'Show less applied filters',
        ShowMoreAppliedFilters: 'Show {{count}} more applied filters',
      },
      Form: {
        RequiredField: 'This field is required.',
        Submit: 'Submit',
        Maximum: 'Max the length for this field is- {{MAX_VALUE}} characters',
        Minimum: 'Min the length for this field is- {{MIN_VALUE}} characters',
        MaximumReached: 'You have reached the character limit',
        Characters: 'characters',
      },
      HomeEngagementsHeader: 'YOUR ENGAGEMENTS',
      HomeToolsHeader: 'TOOLS',
      HomeRequestsHeader: 'UPCOMING REQUESTS',
      HomeFavoritedHeader: 'FAVORITED',
      HomeInsightsHeader: 'LATEST INSIGHTS',
      HomePage: {
        ECPHomeTitle: 'myRSM - Home',
        EngagementsHeader: 'ENGAGEMENTS',
        Header: 'myRSM Homepage',
        InsightsHeader: 'RECENTLY ADDED',
        ToolsHeader: 'TOOLS',
        ToolsLoadMore: 'Load more',
        Launch: 'Launch',
        ViewProjects: 'View projects',
        SubmitSupportTicket: 'Submit a support ticket',
        AreYouMissingTools: 'ARE YOU MISSING TOOLS?',
        RequestMissingTools:
          'Request additional tools by completing the myRSM support form.',
        AreYouMissingToolsCategory: 'EXAMPLE: DASH, TEAMDOCS, ETC.',
        ArticleFeaturedContent: 'Featured Content: Article',
        ArticleReadMore: 'Read more',
        ArticleReadNow: 'Read now',
        ViewAllEngagements: 'View all engagements',
        ViewAllInsights: 'View all insights',
        TeamDocsCategory: 'Consulting',
        TeamDocsDescription:
          'Collaborate with your project team using this engagement tool',
        NotFindingWhatYouNeed: 'Not finding what you need?',
        VisitAppManager:
          "Visit the legacy <0>{{appManager}}</0> for a full list of applications and for password management. If you're still having trouble, submit a support ticket <1>{{here}}</1>.",
        Here: 'here',
        AppManager: 'app manager',
      },
      Projects: {
        Title: 'Project Sites',
        LoadMore: 'Load more',
        GoToTop: 'Go to top',
        HeaderMessage:
          'Missing projects? Check your email for an invitation to the project site in question. If you do not have any invites contact your project team. Once added or removed from a TeamDocs project, myRSM will be updated within 24 hours.',
        SupportCard: {
          Name: 'Support',
          Category: 'Support documentation',
          SecuritySummaryLabel: 'TeamDocs security summary',
          SecuritySummaryUrl:
            'https://rsmnet.sharepoint.com/:b:/r/teams/cd/Shared%20Documents/External%20-%20Published/RSM%20TeamDocs%20Security%20Summary.pdf?csf=1&web=1&e=e5o4Qr',
          LoggingInWith365Label: 'TeamDocs log in with O365 Account',
          LoggingInWith365Url:
            'https://rsmnet.sharepoint.com/:b:/r/teams/cd/Shared%20Documents/External%20-%20Published/External%20User%20Login%20with%20O365%20Account%20into%20TeamDocs.pdf?csf=1&web=1&e=rZKcqQ',
          LoggingInWithout365Label: 'TeamDocs log in without O365 Account',
          LoggingInWithout365Url:
            'https://rsmnet.sharepoint.com/:b:/r/teams/cd/Shared%20Documents/External%20-%20Published/External%20User%20Login%20without%20an%20O365%20Account%20into%20TeamDocs.pdf?csf=1&web=1&e=qh1PB4',
          ContactSupport: 'Contact support',
        },
        NoSearchResultsTitle: 'No results found',
        NoSearchResultsSubTitle: 'Try broadening your search term(s).',
        ReportCard: {
          Name: 'Report an issue',
          Category:
            'Experiencing issues like missing projects or broken links? Report your issue here.',
        },
        ReportAnIssuePage: {
          ReportAnIssue: 'Report an Issue',
        },
        ReportAnIssueForm: {
          title: 'Report an issue form',
          description:
            'Please describe the issue you are experiencing by submitting the form below and an RSM US representative will follow up with you. *Required field.',
          descriptionLabel: 'Description',
          issue: 'Issue',
          projectName: 'Project Name',
          issueOptions: {
            notAccurate: 'My Project list is not accurate',
            notLoading: 'Project not loading when I click the link',
            incorrect: 'Something is incorrect with a project',
            other: 'Other',
          },
          issueWarning:
            '*The project list can take up to 24 hours to be updated.',
          toast: {
            title: 'Support ticket submitted',
            description:
              'Your Service Desk request has been received. Expect an email response within 48 hours.',
          },
        },
      },
      InsightsList: {
        ECPInsightsHeading: 'Latest RSM Insights',
        InsightsFilter: 'Filters',
        InsightsClearChips: 'Clear all',
        InsightsChipAriaPrefix: 'Remove filter ',
        // NOTE: FilterLabels are tightly coupled to the Redux store.
        // Modifying the 2 values below risks breaking the Insights chip functionality.
        FilterLabels: {
          all: 'All Insights',
          favorites: 'favorites',
          recentlyViewed: 'recently-viewed',
          myInterestsLabel: 'My Interests',
          sharedWithMe: 'shared-with-me',
        },
        FilterHeaders: {
          yourinsights: 'Your insights',
          role: 'Role',
          industry: 'Industry',
          service: 'Service line',
          topic: 'Topic',
        },
        ReadMore: 'Read more',
        LoadMore: 'Load more',
        GoTotop: 'Go to top',
        NoSearchResults: 'No results found',
        NoSearchResultsAdvice:
          'Try broadening your search term or removing filters to see results.',
      },
      InsightArticle: {
        ShareArticle: 'Share article',
        AddToFavorites: 'Favorite this article',
        RemoveFromFavorites: 'Remove from Favorites',
        BackToInsights: 'View all Insights',
        InsightArticle: 'Insight Article',
        RSMContributors: 'RSM Contributors',
        Tags: 'Tags',
        DefaultTag: ' ',
        ArticleDate: '{{date, medium}}',
        ArticleSharedSuccess: 'Article shared successfully',
        ArticleSharedFail: 'Unable to share article',
        ArticleSharedWarning:
          'Unable to share with {{count}} of the recipients',
        ArticleSharedSuccessMessageMultiUser:
          'The article has been emailed to all recipients.',
        ArticleSharedFailMessageMultiUser: `We were unable to send this article to {{names}} due to an unexpected error. `,
        ArticleSharedSuccessMessage: 'Article was sent to {{name}}.',
        ArticleSharedError: 'Unable to share',
        ArticleSharedErrorMessage:
          'There was an error and we were unable to send your message. Please try again or contact support if the issue continues.',
        ShareArticleMessage: 'shared This Article with you',
      },
      ShareArticleModal: {
        Title: 'Share this insight article',
        DefaultMessage: `Hi {{receiverName}},\nI was on myRSM and found this article I thought you might be interested in.\n\nBest,\n{{senderName}}`,
        DefaultMessageNonEcpUser: `Hi {{receiverName}},\nI was on myRSM and found this article I thought you might be interested in.\nYou'll need to join myRSM to view it, but I think it's well worth it!\n\nBest,\n{{senderName}}`,
        DefaultMessageMultiple: `Hi, \nI was on myRSM and found this article I thought you might be interested in.\n\nBest,\n{{senderName}}`,
        IncludeMessageLabel: 'Include a message:',
        RequiredField: 'This is a required field.',
        UserSearchInputPlaceholder: 'Search by first or last name',
        AriaLabel: {
          ShareArticle: 'Share Article',
          AriaCloseDialog: 'Close dialog',
        },
        NoOptionsText: 'No results found for any user by that name.',
        EcpWarning:
          'This user does not have myRSM access yet.  If you continue they will get access to myRSM which is required to view any shared article.',
        NonEcpUserAckMessage:
          'Yes, give these users myRSM access. This is required to view this article.',
        NonEcpUserAckRequired:
          'This is a required field, please acknowledge or remove non-myRSM users to continue.',
        NonEcpUserInfoMessage: "These recipients don't have myRSM access yet.",
        MaxUserLimit: 'Maximum number of recipients reached',
      },
      NotificationTemplates: {
        ArticleShared:
          '{{FirstName}} {{LastName}} shared an article <0>{{ArticleName}}</0> with you',
        NoMessages: 'You don’t have any notifications.',
        NotificationsTitle: 'Notifications',
        NotificationsLabel: 'Notifications, {{count}} new',
        DeleteNotification: 'Delete notification',
        DateAgo: '{{date, ago}}',
      },
      ECPInsightsTitle: 'myRSM - Insights',
      InsightsFeaturedTopicsHeader: 'FEATURED TOPICS: CATEGORIES',
      InsightsIndustryInsightsHeader: 'INDUSTRY INSIGHTS: ARTICLES',
      InsightsServiceLineRelatedHeader:
        'SERVICE-LINE RELATED INSIGHTS: ARTICLES',
      ECPNoMatchTitle: 'myRSM - Not Found',
      NoMatchHeader: 'No Match',
      ECPNotificationsTitle: 'myRSM - Notifications',
      NotificationsHeader: 'NOTIFICATIONS',
      ECPSupportTitle: 'myRSM - Support',
      RSMSupportHeading: 'Support',
      SupportFormTitle: 'SUPPORT FORM',
      ActivityFeedTitle: 'Activity feed',
      OutOfTime: 'Out of time',
      LockStatusRequest:
        'Locking this request will make details viewable only to the assignee and the RSM members in this engagement.',
      Engagement: {
        Name: 'ENGAGEMENT NAME',
        StartDate: 'START DATE',
        EndDate: 'END DATE',
        Client: 'CLIENT',
        Entity: 'ENTITY',
        Type: 'TYPE',
        LOB: 'LOB',
        LineOfBusiness: 'LINE OF BUSINESS',
        ViewEngagement: 'View engagement',
        ViewEngagementAriaLabel: 'View {{engagement}} engagement',
      },
      DocReq: {
        NewForm: {
          Success: 'Success!',
          RequestName: 'Request name',
          DocReqInformation: 'Document request information',
          DueDate: 'Due date',
          FunctionalArea: 'Functional area',
          Group: 'Group',
          GroupPlaceholder: 'Type name to search or enter as new group',
          Subgroup: 'Subgroup',
          SubgroupPlaceholder: 'Type name to search or enter as new subgroup',
          RequestSettings: 'Request settings',
          MarkAsPriority: 'Mark as priority',
          PriorityTooltip:
            'Marking as Priority will flag this request and enable related email notifications.',
          LockRequest: 'Lock request',
          LockTooltip:
            'Locking this request will make details viewable only to the assignee and the RSM members in this engagement.',
          Description: 'Description',
          AssignRequest: 'Assign request',
          SearchForUser: 'Search for user',
          AssigneeNoMatch: 'No results found',
          MaxAssignees:
            'You have reached the max of {{MaxAssignees}} assignees per request',
          MaxAssigneesMobile: 'Maximum of {{MaxAssignees}} assignees',
          TypeNameOrEmail: 'Type name or email',
          ClientAssignees: 'Client assignees',
          RSMAssignees: 'RSM assignees',
          Submit: 'Submit',
          AriaLabel: {
            RequestName: 'Request name field',
            DueDate: 'Due date field',
            FunctionalArea: 'Functional area field',
            Group: 'Group field',
            Subgroup: 'Subgroup field',
            PriorityIcon: 'Mark as priority checkbox',
            LockIcon: 'Lock request checkbox',
            SearchForUser: 'Assignee Search field',
            ClientAssignees: 'Client assignees field',
            RSMAssignees: 'RSM assignees field',
            FLAdded: '{{Name}} added.',
            FLRemoved: '{{Name}} removed.',
            UpDownNavigate: 'Use up or down arrows to navigate.',
            BackspaceToRemove:
              'To remove assignee press the delete button or backspace',
            Description: 'Description field',
            SearchEmail: 'Search email field',
            Submit: 'Submit',
          },
          SuccessModal: {
            Title: 'Success!',
            Subtitle: 'Your document request has been successfully created.',
            RequestName: 'Request name',
            DueDate: 'Due date',
            FunctionalArea: 'Functional area',
            Group: 'Group',
            Subgroup: 'Subgroup',
            PriorityStatus: 'Priority status',
            PriorityStatusIsPriority: 'Marked as priority',
            PriorityStatusIsNotPriority: 'Not marked as priority',
            LockStatus: 'Lock status',
            LockStatusLocked: 'Locked',
            LockStatusUnlocked: 'Unlocked',
            Description: 'Description',
            ClientAssignees: 'Client assignees',
            RSMAssignees: 'RSM assignees',
            Close: 'Close',
          },
        },
        RequestFollowUpModal: {
          Title: 'Request follow-up',
          SubTitle: 'What information do you need from client?',
          AddComment: 'Add a comment',
          MaximumReached:
            'Comment must be {{MIN_LENGTH}} to {{MAX_LENGTH}} characters long.',
          AriaLabel: {
            CommentAdded: 'Comment added',
            CharsEntered: '{{charCount}} of {{MAX_LENGTH}} characters entered',
            CloseDialog: 'Close Dialog',
            name: 'reqeust-followup',
          },
        },
        RequestAddComment: {
          AriaCloseDialog: 'Close dialog',
          AriaCommentAdded: 'Comment added',
          Title: 'Add comment',
          PlaceholderNote: 'Add a comment',
          MaximumReached:
            'Comment must be {{MIN_LENGTH}} to {{MAX_LENGTH}} characters long.',
          AriaCharactersEntered:
            '{{charCount}} of {{MAX_LENGTH}} characters entered',
        },
        ViewDetails: {
          MarkedAsPriority: 'Marked as priority',
          NoPriority: 'Not marked as priority',
          Priority: 'Priority',
          FunctionalArea: 'Functional area',
          DueDate: 'Due date',
          Group: 'Group',
          Subgroup: 'Subgroup',
          Lock: 'Lock',
          Locked: 'Locked',
          Unlocked: 'Unlocked',
          CurrentStatus: 'Current status',
          CurrentStatusCountMsg: 'Updated {{countDays}} days ago',
          CurrentStatusDateMsg: 'Updated on',
          DueDateMsg: 'Due in {{countDays}} Days',
          DueToday: 'Due Today',
          UpdatedToday: 'Updated Today',
          CurrentClientAssignees: 'Current client assignees',
          CurrentRSMAssignees: 'Current RSM assignees',
          DocumentRequestStatus: {
            Requested: 'Requested',
            InProgress: 'In progress',
            Submitted: 'Submitted',
            FollowUp: 'Follow Up',
            Complete: 'Complete',
          },
          ActivityDesc: {
            ShowMore: '+ More',
            ShowLess: '- Less',
          },
        },
        CurrentAssignees: 'Current assignees',
        Summary:
          'This is a list of documents you’ve requested. Currently {{uploaded}} out of {{total}} documents requested have been uploaded. {{inProgress}} requests are in progress and {{followUp}} require follow-up.&nbsp;',
        SummaryOverdue: '{{overdue}} request is currently overdue.',
        ActionButtons: {
          Upload: 'Upload document(s)',
          Submit: 'Submit request',
          FollowUp: 'Request follow up',
          Complete: 'Mark as complete',
        },
        Documents: {
          SectionTitle: 'Uploaded document(s)',
          DownloadAll: 'Download all',
          Download: 'Download',
          Delete: 'Delete',
          NoDocs: "There's nothing here yet",
        },
      },
      AutoComplete: {
        AriaLabel: {
          FLAdded: '{{list}} added.',
          FLRemoved: '{{list}} removed.',
          UpDownNavigate: 'Use up or down arrows to navigate.',
        },
      },
      SearchUserByEmail: 'Search for user by email',
      DocumentRequest: 'Document Request',
      Assignee: 'Assignee',
      DueDate: 'Due Date',
      Status: 'Status',
      Activity: 'Activity',
      SearchByDocName: 'Search by document name',
      SearchByKeyword: 'Search by keyword',
      SearchProcessedByKeyword: 'Search processed by',
      SearchRegion: 'Search region',
      searchByPayor: 'Start typing to search',
      SearchScreenReaderInstructions: 'Results will filter below as you type',
      Results: 'results',
      CreateNewDocReq: 'Create a new request',
      SubmitDocReq: 'Submit request',
      MarkComplete: 'Mark as complete',
      FollowUp: 'Request follow-up',
      UploadDoc: 'Upload document',
      AddComment: 'Add comment',
      CsvErrorModal: {
        ErrorReport: 'Error Report',
        ExplanationMessage: 'Failed to create a new document request.',
      },
      NewDocReqModal: {
        Title: 'Create a new document request',
        SubTitle:
          'Please fill in the fields below to create a document request',
      },
      Name: 'Name',
      EngagementName: 'Engagement name',
      ContactMethod: 'Preferred contact method',
      BusinessEmail: 'Contact Email',
      BusinessPhone: 'Contact Phone Number',
      ClientBusName: 'Client/Business name',
      DashIssue: 'Is this a DASH issue?',
      DashFunctionalityIssue: 'Is this is DASH functionality issue?',
      DashFunctionalityIssueWarning:
        'Dash issues cannot be supported through myRSM, please submit an issue through the DASH portal.',
      DashIssueShortened: 'Dash issue',
      DashIssueExplanation:
        'DASH issues cannot be submitted through myRSM, please <2>submit an issue with DASH</2>',
      DashSupportLink: 'dash support page link',
      ProceedToDash: 'Proceed to DASH',
      LOB: 'Line of service',
      Category: 'Category',
      Description: 'Description',
      SysPortal: 'System/Portal trying to access',
      IssueDesc: 'Issue',
      IsARequiredField: 'is a required field.',
      FeedbackDesciption: 'Feedback description is a required field',
      ReadMore: 'Read More',
      RequestAccess: {
        ThankYou: 'Thank you for your interest in the myRSM.',
        SubmitRequest:
          'Please submit your request to initiate access for the following account.',
      },
      Reset: 'Reset',
      Submit: 'Submit',
      SelectEngagement: 'Select engagement',
      SelectBusiness: 'Select',
      Other: 'Other',
      SessionTimeout: 'Session Timeout',
      SessionTimeoutExplination: 'Your session is about to expire.',
      SupportSuccess: 'Support ticket submitted',
      SupportSuccessMessage:
        'Your Service Desk request has been received. Expect an email response within 48 hours.',
      SupportFormSuccessMessage:
        'A member of the support will reach out to you within 2 business days to assist with your request. If more immediate assistance is required, please call the support team at {{supportPhone}}.',
      SupportError: 'ALERT',
      SupportErrorMessage:
        'We were unable to process this request due to a system error. Please try again later.',
      UpdateSuccess: 'Updated successfully',
      UpdateSuccessMessage: 'Your profile preferences have been updated.',
      UpdateError: 'ALERT',
      UpdateErrorMessage:
        'We were unable to process this request due to a system error. ',
      SupportSuccessLink: 'Longer CTA Message Lorem ipsum dolor sit amet',
      SupportFormDesc:
        'Please describe the myRSM issue you are experiencing by submitting the form below and a myRSM representative will follow up with you. *Required field.',
      SupportPageFormDesc:
        'Please use the form to submit issues related to your myRSM experience. If the issue pertains to an application accessed through myRSM, your inquiry will be routed to the appropriate team. For topics related to your services, please reach out to your account manager.',
      CustomerService: 'Customer Service : ',
      SupportPageFormFooterDesc:
        'A representative from the support team will contact you directly within 2 business days.',
      SelectCategory: 'Select Category',
      SelectIssue: 'Select Issue',
      SupportFormDashDropdown: {
        Yes: 'Yes',
        No: 'No',
        ClearSelection: 'Clear selection',
      },
      RequiredField: 'This is a required field.',
      ValidPhoneNumber: 'Please enter a valid phone number',
      ValidCurrency: 'Please enter a valid amount',
      ValidDate: 'Please enter a valid date',
      ValidDateInRange:
        'Please enter a date between {{minDate, P}} and {{maxDate, P}}',
      ValidInput: 'Invalid input',
      ShortRequiredField: 'Required field',
      Filters: {
        Clear: 'Clear',
        CollapsedToggle: {
          SeeMore: 'See more',
          SeeLess: 'See less',
        },
      },
      Dialogs: {
        OkText: 'OK',
        ConfirmText: 'Confirm',
        CancelText: 'Cancel',
        SaveText: 'Save',
      },
      CallUs: {
        Heading: 'SUPPORT',
        Title: 'Call us',
        TollFree: 'Toll Free:',
        Description:
          'Accounts Receivable customer service hours are \nMonday - Friday, 9AM - 4PM CT.',
        InternalDescription:
          'Customer Service hours are:\nSun 9pm - Fri 9pm CT\nSat & Sun 8am - 5pm CT',
        ExternalDescription:
          'Customer Service hours are:\nSun 9pm - Fri 9pm CT',
        USandCANADA: 'US and Canada',
      },
      Faq: {
        Title: 'FAQs',
        Description:
          'Many questions can be quickly answered by checking our FAQs.',
        ButtonText: 'Read FAQs',
        FormTitle: 'Frequently asked questions',
        FormDesc:
          'How can we help you? This page provides help with the most common questions please find them listed below.',
        FaqErrorTitle: 'Uh oh...',
        FaqErrorDesc:
          'It seems that the system couldn’t load the FAQs. Please try refreshing the page or coming back later to see if this issue has resolved.',
        Refresh: 'Refresh',
      },
      FaqPage: {
        Title: 'FAQs',
        Description:
          'Have questions? Here you will find answers to common questions. If you aren’t finding what you need, submit a support ticket and someone will get back to you.',
        FaqErrorTitle: 'Uh oh...',
        FaqErrorDesc:
          'It seems that the system couldn’t load the FAQs. Please try refreshing the page or coming back later to see if this issue has resolved.',
        Refresh: 'Refresh',
        Tools: 'Tools',
        Insights: 'Insights',
        TeamDocs: 'TeamDocs',
        UserProfile: 'User profile',
        SupportPage: 'Support',
      },
      FeedbackPage: {
        Title: 'Feedback',
        Description:
          'Please use the form to submit feedback related to your myRSM experience.',
        FeedbackDetailsLabel:
          '<b>How can we improve the myRSM experience? (or if you loved it, we’ll take that too!)*</b>',
        SubmitButton: 'Submit',
        contactPreferenceHelperText:
          '<b>If we need clarification on any of your responses, may we contact you?*</b>',
        ContactPreferenceEmail: 'Yes, I prefer an email',
        ContactPreferencePhone: 'Yes, I prefer a phone call',
        ContactPreferenceNone: 'No, please do not contact me',
        FeedbackErrorHeader: 'ALERT',
        FeedbackErrorMessage:
          'We were unable to submit this feedback due to a system error. Please try again later.',
        FeedbackSuccessHeader: 'Feedback successfully submitted!',
        FeedbackSuccessMessage:
          'We appreciate your feedback. We are committed to continuous improvement, and your insights help us achieve that.',
      },
      RobotPage: {
        TechnicalDifficulties: 'Experiencing technical difficulties',
        SomeSectionsUnloadable:
          'Some sections of this page were unable to load.',
        TryAgainLaterMessage: 'Please refresh or try again later.',
      },
      UploadFile: {
        Title: 'Upload Document',
        Description: 'Description goes here',
      },
      PageUnavailableHeader: 'Page unavailable',
      TechnicalIssuesMessage: 'We are experiencing technical issues.',
      PleaseTryAgainMessage: 'Please try again later.',
      TryAgainButtonLabel: 'Try again',
      SomethingsNotWorkingHeader: "Something's not working",
      ItemMovedOrDeletedMessage:
        "The item you're looking for may have been moved or deleted, or you may not have permission to view it. Please double-check your request.",
      SubmitSupportTicketMessage:
        'If you think this is a mistake, please <2>submit a support ticket.</2>',
      NoToolsMessage: "You don't have any RSM tools at this time.",
      IfYouAreMissingTools: "If you're missing tools, ",
      SubmitSupportTicket: 'submit a support ticket.',
      SearchLayout: {
        Apply: 'Apply',
        ClearAll: 'Clear all',
        Filters: 'Filters',
        ScrollToTop: 'Scroll to top',
      },
      Invoicing: {
        Accept: 'Accept',
        ACH: 'ACH',
        Actions: 'Actions',
        ActivityFeed: {
          InvoicePastDue: 'Invoice past due',
          InvoicePaymentProcessed: 'Invoice payment processed',
          PaymentScheduled: 'Payment scheduled',
          NewInvoice: 'New invoice',
          DaysOverdue: 'days overdue',
          ScheduledPaymentFor: 'scheduled a payment for',
          PaymentMade: 'payment made',
          At: 'AT',
        },
        AccountInformationCard: {
          Welcome: 'Welcome!',
          ClientNumber: 'Client Number:',
          PrimaryAccountAddress: 'Primary Account Address:',
          BusinessPhone: 'Business Phone:',
          ClientAutocompleteInternalPlaceholder:
            'Enter client number or name to locate account',
          ClientAutocompleteExternalPlaceholder: 'All Accounts',
          NoResults: 'No results found',
          NoClientNote:
            '<b>Note:</b> If you are having trouble locating an account by invoice number, Client Number, or Client Name, there may not be a record in this system. Confirm that a record exists in Workday.',
          PayNowNote:
            '<b>Note:</b> The above amount is the total open amount for all accounts that you are associated to. Selecting Pay Now will initiate payment across all accounts. To pay for a specific account, select from the list to the left.',
        },
        AccountSupport: {
          PhoneTitle: 'Call us',
          PhoneDescription:
            'Accounts Receivable customer\nservice hours are\nMon - Fri,  9AM - 4PM CT.',
          PhoneNumberPrefix: 'Toll Free: ',
          PhoneNumber: '833-597-2118',
          PhoneNumberSuffix: 'US and Canada',
          EmailTitle: 'Email us',
          EmailDescription:
            'Emails are responded to in the order they are received.',
          EmailAddresses:
            '<0>Email:</0> <1>accounts_receivable@rsmus.com</1> or\n<2>accounts_receivable@rsmcanada.com</2>',
        },
        AccountManagementHelpfulLinks: {
          AccountCentral: {
            LinkText: 'Account Central',
            LinkDescription: 'Add additional users to this portal',
          },
          CEM: {
            LinkText: 'CEM',
            LinkDescription: 'Update myRSM Roles',
          },
          PayTrace: {
            LinkText: 'Paytrace',
            LinkDescription: 'Access backdoor credit card processing',
          },
          Workday: {
            LinkText: 'Workday',
            LinkDescription: 'Access client information',
          },
        },
        AccountReferenceDocuments: {
          FaqTitle: 'FAQs',
          FaqDescription:
            'Many questions can be quickly answered by checking our FAQs.',
          FaqButtonText: 'Read FAQs',
          AddlDocsTitle: 'Additional Documentation',
          FirstTermsAndConditions: 'Consent to Electronic Communications.pdf',
          OneTimePayment: 'One-Time Payment-US and Canada.pdf',
          RecurringPayment: 'Auto and Recurring payments US.pdf',
          PADAgreementCanada:
            'Business Pre-Authorized Debit (PAD) Agreement-Canada.pdf',
        },
        AutoPayPage: {
          AutoPayLimit: 'Set Autopay Limit',
          AutoPayLimitAmount: 'Limit Amount',
          AutoPayLimitAmountNoDescription:
            'An Autopay limit has not been set. At the time of each Autopay transaction, the entire open balance will be processed.',
          AutoPayLimitAmountYesDescription:
            'You are choosing to set a limit for each Autopay transaction. After each payment processes, any outstanding balance above the limit you specify below will remain on the account.',
          AutoPayLimitNo: 'No',
          AutoPayLimitYes: 'Yes',
          AutoPayCancelled: 'Autopay Successfully Cancelled',
          BankAccountRequired:
            'Choose an existing bank account is a required field.',
          BiWeekly: 'Bi-weekly',
          CancelAutoPay: 'Cancel Autopay',
          CancelAutoPayMessage:
            'You are about to cancel your {{frequency}} Autopay plan. Your open balance will no longer be automatically paid.',
          CancelAutoPayConfirm: 'Confirm cancel Autopay',
          CreditCardRequired:
            'Choose an existing credit card is a required field.',
          Daily: 'Daily',
          EditCancelAutoPay: 'Edit/Cancel Autopay',
          Frequency: 'Frequency',
          FrequencyRequired: 'Frequency is a required field.',
          HeaderDescriptionEdit:
            'You can make chances to your autopay plan below. To cancel  autopay entirely, select "Cancel Autopay"',
          HeaderDescriptionDashboard:
            'Choose your payment frequency, amount and start date below. Cancel anytime via the Dashboard. Receipts will be available in the Payment History tab once processed.',
          HeaderDescriptionAccountManagement:
            'Choose your payment frequency, amount and start date below. Cancel anytime via Account Management. Receipts will be available in the Payment History tab once processed.',
          InvalidCharacters: 'Invalid characters.',
          LimitAmountRequired: 'Limit Amount is a required field.',
          LimitAmountMinimumRequired:
            // eslint-disable-next-line no-template-curly-in-string
            'Autopay minimum limit is ${{minAmount}}, please enter a value greater than or equal to ${{minAmount}}.',
          LimitAmountMaximumRequired:
            // eslint-disable-next-line no-template-curly-in-string
            'Enter a payment less than or equal to ${{maxAmount}}.',
          Monthly: 'Monthly',
          NextAutopayDate: 'Next Autopay Date',
          NextProcessingDate: '<b>Next processing Date:</b> {{date, short}}',
          Note: '<b>Note:</b> You will only be charged through autopayment if you have an open balance on your account. If there is not a balance, no payment will be processed.',
          PaymentInformationHeader: 'Payment Information',
          PayerRequired: 'Payer is a required field.',
          PaymentStartDate: 'Payment start date',
          PaymentStartDateDailyMessage:
            '*Payments will be automatically initiated daily from the start date.',
          PaymentStartDateWeeklyMessage:
            '*Payments will be automatically initiated on the same day every week from the start date.',
          PaymentStartDateBiWeeklyMessage:
            '*Payments will be automatically initiated on the same day every other week from the start date.',
          PaymentStartDateMonthlyMessage:
            '*Payments will be automatically initiated on the same day every month from the start date.',
          PaymentStartDateRequired: 'Payment Start Date is a required field.',
          ValidAmount: 'Please enter a valid dollar amount.',
          Weekly: 'Weekly',
        },
        AutoPaySetupSuccessPage: {
          AutoPayEdit: 'Edit Autopay Success',
          AutoPayEditDescription:
            'Your recurring autopay has been updated. Once payments have processed, a record of the transaction can be found in the <PayHist>Payment History</PayHist> tab.',
          AutoPaySetup: 'Autopay Scheduled',
          AutoPaySetupDescription:
            'Your recurring autopay has been scheduled.  Once payments have processed, a record of the transaction can be found in the <PayHist> Payment History</PayHist> tab.',
          BackToAccountManagement: 'Back to Account Management',
          BackToDashboard: 'Back to Dashboard',
          ClientName: 'Client Name',
          Frequency: 'Frequency',
          AutoPayStartDate: 'Autopay Start Date',
          AutoPayStartDateFormat: '{{date, PP}}',
          AutoPayLimit: 'Autopay Limit',
          PaymentMethod: 'Payment method',
          PayerName: 'Payer name',
        },
        AutoPayDetailsPage: {
          Frequency: 'Frequency',
          AutoPayStartDate: 'Autopay Start Date',
          AutoPayStartDateFormat: '{{date, PP}}',
          AutoPayLimit: 'Autopay Limit',
          PaymentMethod: 'Payment method',
          PayerName: 'Payer name',
          ClientName: 'Client name',
          Details: 'Current Autopay Details:',
          NoLimitSet: 'No limit set',
          BankAccountEnding: 'Account ending {{accountNumber}}',
          CreditCardEnding: 'Credit card ending {{accountNumber}}',
        },
        PaymentManagement: {
          PaymentMethodAdded: 'Payment Method Added',
          PaymentMethodAddedMessage:
            'Your payment method has been successfully added!',
          PaymentManagementTitle: 'Payment Management',
          LookingForInvoice: 'Looking for an invoice?',
          FindAndDownloadReceipts: 'Find and Download Receipts',
          SavedPaymentMethods: 'Saved Payment Methods',
          SavedPaymentMethodsSub:
            '<outer>(Add or delete a payment method for <clientName>{{clientName}}</clientName> below)</outer>',
          AchAccounts: 'Bank Accounts',
          CreditCards: 'Credit Cards',
          BankAccountEnding: 'Account ending {{accountNumber}}',
          CreditCardEnabled: 'Enabled',
          CreditCardEnding: '{{cardType}} ending {{cardNumber}}',
          AddNewBankAccount: 'Add new bank account',
          AddNewCreditCard: 'Add new credit card',
          PaymentOptions:
            'To discuss additional payment options, please reach out to the Accounts Receivable team.',
          EnableCreditCard: 'Enable',
          Footer:
            'For assistance with payments or invoices, contact Accounts Receivable at <tel>833-597-2118</tel> or email <email>accounts_receivable@rsmus.com</email>',
          ConfirmEnableCreditCard: {
            Title: 'Confirm Enable CC Payments',
            EnableCreditCardReview:
              'You are about to enable Credit Card payments for <client>{{clientName}} ({{clientId}})</client>.<lb />Once enabled, you will not be able to undo this action.<lb /><lb /> Would you like to proceed?',
            Cancel: 'Cancel',
            Proceed: 'Confirm Enable CC Payments',
            CreditCardEnableSuccess: 'Credit card payments have been enabled.',
            CreditCardEnableSuccessHeader: 'Success',
            CreditCardEnableError:
              'Something went wrong, credit card payments were not enabled.',
          },
          ConfirmDeletePaymentMethod: {
            AutoPay: 'Autopay',
            Title: 'Confirm Delete',
            DeleteBankAccountReview:
              'Are you sure you want to delete Account ending in {{accountNumber}}?',
            DeleteCreditCardReview:
              'Are you sure you want to delete {{cardType}} ending in {{cardNumber}}?',
            Cancel: 'Cancel',
            Proceed: 'Confirm Delete',
            PaymentMethodDeletionError:
              'Something went wrong, the payment method was not deleted.',
            BankAccountDeletionSuccess:
              'The Account ending in {{accountSuffix}} has been deleted.',
            CreditCardDeletionSuccess:
              'The {{cardType}} ending in {{cardNumber}} has been deleted.',
            PaymentMethodDeleteSuccessHeader: 'Success',
            BankAccountEndingIn: 'Account ending in {{accountNumber}}',
            CreditCardEndingIn: '{{cardType}} ending in {{cardNumber}}',
            ScheduledPaymentDateSelect: 'Scheduled Payment ({{name}})',
            InstallmentPaymentDateSelect: 'Installment Plan ({{name}})',
          },
        },
        ScheduledPayment: {
          BankAccount: 'Bank Account',
          ScheduledPaymentTitle: 'Scheduled payments',
          ScheduledPaymentSelect: 'Select Scheduled Payment',
          ScheduledPaymentDate: 'Next Scheduled Payment Date: ',
          InstallmentPaymentDate: 'Next Installment Payment Date: ',
          ScheduledPaymentDetails: 'Scheduled Payment Details:',
          InstallmentPlanDetails: 'Installment Plan Details:',
          ChangePaymentDate: 'Change Payment Date',
          CancelScheduledPayment: 'Cancel Scheduled Payment',
          CancelInstallmentPayment: 'Cancel Installment Payment',
          NoRecords: 'No Records',
          NoRecordsData:
            'There are not currently any scheduled payments for this account. To schedule a payment or setup an installment plan, go to Invoices tab.',
          NoRecordsClientAdmin:
            'Select an account for a list of scheduled payments .',
          PaymentType: 'Payment Type: {{name}}  - Account ending in {{num}}',
          AccountsonPayment: 'Accounts on Plan : {{name}}',
          InvoicesPaid: 'Invoices Paid : {{name}}',
          DownloadDetails: 'Download details:',
          DownloadPaymentDetails: 'Download Payment Details',
          paymentSelectInstructions:
            'Payment details will automatically update below',
          ScheduledPaymentDateSelect: 'Scheduled Payment ({{name}})',
          InstallmentPaymentDateSelect: 'Installment Plan ({{name}})',
          InstallmentNumber:
            '(installment {{curentNumber}} of {{totalNumber}})',
          InstallmentPlanLength: 'Installment Length: {{length}}',
          InstallmentPlanStart: 'Installment Start Date: {{startDate}}',
          InstallmentPlanEnd: 'Final Payment Date: {{endDate}}',
          ScheduledPaymentDownloadError:
            'An Error occurred during download. Please try again.',
          ScheduledPaymentChangeDateAlertHeader:
            'Scheduled Payment Date Updated',
          ScheduledPaymentChangeDateAlertMessage:
            'Changes to scheduled payment have been made successfully.',
          CancelScheduledPaymentAlertHeader: 'Scheduled Payment Cancelled',
          CancelScheduledPaymentAlertMessage:
            'Your scheduled payment has been successfully cancelled.',
        },
        ChangeSchedulePaymentDate: {
          Title: 'Change Scheduled Payment Date',
          Description:
            'If you would like to change the date of the next scheduled payment for <b>{{planName}}</b>, select a new date in the calendar below.',
          Note: '<b>Note:</b> This change will affect the next scheduled payment ONLY.',
          ConfirmNewPaymentDate: 'Confirm New Payment Date',
          Cancel: 'Cancel',
        },
        AddNewBankAccount: '+ New bank account',
        AddNewCreditCard: '+ New credit card',
        PaymentMethodSaveNote:
          '<i>New payment methods will be saved to <b>{{clientName}}</b> account. To remove payment method, select "<b>$t(Invoicing.AccountInformationDoNotSave)</b>" and complete the transaction.</i>',
        AgreementModal: {
          TermsConditionInv: 'Terms and conditions',
          AcceptTermsCondition:
            'In order to accept or decline, you must scroll through the terms and conditions displayed below.',
        },
        Frequency: 'Frequency',
        FrequencyRequired: 'Frequency is a required field.',
        SelectInstallmentPlan: 'Select Installment Plan',
        SelectInstallmentPlanRequired:
          'Select Installment Plan is a required field.',
        SelectInstallmentPlanSub:
          '(installment plans based on total amount for selected invoice(s). If these selections do not fit your needs, call Accounts Receivable at 833-597-2118 to discuss additional payment options)',
        InstallmentPayments:
          '<0>{{num}} PAYMENTS :</0> {{paymentAmount}}/PAYMENT',
        InstallmentPaymentsUnformatted:
          '{{num}} PAYMENTS : {{paymentAmount}}/PAYMENT',
        InstallmentPaymentOther: 'OTHER',
        InstallmentPaymentOtherSelect: 'Other payment plans',
        InstallmentPaymentOtherRequired:
          'Other payment plans is a required field.',
        SelectInstallmentPayment: 'Please select Other payment plans.',
        SelectInstallmentPaymentError: 'Other payment plans',
        PaymentsStartDate: 'Payment start date',
        PaymentsStartDateRequired: 'Choose a Payment start date.',
        Monthly: 'Monthly',
        BiWeekly: 'Bi-weekly',
        Weekly: 'Weekly',
        AccountInformation: 'Account Information',
        AccountInformationSub:
          'Select from an existing account on file, or add a new account. New accounts can be saved for future payments or used for one time only payments.',
        AccountInformationDoNotSave: 'Delete after processing this payment',
        Amount: 'Amount',
        AmountScheduled: 'Amount scheduled',
        TotalInstallmentAmount: 'TOTAL INSTALLMENT AMOUNT',
        TotalInstallmentAmountSubText: '{{num}} installments of {{amount}}',
        TotalInstallmentAmountToolTip:
          'Payments are divided based on the number of installments and may be less than the stated amount',
        AmountSelected: 'Amount selected',
        AutoPay: 'Auto pay',
        BackToInvoices: 'Back to invoices',
        BackToPreviousPage: 'Back to previous page',
        BankAccountEnding: 'Bank account ending',
        CancelPayment: 'Cancel payment',
        ChooseBankAccount: 'Choose an existing bank account',
        ChooseCreditCard: 'Choose an existing credit card',
        SelectBankAccount:
          'Please select a bank account from the dropdown list',
        SelectCreditCard: 'Please select a credit card from the dropdown list',
        SelectBankAccountError: 'Select Bank Account',
        SelectCreditCardError: 'Select Credit Card',

        Clear: 'Clear',
        ClearSelection: 'Clear selection',
        Closed: 'Closed',
        Company: 'Company',
        ConfirmPayment: 'Confirm payment',
        Continue: 'Continue',
        ContinueToPayment: 'Continue to payment',
        CreditCardEnding: 'Credit card ending',
        CurrentAmountOwed: 'Current Amount Owed',
        CustomerName: 'Client Name',
        CustomerNumber: 'Client Number',
        BankAccount: 'Bank account',
        CreditCard: 'Credit card',
        Decline: 'Decline',
        Download: 'Download selected',
        InvoiceDowload: 'Download',
        DownloadCancelledPaymentNotice: 'Download Payment Cancellation Notice',
        DownloadError: 'Failed to Download',
        InvoiceonFailure: 'nofailure',
        InvoiceNotFound: 'notfound',
        InvoiceFail: 'fail',
        DownloadErrorMessage:
          'An Error occurred during download. Please try again.',
        DownloadErrorMessageForNotFound:
          'One or more invoice(s) selected cannot be downloaded at this time. Please call Accounts Receivable at 833-597-2118 for support.',
        DownloadErrorMessageInvoiceDocNotfound:
          'The selected invoice cannot be viewed at this time. Please call Accounts Receivable at 833.597.2118 for support.',
        DownloadFailedPaymentNotice: 'Download Payment Failure Notice',
        DownloadPaymentDetails: 'Download Payment Details',
        DownloadReceipt: 'Download Receipt',
        ECPInvoicesTitle: 'myRSM - Invoices',
        ECPInvoicingAdministrativeTitle: 'myRSM - Invoicing Administrative',
        ECPInvoicingTitle: 'myRSM - Invoicing',
        ECPPaymentsTitle: 'myRSM - Payments',
        Edit: 'Edit',
        EditPayment: 'Edit payment',
        EmailUs: 'Email us',
        EnterInvoiceOrKeywords: 'Enter invoice(s) # or keywords.',
        EnterInvoiceOrKeywordsSeperateCommas:
          'Enter invoice(s) # or keywords. Seperate multiple invoices with commas.',
        ErrorMessages: {
          MustBeLessThanOpenAmount: 'Input cannot exceed Invoice amount',
        },
        ExpYourSessionIsAboutToExpire: 'Your session is about to expire',
        Exp59Sec: '59 seconds left, your session is about to expire',
        Exp30Sec: '30 seconds until your session expires',
        Exp10Sec: '10 seconds until your session expires',
        Exp5Sec: '5 seconds until your session expires',
        ErrorGettingFilters: 'Error getting filters',
        FindAnInvoice: 'Find an invoice',
        FilterGroups: {
          amountrange: 'Amount Range',
          amountRangeHeader: '$ range:',
          amountRangeTooltip:
            'Amount slider range, Select a start and end amount.',
          client: 'Client',
          clientId: 'Client',
          daterange: 'Date',
          enddate: 'End date',
          date: 'Date',
          invoiceAmountFrom: 'Minimum Open Amount',
          invoiceAmountTo: 'Maximum Open Amount',
          paymentAmountFrom: 'Minimum Amount Paid',
          paymentAmountTo: 'Maximum Amount Paid',
          startdate: 'Start date',
          status: 'Status',
        },
        FilterCheckboxes: {
          open: 'Open',
          closed: 'Closed',
          pending: 'Pending',
          paid: 'Paid',
          scheduled: 'Scheduled',
          cancelled: 'Cancelled',
          failed: 'Failed',
        },
        IframeGenericErrorMessage:
          'Please validate the payment information and retry. If the issue persists, contact your bank or card provider for assistance.',
        IframeGenericErrorMessageHeader:
          'The Payment information could not be saved',
        IframeTitle: 'Payment Information Form',
        InvoiceDate: 'Invoice Date',
        InvoiceDateValue: '{{date, Datetime}}',
        InvoiceDateErrors: {
          EndDateIsRequired: 'End Date Required',
          InvalidDateRange: 'Invalid Date Range',
          InvalidStartDate: 'Invalid Start Date',
          InvalidEndDate: 'Invalid End Date',
          StartDateIsRequired: 'Start Date Required',
        },
        InvoiceNumber: 'Invoice Number',
        InvoiceAmount: 'Invoice Amount',
        InvoicesAmountRangeFilter: {
          GreaterThanMaxError: 'Amount must be less than Max.',
          InvalidNumberError: 'Please enter a valid dollar amount.',
          LessThanMinError: 'Amount must be greater than Min.',
          Maximum: 'Maximum',
          Minimum: 'Minimum',
          NegativeNumberError: 'Amount cannot be less than zero.',
          Title: 'Open Amount',
        },
        InvoicesTable: {
          InvoiceStatuses: {
            Open: 'Open',
            Closed: 'Closed',
            Scheduled: 'Scheduled',
          },
        },
        PaymentAmount: 'Payment Amount',
        PartialAmount: 'Partial Payment',
        PaymentAmountRangeFilter: {
          GreaterThanMaxError: 'Amount must be less than Max.',
          InvalidNumberError: 'Please enter a valid dollar amount.',
          LessThanMinError: 'Amount must be greater than Min.',
          Maximum: 'Maximum',
          Minimum: 'Minimum',
          NegativeNumberError: 'Amount cannot be less than zero.',
          Title: 'Amount Paid',
        },
        PaymentDate: 'Payment Date',
        PaymentDateMessage:
          '(To allow for processing, select payment date on/after {{date, P}})',
        PaymentDateRequired: 'Choose a payment date.',
        NetPayment: 'Net Payment',
        Initiated: 'Payment Date',
        Installment: 'Installment',
        Installments: 'Installments',
        Invoices: 'Invoices',
        InvoiceSelected: 'Invoice selected',
        InvoicesSelected: 'Invoices selected',
        ManageScheduledPayment: 'Manage Scheduled Payment',
        Max: 'Max',
        Method: 'Payment Type',
        NewBankAccount: 'New Bank Account',
        NewCreditCard: 'New Credit Card Account',
        NewPaymentMethod: {
          CardEndingIn: 'ending in {{num}}',
          BankAccountEndingIn: '{{name}} account ending in {{num}}',
        },
        NoBankInfoSaved: 'No Bank Account Saved',
        NoCardSaved: 'No Cards Saved',
        NoDataFound: 'No data found.',
        NoData: 'No data found',
        NoInvoices: 'No invoices found.',
        NoInvoicesAccountManager:
          'Navigate to Account Management tab to locate account by Client Name or Client Number.',
        NoInvoicesClientAdmin:
          'Navigate to Dashboard tab to locate account by Client Name or Client Number.',
        Note: 'Note',
        OpenActionsMenuForInvoice: 'Open actions menu for invoice',
        Open: 'Open',
        OpenAmount: 'Open Amount',
        OriginalAmount: 'Original Amount',
        OtherPaymentOptions: 'Other Payment Options',
        Paid: 'Paid',
        PaidAmount: 'Amount Paid',
        PaidBy: 'Paid by',
        PartialPaymentForInvoice:
          'Partial payment for invoice {{invoiceNumber}}',
        Pay: 'Pay',
        PaymentInformationHeader: 'Payment Information',
        PaymentDates: 'Payment Dates',
        Payments: 'Payments',
        PaymentGateway: {
          AddNewBankAccount: 'Add a New Bank Account',
          AddNewCreditCardAccount: 'Add a New Credit Card Account',
          PaymentInformation: 'Payment Information',
          TimeoutMessage:
            'You will have 5 mins to complete this form.  Once timed out the modal will close and all changes will not be saved.',
        },
        PaymentHistory: 'Payment history',
        PaymentHistoryDisclaimerHeading: 'Disclaimer:',
        PaymentHistoryDisclaimer:
          ' Only payments made on myRSM will be displayed here. Checks mailed or electronic payments processed through Bank of America will not be displayed in the payment history.',
        Payer: 'Payer',
        PayerMessage:
          'When adding a new account to the payer, all associated users to the selected payer will have access to that account.',
        PayerRequired: 'Please select a payer.',
        MaxPaymentAmountRequired:
          // eslint-disable-next-line no-template-curly-in-string
          'Enter payment less than or equal to ${{amount}}.',
        MinPaymentAmountRequired:
          // eslint-disable-next-line no-template-curly-in-string
          'Enter a payment of ${{amount}} or more.',
        PaySelectedInvoices: 'Pay selected invoice(s)',
        PaySelectedHelperText:
          'The status of any invoice paid via Credit card after 5.30pm or Bank account after 7PM(CT) will not be updated in myRSM for 2 business days.',
        PaymentInfo: 'Payment info will apply to all invoices selected.',
        PaymentMethod: 'Payment method',
        PaymentMethodComponent: {
          PaymentMethodAdded: 'Payment Method Added',
          PaymentMethodAddedMessage:
            'Your payment method has been successfully added!',
        },
        PaymentMade: 'Payment made',
        PaymentSelectionWillApply:
          'These payment selections will apply to all the invoices you’ve selected. To update invoice selection, return to previous screen.',
        PaymentSettings: 'Payment settings',
        PaymentTermsAndConditions: {
          Accepted: 'Accepted',
          AcceptTermsAndConditions:
            'I accept these payment terms and conditions',
          AcceptTermsAndCondPay: 'Accept and Pay',
          DeclineTermsAndCondPay: 'Decline',
          PaymentTermsAndConditions: 'Payment terms and conditions',
          TermsAndConditions: 'Terms and Conditions',
          ViewPaymentTermsAndConditions:
            'View payment terms and conditions to continue',
          ReadtermsandConditions:
            '* Please read the Terms and Conditions and  scroll down before proceeding to payment',
        },
        ReferenceDocuments: 'Reference Documents',
        HelpfulLinks: 'Helpful Links',
        Payment: 'Payment',
        PayNow: 'Pay Now',
        PayOnAccount: 'Pay on Account',
        SetupAutoPay: 'Set Up Autopay',
        EditAutoPay: 'Edit/Cancel Autopay',
        Autopay: 'Autopay',
        AutopayEnable: 'enabled',
        AutopayOn: 'Autopay ON',
        AutopayOff: 'Autopay OFF',
        Setup: 'Set up',
        Update: 'Update',
        PayOnAccountPage: {
          Account: 'Account',
          AssistanceMessage:
            ' For assistance with payments or invoices, contact Accounts Receivable at 833-597-2118 or email accounts_receivable@rsmus.com',
          BackToAccountManagement: 'Back to Account Management',
          BackToDashboard: 'Back to Dashboard',
          Comments: 'Comments',
          HeaderDescription:
            'Payments made via this screen will be applied directly to your account.  To pay specific invoice(s), navigate to ',
          InvalidCharacters: 'Invalid characters.',
          OpenInvoices: 'Open Invoices',
          PayAnotherAccount: 'To pay on another associated account, return to ',
          PaymentAmount: 'Payment Amount',
          PurchaseOrderQuoteNumber: 'Purchase Order/Quote Number',
        },
        Pending: 'Pending',
        ProcessedBy: 'Processed By',
        Retention:
          'Due to retention policy, the invoices from {{date}} Cannot be displayed. Please email your request to the appropriate email address.',
        Scheduled: 'Scheduled',
        ScheduledFor: 'Scheduled for',
        InstallmentScheduledFor: 'Installment Scheduled for',
        SchedulePayment: 'Schedule a payment',
        ScheduledPayments: 'Scheduled payments',
        SelectAllInvoices: 'Select all invoices',
        SelectAllPayments: 'Select all payments',
        SelectInvoice: 'Select invoice',
        SelectPaymentType: 'Select payment type',
        PaymentTyperequired: 'Payment type is a required field.',
        SetupInstallments: 'Set up installments',
        Share: 'Share',
        ShareReceipt: 'Share receipt',
        Status: 'Status',
        TotalAmount: 'Total amount',
        TotalNetPayableAmount: 'Total net payable amount',
        TotalPayableAmount: 'Total payable amount',
        TotalOpenAmount: 'Total Open Amount',
        PartialPaymentToolTipNote: 'Note: ',
        PartialPaymentToolTipText:
          'Partial payments can only be paid now and not scheduled.',
        TransactionId: 'Transaction ID',
        PaymentSuccessPage: {
          AccountName: 'Account name',
          CancelPayment: 'Cancel payment',
          Edit: 'Edit',
          PaymentScheduled: 'Payment scheduled',
          PaymentSuccess: 'Payment success',
          PaymentScheduledDescription:
            'Once processed, payment details will be available in',
          PaynowPaymentSucessDescription:
            'Your payment has been processed. A record of this transaction can be found in the',
          PaymentSucessDescription1:
            'Once payment has processed, a record of this transaction can be found in the',
          PaymentSucessDescription2: 'Payment History',
          PaymentSucessDescription3: 'tab.',
          TransactionId: 'Transaction ID',
          TransactionDate: 'Transaction date',
          DateScheduled: 'Scheduled Payment Date',
          TransactionDateFormat: '{{date, PP}}',
          PaymentMethod: 'Payment method',
          PayerName: 'Payer name',
          InstallmentPaymentDates: 'Installment Payment Dates',
          InstallmentPaymentDetails: 'Installment Payment Details',
          InstallmentPlan: 'Installment Plan',
          Invoices: 'Invoices',
          InvoicesPaid: 'Invoices paid',
          DownloadReceipt: 'Download Details',
          EditInstallmentPlan: 'Edit Installment Plan',
          EditScheduledPayment: 'Edit Scheduled Payment',
          ShareReceipt: 'Share receipt',
          ViewPaidInvoiceDetails: 'View paid invoice details',
          HidePaidInvoiceDetails: 'Hide paid invoice details',
          ViewInstallmentPaidInvoiceDetails:
            'View scheduled installment details',
          HideInstallmentPaidInvoiceDetails:
            'Hide scheduled installment details',
          ViewScheduledPaidInvoiceDetails: 'View scheduled payment details',
          HideScheduledPaidInvoiceDetails: 'Hide scheduled payment details',
          ChangePaymentDate: 'Change Payment Date',
          CancelScheduledPayment: 'Cancel Scheduled Payment',
          CancelInstallmentPayment: 'Cancel Installment Payment',
          ScheduledPaymentDateSelect: 'Scheduled Payment ({{name}})',
        },
        ConfirmAmountDialog: {
          Title: 'Confirm Amount',
          PartialAmountText:
            'A partial payment amount has been entered. Partial payments can only be paid via Pay Now. If you wish to schedule a payment, return to selected invoices and remove partial payment amount.',
          ConfirmAmountReview:
            'Please review the payment amount below and proceed to payment screen.',
          Cancel: 'Cancel',
          Proceed: 'Proceed to Payment',
        },
        ScheduledinvoicesDialog: {
          AutoPay: 'AutoPay',
          DialogText: 'Invoices already being processed',
          SubText1:
            'The following invoices that you have selected for this transaction are already being processed as part of another installment plan or scheduled payment.',
          SubText2:
            'To continue setting up a payment plan, return to the previous screen and deselect the above invoices or change payment type to Pay Now.',
          ScheduledInvoiceslineitems:
            'Invoice Number: {{invoicenumber}} - {{paymenttype}} Payment {{Date}}',
          Close: 'Close',
          DeleteCardDialogText: 'Delete Card Scheduled Payments',
          DeleteCardSubText1:
            'The payment method that you are attempting to delete is currently being used for the following planned payments:',
          DeleteCardSubText2:
            'Add alternate payment method or cancel planned payment(s) before deleting this payment method.',
          DeleteCardClose: 'Cancel',
          DeleteCardScheduledInvoiceslineitems:
            '{{paymenttype}} Payment - next payment date ({{Date}})',
          ConfirmDelete: 'Confirm Delete',
          SelectReplacement: 'Select replacement payment method',
          SelectReplacementLabel: 'Select replacement payment method',
        },
        CancelScheduledInvoicesDialog: {
          DialogText: 'Cancel installment plan',
          DialogTextschedl: 'Cancel scheduled payment',
          SubText1:
            'You are about to cancel Installment Plan ({{date}}). Any outstanding balances for the following invoices will remain unpaid in your account.',
          SubTextSchedule:
            'You are about to cancel Scheduled Payment ({{date}}). Any outstanding balances for the following invoices will remain unpaid in your account.',
          ScheduledInvoiceslineitems:
            '{{invoicenumber}} - {{clientName}} - {{openAmount}}',
          ScheduledInvoicesitemsMobile: '{{invoicenumber}} - {{openAmount}}',
          Cancel: 'Cancel',
          Confirm: 'Confirm Change',
        },
        UnrelatedInvoicesDialog: {
          Title: 'Invoices selected are not related',
          Subtitle: 'Do you wish to proceed to payment?',
          Cancel: 'Cancel',
          Proceed: 'Proceed',
          DontAskAgain: "Don't ask again",
          TitleMutliclient: 'Multi-account payment notice',
          NoteMessage:
            '<b>Note:</b> Details about this transaction, including invoice numbers, amounts and client IDs, will display on payment receipt for all recipients. If account-specific receipt is desired, select “cancel” and process payments individually.',
          NoteMessageMutli:
            'You have selected to pay invoices from more than one account. Details about this transaction, including invoice numbers, amounts and client IDs, will display on payment receipt for all recipients. If account-specific receipt is desired, select “cancel” and process payments individually.',
        },
        InvoiceExport: {
          ExportInvoiceHistory: 'Export Invoice History',
          ExportFilteredResults: 'Export filtered results',
          ExportEntireRecord: 'Export entire record',
          Cancel: 'Cancel',
          exportToCSV: 'Export to .csv',
          Export: 'Export',
          ExportLimitWarning:
            'System exports a maximum of ({{exportLimit}}) records. To refine results, select “Cancel” and filter using the side pane and search bar.',
          ExportErrorDownload:
            'Error: Failed to download. Please try again later.',
        },
        PaymentHistoryExport: {
          ExportPaymentHistory: 'Export Payment History Page',
          ExportFilteredResults: 'Export filtered results',
          ExportEntireRecord: 'Export entire record',
          Cancel: 'Cancel',
          exportToCSV: 'Export to .csv',
          Export: 'Export',
          ExportLimitWarning:
            'System exports a maximum of ({{exportLimit}}) records. To refine results, select “Cancel” and filter using the side pane and search bar.',
          ExportErrorDownload:
            'Error: Failed to download. Please try again later.',
        },
        UploadImage: 'Upload image',
        SelectedOfTotalInvoicesSelected:
          '{{selected}} of {{total}} invoices selected',
        ViewClient: 'View Client',
        whenWillYouPay: 'When will you be paying',
        ScheduledPlanNote:
          '<i>Invoices you have selected are already on a scheduled plan. Proceed with <b>Pay Now</b> or return to the previous screen to change selections</i>',
      },
      SessionManager: {
        Exp10Min: '10 minutes until session expires',
        Exp5Min: '5 minutes until session expires',
        Exp1Min: '1 minute until session expires',
        Exp30Sec: '30 seconds until session expires',
        Exp10Sec: '10 seconds until session expires',
        Exp5Sec: '5 seconds until session expires',
        Exp1Sec: 'Session expired',
      },
      WelcomeMessageModal: {
        WelcomeTitle: 'Hi {{firstName}}, welcome to myRSM!',
        WelcomeDesc:
          'Welcome to myRSM!  This online platform is a convenient, efficient and secure location for you to seamlessly access key applications provided by RSM, collaborate with RSM employees and also receive timely thought leadership based on your interests.',
        WelcomeQuestion: 'How would you like to begin your myRSM experience?',
        CustomizeMyExperienceTitle: 'CUSTOMIZE MY EXPERIENCE',
        CustomizeMyExperienceDesc:
          'Take a quick 4 step wizard to select which Industries, Service Lines and Topics you want to see more of.',
        CustomizeMyExperienceLink: 'Set up your interests',
        NoThanksTitle: 'No, thanks',
        NoThanksDesc: 'I just want to dive in and start using myRSM.',
        NoThanksLink: 'Start using myRSM',
      },
      Footer: {
        PrivacyPolicy: 'Privacy policy',
        Disclaimer:
          'RSM US LLP is a limited liability partnership and the U.S. member firm of RSM International, a global network of independent audit, tax and consulting firms. The member firms of RSM International collaborate to provide services to global clients, but are separate and distinct legal entities that cannot obligate each other. Each member firm is responsible only for its own acts and omissions, and not those of any other party. Visit rsmus.com/aboutus for more information regarding RSM US LLP and RSM International.',
        Copyright: '© {{copyrightYear}} RSM US LLP. All rights reserved.',
      },
      ModalDialog: {
        CloseDialog: 'Close dialog',
      },
      RSMUS: 'RSMUS',
      RSMUSClient: 'RSMUS Client',
      ReturnToCP: 'Return to myRSM',
      PaymentMethodLimit: {
        BankAcctOrCredCardLimit:
          'You have reached the maximum of {{cclimit}} saved {{paymentMethodstype}}. To add a new {{paymentMethodstype}}, please remove another from your existing list.',
      },
    },
  },
  [locales.SPANISH]: {
    translation: {
      srOnlyRequired: 'requerido',
      Navigation: {
        AccountSettings: 'Configuraciones de la cuenta',
        AccountManagement: 'Gestión de la cuenta',
        CloseMainNavigationMenu: 'Cerrar menú de navegación principal',
        Dashboard: 'Panel',
        DocumentRequests: 'Solicitudes de Documentos',
        Documents: 'Documentos',
        Engagements: 'Compromisos',
        HighContrast: 'Alto contraste',
        Home: 'Casa',
        Insights: 'Perspectivas',
        Invoices: 'Facturas',
        Invoicing: 'Facturación',
        Logout: 'Cerrar sesión',
        Main: 'Principal',
        OpenMainNavigationMenu: 'Abrir menú de navegación principal',
        Payments: 'Historial de pagos',
        PayMyBill: 'Pagar Mi Factura',
        Profile: 'Perfil',
        PleaseSelect: 'Por favor seleccione',
        SkipToMainContent: 'Saltar al contenido principal',
        Support: 'Apoyo',
        Welcome: 'Bienvenido',
        ContactUs: 'Contáctenos',
        Feedback: 'Realimentación',
        FAQ: 'Preguntas frecuentes',
      },
      Pagination: {
        RowsPerPage: 'Filas por página',
      },
      ActivityTemplates: {
        RequestCreatedSubject:
          'Solicitud creada por {{FirstName}} {{LastName}}',
        RequestUpdatedSubject:
          'Solicitar detalles modificados por {{FirstName}} {{LastName}}',
        RequestNameUpdatedBody:
          'El nombre de la solicitud cambió de "{{OldRequestName}}" a "{{NewRequestName}}".',
        RequestFunctionalAreaUpdatedBody:
          'Área funcional cambiada de "{{OldArea}}" a "{{NewArea}}".',
        RequestGroupUpdatedBody:
          'Grupo cambiado de "{{OldGroup}}" a "{{NewGroup}}".',
        RequestSubgroupUpdatedBody:
          'Subgrupo cambiado de "{{OldSubgroup}}" a "{{NewSubgroup}}".',
        RequestDescriptionUpdatedBody:
          'Descripción cambiada de "{{OldDescription}}" a "{{NewDescripion}}".',
        RequestStatusUpdatedSubject:
          'Estado actualizado desde $t({{From}}) a $t({{To}}) por {{FirstName}} {{LastName}}.',
        RequestDueDateUpdatedSubject:
          'Fecha de vencimiento actualizada desde {{From, Datetime}} a {{To, Datetime}} por {{FirstName}} {{LastName}}',
        RequestPrioritySetSubject:
          'Marcado como prioritario por {{FirstName}} {{LastName}}',
        RequestPriorityUnsetSubject:
          'No marcado como prioridad por {{FirstName}} {{LastName}}',
        RequestLockSetSubject:
          'Solicitud bloqueada por {{FirstName}} {{LastName}}',
        RequestLockUnsetSubject:
          'Solicitud desbloqueada por {{FirstName}} {{LastName}}',
        RequestDeletedSubject:
          'Solicitud eliminada por {{FirstName}} {{LastName}}',
        RequestAssignedSubject:
          'Asignados añadidos por {{FirstName}} {{LastName}}',
        RequestAssignedBody: '{{Val, List}} agregado a la solicitud.',
        RequestUnassignedSubject:
          'Asignados eliminados por {{FirstName}} {{LastName}}',
        RequestUnassignedBody: '{{Val, List}} eliminado de la solicitud.',
        DocumentsAddedSubject:
          '{{DocumentCount}} documento(s) subido por {{FirstName}} {{LastName}}.',
        DocumentsAddedBody: '{{Val, List}} han sido subidos.',
        DocumentsDeletedSubject:
          '{{DocumentCount}} documento(s) borrado por {{FirstName}} {{LastName}}.',
        DocumentsDeletedBody: '{{Val, List}} han sido eliminados.',
        DocumentsDownloadedSubject:
          '{{DocumentCount}} documento(s) descardado por {{FirstName}} {{LastName}}.',
        DocumentsDownloadedBody: '{{Val, List}} han sido descardados.',
        CommentAddedSubject:
          'Comentarios añadidos por {{FirstName}} {{LastName}}',
        FollowupCommentAddedSubject:
          'Solicitar comentarios de seguimiento agregados por {{FirstName}} {{LastName}}',
      },
      Alert: {
        CloseAlert: 'Cerrar alerta',
        PaymentFailed: 'Pago fallido',
        PaymentUnableToProcess:
          'El pago no tuvo éxito. Inténtalo de nuevo o inténtalo con otro método de pago. Si el problema persiste, comuníquese con su banco o proveedor de tarjeta para obtener ayuda.',
        ErrorSummaryAlertmessage:
          'Los siguientes campos obligatorios requieren entrada y deben completarse antes de confirmar el pago.',
        ErrorScheduledPaymentDownlaodmessage: 'No se pudo descargar',
      },
      Authorization: {
        NotAuthorizedForApplication: {
          NotAuthorizedHeader: 'Algo no funciona',
          NotAuthorizedReason: 'No tienes permiso para ver esta página.',
          NotAuthorizedPrimarySuggestion:
            'Por favor verifique dos veces su solicitud.',
          NotAuthorizedSecondarySuggestion:
            'Si cree que se trata de un error, póngase en contacto con el administrador de su cuenta de RSM para obtener acceso.',
        },
      },
      UserProfile: {
        ECPProfileTitle: 'myRSM - Perfil',
        PageContactInfoPrefsHeading: 'Información de contacto y preferencias',
        PageInterestsHeading: 'Intereses',
        PageNoInterestsMessage:
          'Aún no has seleccionado ningún interés. Solo toma unos minutos y nos ayuda a personalizar su experiencia myRSM.',
        PageAddInterestsButton: 'Agregar intereses',
        PageUpdateInterestsButton: 'Actualizar intereses',
        IndustryLabel: 'Industrias',
        ServiceLabel: 'Líneas de servicio',
        TopicLabel: 'Temas',
        ProfileWizard: {
          StepCounter: 'Paso {{currentStep}} de {{totalSteps}} ',
          WelcomeMessageDesktop: '¡Hola {{firstName}}, bienvenido a myRSM!',
          WelcomeMessageTablet: 'Bienvenido a myRSM!',
          WelcomeMessageMobile: '¡Bienvenido, {{firstName}}!',
          CustomizationMessage:
            'Díganos cómo planea usar myRSM y personalizaremos la experiencia para usted. Esta información no se compartirá fuera de myRSM y solo se utilizará para mejorar esta herramienta para usted.',
          CustomizationMessageShort:
            'Díganos cómo planea usar myRSM y personalizaremos la experiencia para usted. Esta información no se compartirá fuera de myRSM.',
          SelectThe: 'Selecciona el',
          Industries: 'Industrias',
          ServiceLines: 'Líneas de servicio',
          Topics: 'Temas',
          ContactInfoPrefs: 'Información de contacto y preferencias',
          YouAreInterestedIn: 'que le interesan.',
          ContactInfoPrefsMessage:
            'Verifique y/o agregue la siguiente información. Todos los campos son opcionales.',
          Back: 'Atrás',
          Next: 'Próxima',
          SaveAndContinueLater: 'Guardar y continuar más tarde',
          SaveAndExit: 'Guardar y Salir',
        },
        ProfilePopover: {
          IncompleteMessage:
            'Notamos que no pudiste terminar de configurar tu perfil.',
          CompleteMessage:
            'Puede ajustar sus selecciones de interés en cualquier momento en la sección de perfil del Portal del cliente.',
          ContinueMessage: 'Ir al perfil para terminar',
          AdjustProfileMessage: 'Ir al perfil',
          FinishProfile: 'Perfil de acabado',
          CloseDialog: 'Cerrar diálogo',
        },
        ContactPreferenceForm: {
          FirstName: 'Nombre de pila',
          Name: 'Nombre',
          LastName: 'Apellido',
          ClientOrBusinessName: 'nombres de clientes o empresa(s)',
          RegionOrTimeZone: 'Región/Zona horaria',
          BusinessPhoneNumber: 'Número de teléfono laboral',
          BusinessEmailAddress: 'Dirección de correo electrónico comercial',
          PreferredContactMethod: 'Método de Contacto Preferido',
          Email: 'Correo electrónico',
          Phone: 'Teléfono',
          EngagementActivityNotificationFrequency:
            'Frecuencia de notificación de actividad de interacción',
          InsightsActivityNotificationFrequency:
            'Frecuencia de notificación de actividad de Insights',
          Submit: 'Enviar',
          Edit: 'Editar',
          Save: 'Salvar',
          Cancel: 'Cancelar',
          TimeZones: {
            AlaskaTimeZone: 'Alaska',
            ArizonaTimeZone: 'Arizona',
            CentralTimeZone: 'Hora central (EE. UU. y Canadá)',
            PacificTimeZone: 'Hora del Pacífico (EE. UU. y Canadá)',
            MountainTimeZone: 'Hora de la montaña (EE. UU. y Canadá)',
            HawaiiTimeZone: 'Hawai',
            EasternTimeZone: 'Hora del Este (EE. UU. y Canadá)',
          },
          NotificationFrequencies: {
            WeeklyTextAlert: 'Alerta de texto semanal',
            DailyTextAlert: 'Alerta de texto diaria',
            NewItemTextAlert:
              'Alerta de texto tan pronto como se agregue un nuevo elemento',
            NoTextAlert:
              'Sin alertas de texto, solo en las notificaciones de la aplicación',
            WeeklyEmailDigest: 'Resumen de correo electrónico semanal',
            DailyEmailDigest: 'Resumen diario de correo electrónico',
            NewItemEmailDigest:
              'Correo electrónico tan pronto como se agregue un nuevo elemento',
            NoEmailDigest:
              'Sin correos electrónicos, solo en las notificaciones de la aplicación',
          },
          ContactMethods: {
            EmailContactMethod: 'Correo electrónico',
            PhoneContactMethod: 'Teléfono',
          },
        },
      },
      Download: 'Descargar',
      DownloadAll: 'Descargar Todos',
      Delete: 'Eliminar',
      DocumentRequestListHeader: 'LISTA DE SOLICITUDES DE DOCUMENTOS',
      DocumentRequestButtonCreateRequest: 'Crear nueva solicitud',
      DocumentRequestSearchLabel: 'Buscar por nombre',
      DocumentRequestCreateRequestModalTitle:
        'Crear nueva solicitud de documento',
      DocumentRequestCreateRequestModalSubtitle:
        'Porfavor llena los campos de abajo para crear una solicitud de documento',
      DocumentRequestCsvUploadErrorModalTitle: 'Reporte de Errores',
      DocumentRequestCsvUploadErrorModalSubtitle:
        'Ha fallado la creación de la nueva solicitud de documento.',
      Docs: {
        ECPDocsTitle: 'myRSM - Documentos',
        DocumentListHeader: 'ALL DOCUMENTOS',
        DocumentSearchLabel: 'Buscar por título:',
        ConfirmDeleteTitle_one:
          'Se eliminarán {{count}} elementos seleccionados.',
        ConfirmDeleteTitle_other:
          'Se eliminarán {{count}} elementos seleccionados.',
        ConfirmDeleteDescription:
          '¿Está seguro de que desea eliminar el documento(s) seleccionado(s)?',
        AlertDeletedTitle: 'Eliminar exitoso.',
        AlertDeletedDescription: 'Los documentos seleccionados se eliminan.',
        Headers: {
          documentName: 'nombre del documento',
          createdBy: 'Subido por',
          createdDate: 'Fecha',
          group: 'Grupo',
          subgroup: 'Subgrupo',
          status: 'Estado',
          contentType: 'Tipo de contenido',
        },
      },
      EngagementList: {
        NoSearchResultsTitle: 'No se han encontrado resultados',
        NoSearchResultsSubTitle:
          'Intente ampliar su(s) término(s) de búsqueda.',
        ReportCard: {
          Name: 'Reportar un problema',
          Category:
            '¿Tiene problemas como proyectos perdidos o enlaces rotos? Informe su problema aquí.',
        },
        ReportAnIssueForm: {
          title: 'Informar un formulario de problema',
          description:
            'Describa el problema que está experimentando enviando el siguiente formulario y un representante de RSM en EE. UU. se comunicará con usted. *Campo requerido.',
          descriptionLabel: 'Descripción',
          issue: 'Asunto',
          projectName: 'Nombre del proyect',
          issueOptions: {
            notAccurate: 'Mi lista de proyectos no es precisa',
            notLoading: 'El proyecto no se carga cuando hago clic en el enlace',
            incorrect: 'Algo está mal con un proyecto',
            other: 'Otro',
          },
          issueWarning:
            '*La lista de proyectos puede tardar hasta 24 horas en actualizarse.',
          toast: {
            title: 'Ticket de soporte enviado',
            description:
              'Se ha recibido su solicitud de mesa de servicio. Espere una respuesta por correo electrónico dentro de las 48 horas.',
          },
        },
        GoToTop: 'Ve arriba',
      },
      EngagementDashboard: {
        RequestSummaryHeader: 'RESUMEN DE SOLICITUD',
        ViewAllEngagements: 'Ver todos los compromisos',
        RequestTaskCompletionHeader: 'DESGLOSE DEL ESTADO',
        DueDateCalendarHeader: 'CALENDARIO DE FECHA DE VENCIMIENTO',
        TeamMembersHeader: 'MIEMBROS DEL EQUIPO',
        EmptyStatusBreakdownMainHeader: '¡Estáis todos atrapados!',
        EmptyStatusBreakdownSubHeader:
          'Ninguna solicitud necesita atención en este momento.',
        Summary: {
          AssignedToYou: 'Asignado a usted',
          CreatedByYou: 'Creado por usted',
          AllRequests: 'Todas las solicitudes',
          OutOf: 'de',
          ViewAllRequests: 'Ver todas las solicitudes',
          OverdueRequestsPhrase: 'las solicitudes están actualmente vencidas',
          OutstandingRequestsPhrase:
            'las solicitudes prioritarias están pendientes',
          FollowUpRequestsPhrase: 'las solicitudes requieren seguimiento',
          StatusBreakDown: {
            TotalRequests:
              'Este compromiso cuenta actualmente con {{count}} solicitudes',
            CompletedLabel: 'Completado',
            FollowUpLabel: 'Seguimiento',
            SubmittedLabel: 'Presentado',
            InProgressLabel: 'En curso',
            RequestedLabel: 'Solicitado',
          },
        },
        TeamMembers: {
          RSMTeam: 'Equipo del RSM',
          ClientTeam: 'Equipo del cliente',
        },
      },
      ECPAllEngagementsTitle: 'myRSM - Todos los compromisos',
      ECPEngagementsTitle: 'myRSM - Compromisos',
      EngagementListHeader: 'TODOS LOS COMPROMISOS',
      EngagementMembersHeader: 'MIEMBROS',
      ErrorBoundary: {
        DefaultErrorMessage: 'Lo sentimos, ha habido un error',
      },
      FilterChips: {
        ClearAll: 'Limpiar todo',
        ClearAllAppliedFilters: 'Borrar todos los filtros aplicados,',
        RemoveFilter: 'Quitar',
        ShowLess: 'Muestra menos',
        ShowLessAppliedFilters: 'Muestra menos filtros aplicados',
        ShowMoreAppliedFilters: 'Mostrar {{count}} filtros aplicados más',
      },
      Form: {
        RequiredField: 'El campo es requerido.',
        Submit: 'Enviar',
        Maximum:
          'El limite maximo para este campo es- {{MAX_VALUE}} caracteres',
        Minimum:
          'El limite minimo para este canmpo es- {{MIN_VALUE}} caracteres',
        Characters: 'caracteres',
        MaximumReached: 'Has alcanzado el límite de caracteres',
      },
      HomeEngagementsHeader: 'TUS CONTRATOS',
      HomeToolsHeader: 'HERRAMIENTAS',
      HomeRequestsHeader: 'PRÓXIMAS SOLICITUDES',
      HomeFavoritedHeader: 'FAVORITOS',
      HomeInsightsHeader: 'ÚLTIMAS SUGERENCIAS',
      HomePage: {
        ECPHomeTitle: 'myRSM - Inicio',
        EngagementsHeader: 'COMPROMISOS',
        Header: 'Página de inicio de myRSM',
        ArticleFeaturedContent: 'Contenido destacado: Artículo',
        InsightsHeader: 'AÑADIDO RECIENTEMENTE',
        ToolsHeader: 'HERRAMIENTAS',
        ToolsLoadMore: 'Carga más',
        Launch: 'Lanzamiento',
        ViewProjects: 'Ver proyectos',
        SubmitSupportTicket: 'Enviar un ticket de soporte',
        AreYouMissingTools: '¿TE FALTAN HERRAMIENTAS?',
        RequestMissingTools:
          'Solicite herramientas adicionales completando el formulario de soporte de myRSM.',
        AreYouMissingToolsCategory: 'EJEMPLO: DASH, TEAMDOCS, ETC.',
        ArticleReadMore: 'Leer más',
        ArticleReadNow: 'Lee ahora',
        ViewAllEngagements: 'Ver todos los compromisos',
        ViewAllInsights: 'Ver todos los conocimientos',
        TeamDocsCategory: 'Consultante',
        TeamDocsDescription:
          'Colabore con su equipo de proyecto usando esta herramienta de participación',
        NotFindingWhatYouNeed: '¿No encuentras lo que necesitas?',
        VisitAppManager:
          'Visite el <0>{{appManager}}</0> heredado para obtener una lista completa de aplicaciones y administración de contraseñas. Si aún tienes problemas, envía un ticket de soporte <1>{{here}}</1>.',
        Here: 'aquí',
      },
      Projects: {
        Title: 'Localidades de Projectos',
        LoadMore: 'Descargar más',
        GoToTop: 'Volver al inicio',
        HeaderMessage:
          'Proyectos perdidos? Revise su correo electrónico para ver si hay una invitación al sitio del proyecto en cuestión. Si no tiene ninguna invitación, comuníquese con su equipo de proyecto.',
        SupportCard: {
          Name: 'Soporte',
          Category: 'Documentación de Soporte',
          SecuritySummaryLabel: 'Resument de seguridad para TeamDocs',
          SecuritySummaryUrl:
            'https://rsmnet.sharepoint.com/:b:/r/teams/cd/Shared%20Documents/External%20-%20Published/RSM%20TeamDocs%20Security%20Summary.pdf?csf=1&web=1&e=e5o4Qr',
          LoggingInWith365Label: 'Ingresar a TeamDocs con cuenta O365',
          LoggingInWith365Url:
            'https://rsmnet.sharepoint.com/:b:/r/teams/cd/Shared%20Documents/External%20-%20Published/External%20User%20Login%20with%20O365%20Account%20into%20TeamDocs.pdf?csf=1&web=1&e=rZKcqQ',
          LoggingInWithout365Label: 'Ingresar a TeamDocs sin cuenta O365',
          LoggingInWithout365Url:
            'https://rsmnet.sharepoint.com/:b:/r/teams/cd/Shared%20Documents/External%20-%20Published/External%20User%20Login%20without%20an%20O365%20Account%20into%20TeamDocs.pdf?csf=1&web=1&e=qh1PB4',
          ContactSupport: 'Contactar soporte',
        },
        NoSearchResultsTitle: 'No se han encontrado resultados',
        NoSearchResultsSubTitle:
          'Intente ampliar su(s) término(s) de búsqueda.',
      },
      InsightsList: {
        ECPInsightsHeading: 'Últimas Perspectivas de RSM',
        InsightsFilter: 'Filtro',
        InsightsClearAll: 'Borrar todo',
        InsightsChipAriaPrefix: 'Quitar filtro ',
        FilterLabels: {
          favorites: 'Favoritos',
          recentlyViewed: 'Visto recientemente',
          myInterestsLabel: 'mis-intereses',
          sharedWithMe: 'compartido-conmigo',
        },
        FilterHeaders: {
          yourinsights: 'Tus Ideas',
          role: 'El Oficio',
          industry: 'Idustria',
          service: 'Línea de servicio',
          topic: 'Tema',
        },
        ReadMore: 'Leer más',
        LoadMore: 'Cargar más',
        GoTotop: 'Ir al principio',
        NoSearchResults: 'No se han encontrado resultados',
        NoSearchResultsAdvice:
          'Intenta ampliar el término de búsqueda o eliminar filtros para ver los resultados.',
      },
      InsightArticle: {
        AddToFavorites: 'Agregar a Favoritos',
        RemoveFromFavorites: 'Eliminar de favoritos',
        BackToInsights: 'Ver todas las perspectivas',
        InsightArticle: 'Artículo de Información',
        RSMContributors: 'Colaboradores de RSM',
        Tags: 'Etiquetas',
        DefaultTag: ' ',
        ArticleDate: '{{date, medium}}',
        ArticleSharedSuccess: 'Artículo compartido con éxito',
        ArticleSharedFail: 'No se puede compartir el artículo',
        ArticleSharedWarning:
          'No se puede compartir con {{count}} de las destinatarias',
        ArticleSharedSuccessMessageMultiUser:
          'El artículo ha sido enviado por correo electrónico a todos los destinatarios.',
        ArticleSharedSuccessMessage:
          'El artículo fue enviado a {{firstName}} {{lastName}} ({{email}}).',
        ArticleSharedError: 'No se puede compartir',
        ArticleSharedErrorMessage:
          'Hubo un error y no pudimos enviar su mensaje. Vuelva a intentarlo o póngase en contacto con el soporte si el problema continúa.',
        ShareArticleMessage: 'compartió este artículo contigo',
      },
      ShareArticleModal: {
        Title: 'Comparte este artículo de información',
        DefaultMessage: `Hola {{receiverName}},\nEstaba en myRSM y encontré este artículo que pensé que podría interesarle.\n\nEl mejor,\n{{senderName}}`,
        DefaultMessageNonEcpUser: `Hola {{receiverName}},\nEstaba en myRSM y encontré este artículo que pensé que podría interesarle.\nDeberá unirse a myRSM para verlo, ¡pero creo que vale la pena!\n\nBest,\n{{senderName}}`,
        DefaultMessageMultiple: `Hola, \nEstaba en myRSM y encontré este artículo que pensé que podría interesarle.\n\nBest,\n{{senderName}}`,
        IncludeMessageLabel: 'Incluye un mensaje:',
        RequiredField: 'Este es un campo obligatorio.',
        UserSearchInputPlaceholder: 'Buscar por nombre o apellido',
        AriaLabel: {
          ShareArticle: 'Compartir artículo',
          AriaCloseDialog: 'Cerrar cuadro de diálogo',
        },
        NoOptionsText:
          'No se encontraron resultados para ningún usuario con ese nombre.',
        EcpWarning:
          'Este usuario aún no tiene acceso a myRSM. Si continúa, tendrán acceso a  myRSM, que es necesario para ver cualquier artículo compartido.',
        NonEcpUserAckMessage:
          'Sí, dé a estos usuarios acceso a myRSM. Esto es necesario para ver este artículo.',
        NonEcpUserAckRequired:
          'Este es un campo obligatorio, reconozca o elimine a los usuarios que no sean myRSM para continuar.',
        NonEcpUserInfoMessage:
          'Estos destinatarios aún no tienen acceso al Portal del cliente.',
        MaxUserLimit: 'Número máximo de destinatarias alcanzadas',
      },
      NotificationTemplates: {
        ArticleShared:
          '<strong>{{FirstName}} {{LastName}} compartió un artículo <0>{{ArticleName}}</0> con usted</strong>',
        NoMessages: 'No tienes ninguna notificación.',
        NotificationsTitle: 'Notificaciones',
        NotificationsLabel: 'Notificaciones, {{count}} nuevo',
        DeleteNotification: 'Eliminar notificación',
        DateAgo: '{{date, atrás}}',
      },
      ECPInsightsTitle: 'myRSM - Sugerencias',
      InsightsFeaturedTopicsHeader: 'TEMAS DESTACADOS: CATEGORÝAS',
      InsightsIndustryInsightsHeader: 'SUGERENCIAS DE LA INDUSTRIA: ARTÝCULOS',
      InsightsServiceLineRelatedHeader:
        'SUGERENCIAS RELACIONADAS A LA LÝNEA DE SERVICIO: ARTÝCULOS',
      ECPNoMatchTitle: 'myRSM - No Encontrado',
      NoMatchHeader: 'Sin Coincidencias',
      ECPNotificationsTitle: 'myRSM - Notificaciones',
      NotificationsHeader: 'NOTIFICACIONES',
      ActivityFeedTitle: 'Fuente de actividad',
      OutOfTime: 'Fuera de tiempo',
      ECPSupportTitle: 'myRSM - Apoyo',
      RSMSupportHeading: 'myRSM Soporte',
      SupportFormTitle: 'FORMULARIO DE APOYO',
      FirstName: 'Primer nombre',
      LastName: 'Apellido',
      EngagementName: 'Nombre de compromiso',
      ContactMethod: 'Método de Contacto Preferido',
      BusinessEmail: 'Correo electrónico de contacto',
      BusinessPhone: 'Número de teléfono de contacto',
      ClientBusName: 'Cliente/Nombre de la empresa',
      DashIssue: '¿Es esto un problema de DASH?',
      DashFunctionalityIssue: '¿Se trata de un problema de funcionalidad DASH?',
      DashFunctionalityIssueWarning:
        'Los problemas de Dash no se pueden admitir a través de myRSM; envíe un problema a través del portal DASH.',
      DashIssueShortened: 'Problema con DASH',
      DashSupportLink: 'enlace de la página de soporte de dash',
      ProceedToDash: 'Continúe con DASH',
      LOB: 'Línea de servicio',
      Category: 'Categoría',
      Description: 'Descripción',
      SysPortal: 'Sistema/Portal tratando de acceder',
      IssueDesc: 'Asunto',
      IsARequiredField: 'es un campo obligatorio.',
      FeedbackDesciption:
        'La descripción de los comentarios es un campo obligatorio',
      ReadMore: 'Leer Más',
      RequestAccess: {
        ThankYou: 'Gracias por su interés en myRSM.',
        SubmitRequest:
          'Envíe su solicitud para iniciar el acceso a la siguiente cuenta.',
      },
      Reset: 'Reiniciar',
      Submit: 'Enviar',
      SelectEngagement: 'Seleccionar compromiso',
      SessionTimeout: 'Hora de término de la sesión',
      SessionTimeoutExplination: 'Su sesión está a punto de caducar',
      SelectBusiness: 'Seleccionar',
      Other: 'Otro/Otra',
      SupportSuccess: 'Ticket de soporte enviado',
      SupportSuccessMessage:
        'Su solicitud de Service Desk ha sido recibida. Espere una respuesta por correo electrónico dentro de las 48 horas.',
      SupportFormSuccessMessage:
        'Un miembro del soporte se comunicará con usted dentro de 2 días hábiles para ayudarlo con su solicitud. Si necesita asistencia más inmediata, llame al equipo de soporte al {{supportPhone}}.',
      SupportSuccessLink: 'Mensaje de CTA más largo Lorem ipsum dolor sit amet',
      SupportError: 'Alerta',
      SupportErrorMessage:
        'No pudimos procesar esta solicitud debido a un error del sistema. Inténtelo de nuevo más tarde.',
      SupportFormDesc:
        'Describa el problema de myRSM que está experimentando enviando el siguiente formulario y un representante de myRSM se comunicará con usted. *Campo requerido.',
      SupportPageFormDesc:
        'Utilice el formulario para enviar problemas relacionados con su experiencia myRSM. Si el problema se refiere a una aplicación a la que se accede a través de myRSM, su consulta se enviará al equipo correspondiente. Para temas relacionados con sus servicios, comuníquese con su administrador de cuentas.',
      CustomerService: 'Servicio al cliente',
      SupportPageFormFooterDesc:
        'Un representante del equipo de soporte se comunicará con usted directamente dentro de 2 días hábiles.',
      SelectCategory: 'Seleccionar Categoría',
      SelectIssue: 'Seleccionar problema',
      SupportFormDashDropdown: {
        Yes: 'Sí',
        No: 'No',
        ClearSelection: 'Borrar selección',
      },
      UpdateSuccess: 'Actualizado correctamente',
      UpdateSuccessMessage: 'Sus preferencias de perfil han sido actualizadas.',
      UpdateError: 'ALERTA',
      UpdateErrorMessage:
        'No pudimos procesar esta solicitud debido a un error del sistema.',
      RequiredField: 'Este campo es requerido.',
      ValidCurrency: 'Por favor ingrese un valor válido',
      ValidDate: 'Por favor ingrese una fecha válida',
      ValidDateInRange:
        'Ingrese una fecha entre el {{minDate, P}} y el {{maxDate, P}}',
      ValidInput: 'Entrada inválida',
      Filters: {
        Clear: 'Limpiar',
        CollapsedToggle: {
          ShowMore: 'Ver más',
          ShowLess: 'Ver menos',
        },
      },
      Engagement: {
        Name: 'NOMBRE DEL COMPROMISO',
        DateCreated: 'FECHA DE CREACION',
        EndDate: 'FECHA FINAL',
        Client: 'CLIENTE',
        Entity: 'ENTIDAD',
        Type: 'TIPO',
        ViewEngagement: 'Ver interacción',
        ViewEngagementAriaLabel: 'Ver {{engagement}} compromiso',
      },
      DocReq: {
        NewForm: {
          Success: '¡Éxito!',
          RequestName: 'Solicitar nombre',
          DocReqInformation: 'Información de solicitud de documentos',
          DueDate: 'Fecha de vencimiento',
          FunctionalArea: 'Área funcional',
          Group: 'Grupo',
          GroupPlaceholder:
            'Escriba el nombre para buscar o ingrese como nuevo grupo',
          Subgroup: 'Subgrupo',
          SubgroupPlaceholder:
            'Escriba el nombre para buscar o ingrese como nuevo subgrupo',
          RequestSettings: 'Solicitar configuración',
          MarkAsPriority: 'Marcar como prioridad',
          PriorityTooltip:
            'Marcar como prioridad marcará esta solicitud y habilitará las notificaciones por correo electrónico relacionadas.',
          LockRequest: 'Solicitud de bloqueo',
          LockTooltip:
            'Al bloquear esta solicitud, los detalles solo serán visibles para el cesionario y los miembros de RSM en este compromiso.',
          Description: 'Descripción',
          AssignRequest: 'Asignar solicitud',
          SearchForUser: 'Buscar usuario',
          AssigneeNoMatch: 'No se han encontrado resultados',
          MaxAssignees:
            'Ha alcanzado el máximo de {{MaxAssignees}} el apoderados por solicitud',
          MaxAssigneesMobile: 'Máximo de {{MaxAssignees}} los apoderados',
          TypeNameOrEmail: 'Escriba el nombre o el correo electrónico',
          ClientAssignees: 'Cesionarios del cliente',
          RSMAssignees: 'Cesionarios del RSM',
          Submit: 'Enviar',
          AriaLabel: {
            RequestName: 'Solicitar campo de nombre',
            DueDate: 'Campo de fecha de vencimiento',
            FunctionalArea: 'Campo de área funcional',
            Group: 'Campo de grupo',
            Subgroup: 'Campo de subgrupo',
            PriorityIcon: 'Marcar como casilla de prioridad',
            LockIcon: 'Casilla de solicitud de bloqueo',
            SearchForUser: 'Campo de búsqueda de cesionario',
            EnterNameOrEmail: 'Ingrese nombre o correo electrónico',
            ClientAssignees: 'Campo de asignatarios de clientes',
            RSMAssignees: 'Campo de asignados de RSM',
            FLAdded: '{{Name}} agregado.',
            FLRemoved: '{{Name}} eliminado.',
            UpDownNavigate:
              'Utilice las flechas hacia arriba o hacia abajo para navegar.',
            BackspaceToRemove:
              'Para eliminar al asignado, presione el botón eliminar o retroceder',
            Description: 'Campo de descripción',
            SearchEmail: 'Buscar campo de correo electrónico',
            Submit: 'Enviar',
          },
          SuccessModal: {
            Title: '¡Éxito!',
            Subtitle: 'Su solicitud de documento ha sido creada con éxito.',
            RequestName: 'Solicitar nombre',
            DueDate: 'Fecha de vencimiento',
            FunctionalArea: 'Área funcional',
            Group: 'Grupo',
            Subgroup: 'Subgrupo',
            PriorityStatus: 'Estado de prioridad',
            PriorityStatusIsPriority: 'Marcado como prioridad',
            PriorityStatusIsNotPriority: 'No marcado como prioridad',
            LockStatus: 'Estado de bloqueo',
            LockStatusLocked: 'Bloqueado',
            LockStatusUnlocked: 'Desbloqueado',
            Description: 'Descripción',
            ClientAssignees: 'Cesionarios del cliente',
            RSMAssignees: 'Cesionarios del RSM',
            Close: 'Cerca',
          },
        },
        RequestFollowUpModal: {
          Title: 'Solicitar seguimiento',
          SubTitle: '¿Qué información necesita del cliente?',
          AddComment: 'Agregar comentario',
          MaximumReached:
            'El comentario debe estar entre {{MIN_LENGTH}} y {{MAX_LENGTH}} Caracteres largas',
          AriaLabel: {
            CommentAdded: 'Comentario añadido',
            CharsEntered:
              '{{charCount}} de {{MAX_LENGTH}} Caracteres ingresadas',
            CloseDialog: 'Cerrar diálogo',
            name: 'solicitud de seguimiento',
          },
        },
        RequestAddComment: {
          AriaCloseDialog: 'Cerrar cuadro de diálogo',
          AriaCommentAdded: 'Comentario añadido',
          Title: 'Agregar comentario',
          PlaceholderNote: 'Añadir un comentario',
          MaximumReached:
            'El comentario debe tener entre {{MIN_LENGTH}} y {{MAX_LENGTH}} caracteres.',
          AriaCharactersEntered:
            '{{charCount}} de {{MAX_LENGTH}} caracteres ingresados',
        },
        ViewDetails: {
          MarkedAsPriority: 'Marcado como prioridad',
          NoPriority: 'sin prioridad',
          Priority: 'Prioridad',
          FunctionalArea: 'Área funcional',
          DueDate: 'Fecha de vencimiento',
          Group: 'Grupo',
          Subgroup: 'Subgrupo',
          Lock: 'Cerrar',
          Locked: 'Bloqueada',
          Unlocked: 'Desbloqueada',
          CurrentStatus: 'Estado actual',
          CurrentStatusCountMsg: 'Actualizada {{countDays}} hace días',
          CurrentStatusDateMsg: 'Actualizado en',
          DueDateMsg: 'Debido en {{countDays}} Días',
          DueToday: 'Para hoy',
          UpdatedToday: 'Actualizado hoy',
          CurrentClientAssignees: 'Asignatarios de clientes actuales',
          CurrentRSMAssignees: 'Asignadas actuales de RSM',
          DocumentRequestStatus: {
            Requested: 'Solicitada',
            InProgress: 'En progreso',
            Submitted: 'Presentada',
            FollowUp: 'Hacer un seguimiento',
            Complete: 'Completo',
          },
        },
        CurrentAssignees: 'Current assignees',
        Summary:
          'Esta es una lista de los documentos que ha solicitado. Actualmente {{uploaded}} fuera de {{total}} los documentos solicitados han sido subidos. {{inProgress}} las solicitudes están en curso y  {{followUp}} requieren seguimiento.&nbsp;',
        SummaryOverdue: '{{overdue}} la solicitud está vencida actualmente.',
        ActionButtons: {
          Upload: 'Subir documentos',
          Submit: 'Enviar peticion',
          FollowUp: 'Solicitar seguimiento',
          Complete: 'Marcar como completo',
        },
        Documents: {
          SectionTitle: 'Documento(s) subido(s)',
          DownloadAll: 'Descargar todo',
          Download: 'Descargar ',
          Delete: 'Borrar',
          NoDocs: 'No hay nada aquí todavía',
        },
      },
      SearchUserByEmail: 'Buscar usuario por correo electrónico',
      DocumentRequest: 'Solicitud de documento',
      Assignee: 'Cesionario',
      DueDate: 'Fecha de vencimiento',
      Status: 'Estado',
      Activity: 'Actividad',
      SearchByDocName: 'Buscar por nombre de documento',
      SearchByKeyword: 'Buscar por palabra clave',
      SearchProcessedByKeyword: 'Búsqueda procesada por',
      SearchRegion: 'Región de búsqueda',
      SearchByPayor: 'Empieza a escribir para buscar',
      SearchScreenReaderInstructions:
        'Los resultados se filtrarán a continuación a medida que escriba',
      ScreenReaderRemoveFilterMessage:
        'Para eliminar filtros, pulse el botón Eliminar o retroceso',
      Results: 'resultados',
      CreateNewDocReq: 'Crear una nueva solicitud',
      SubmitDocReq: 'Enviar peticion',
      MarkComplete: 'Marcar como completo',
      FollowUp: 'Solicitar seguimiento',
      UploadDoc: 'Subir documento',
      AddComment: 'Agregar comentario',
      CsvErrorModal: {
        ErrorReport: 'Reporte de error',
        ExplanationMessage:
          'No se pudo crear una nueva solicitud de documento.',
      },
      NewDocReqModal: {
        Title: 'Crear una nueva solicitud de documento',
        SubTitle:
          'Complete los campos a continuación para crear una solicitud de documento',
      },
      Faq: {
        Title: 'FAQs',
        Description:
          'Muchas preguntas pueden responderse rápidamente consultando nuestras preguntas frecuentes.',
        ButtonText: 'Leer preguntas frecuentes',
        FormTitle: 'Preguntas frecuentes',
        FormDesc:
          '¿Cómo podemos ayudarte? Esta página proporciona ayuda con las preguntas más comunes; encuéntrelas a continuación.',
        FaqErrorTitle: 'Oh oh...',
        FaqErrorDesc:
          'Parece que el sistema no pudo cargar las preguntas frecuentes. Intente actualizar la página o volver más tarde para ver si este problema se resolvió.',
        Refresh: 'Refrescar',
      },
      FaqPage: {
        Title: 'FAQs',
        Description:
          '¿Tienes preguntas? Aquí encontrarás respuestas a preguntas comunes. Si no encuentras lo que necesitas, envía un ticket de soporte y alguien se pondrá en contacto contigo.',
        FaqErrorTitle: 'Oh oh...',
        FaqErrorDesc:
          'Parece que el sistema no pudo cargar las preguntas frecuentes. Intente actualizar la página o volver más tarde para ver si se ha resuelto el problema.',
        Refresh: 'Refrescar',
        Tools: 'Herramientas',
        Insights: 'Perspectivas',
        TeamDocs: 'TeamDocs',
        UserProfile: 'Perfil de usuario',
        SupportPage: 'Apoyo',
      },
      FeedbackPage: {
        Title: 'Retroalimentación',
        Description:
          'Utilice el formulario para enviar comentarios relacionados con su experiencia con myRSM.',
        FeedbackDetailsLabel:
          '<b>¿Cómo podemos mejorar la experiencia de myRSM? (O si te encantó, ¡también lo tomaremos!)*</b>',
        SubmitButton: 'Enviar',
        contactPreferenceHelperText:
          '<b>Si necesitamos aclaraciones sobre alguna de sus respuestas, ¿podemos ponernos en contacto con usted?*</b>',
        ContactPreferenceEmail: 'Sí, prefiero un correo electrónico',
        ContactPreferencePhone: 'Sí, prefiero una llamada telefónica',
        ContactPreferenceNone: 'No, por favor, no se ponga en contacto conmigo',
        FeedbackErrorHeader: 'ALERTA',
        FeedbackErrorMessage:
          'No pudimos enviar estos comentarios debido a un error del sistema. Inténtelo de nuevo más tarde.',
        FeedbackSuccessHeader: '¡Comentarios enviados con éxito!',
        FeedbackSuccessMessage:
          'Agradecemos sus comentarios. Estamos comprometidos con la mejora continua y sus opiniones nos ayudan a lograrlo.',
      },
      Dialogs: {
        OkText: 'OK',
        ConfirmText: 'Confirmar',
        CancelText: 'Cancelar',
        SaveText: 'Ahorrar',
      },
      CallUs: {
        Title: 'Llámanos',
        TollFree: 'Gratuito:',
        Description:
          'El horario de atención al cliente de Cuentas por Cobrar es: de lunes a viernes, de 9 a.m. a 4 p.m. CT.',
        InternalDescription:
          'El horario de atención al cliente es: domingo de 9 p. m. a viernes de 9 p. m. CT, sábados y domingos de 8 a. m. a 5 p. m. CT',
        ExternalDescription:
          'El horario de atención al cliente es: domingo 9 p. m. - viernes 9 p. m. CT',
        USandCANADA: 'Estados Unidos y Canadá',
      },
      RobotPage: {
        TechnicalDifficulties: 'Experimentar dificultades técnicas',
        SomeSectionsUnloadable:
          'Algunas secciones de esta página no pudieron cargarse.',
        TryAgainLaterMessage: 'Actualiza o vuelve a intentarlo más tarde.',
      },
      PageUnavailableHeader: 'Página no disponible',
      TechnicalIssuesMessage: 'Estamos experimentando problemas técnicos. ',
      PleaseTryAgainMessage: 'Por favor, inténtelo de nuevo más tarde.',
      TryAgainButtonLabel: 'Intentar otra vez',
      NoToolsMessage: 'No tiene ninguna herramienta RSM en este momento.',
      IfYouAreMissingTools: 'Si te faltan herramientas, ',
      SubmitSupportTicket: 'enviar un ticket de soporte.',
      SearchLayout: {
        Apply: 'Aplicar',
        ClearAll: 'Limpiar todo',
        Filters: 'Filtros',
        ScrollToTop: 'Vuelve al comienzo',
      },
      Invoicing: {
        Actions: 'Comportamiento',
        Accept: 'Aceptar',
        ActivityFeed: {
          InvoicePastDue: 'Factura vencida',
          InvoicePaymentProcessed: 'Pago de factura procesado',
          PaymentScheduled: 'Pago programado',
          NewInvoice: 'Nueva factura',
          InvoicePayment: 'Pago de la factura',
          DaysOverdue: 'dias atrasados',
          ScheduledPaymentFor: 'programó un pago para',
          PaymentMade: 'pago realizado',
          At: 'EN',
        },
        AccountInformationCard: {
          Welcome: 'Bienvenido!',
          ClientNumber: 'Número de cliente:',
          PrimaryAccountAddress: 'Dirección de la cuenta principal:',
          BusinessPhone: 'Teléfono de negocios:',
          ClientAutocompleteInternalPlaceholder:
            'Ingrese el número de cliente o el nombre para localizar la cuenta',
          ClientAutocompleteExternalPlaceholder: 'Todas las cuentas',
          NoResults: 'No se encontraron resultados',
          NoClientNote:
            '<b>Nota:</b> Si tiene problemas para localizar una cuenta por número de factura, número de cliente o nombre de cliente, es posible que no haya un registro en este sistema. Confirme que existe un registro en Workday.',
          PayNowNote:
            '<b>Nota:</b> El monto anterior es el monto total pendiente para todas las cuentas a las que está asociado. Seleccionar Pagar ahora iniciará el pago en todas las cuentas. Para pagar una cuenta específica, seleccione de la lista a la izquierda.',
        },
        AccountSupport: {
          PhoneTitle: 'Llámanos',
          PhoneDescription:
            'El horario de atención al cliente de Cuentas por cobrar es de lunes a viernes, de 9 a. m. a 4 p. m. CT.',
          PhoneNumberPrefix: 'Gratuito: ',
          PhoneNumber: '1.833.597.2118',
          PhoneNumberSuffix: 'Estados Unidos y Canadá',
          EmailTitle: 'Envíanos un correo electrónico',
          EmailDescription:
            'Los correos electrónicos se responden en el orden en que se reciben.',
          EmailAddresses:
            '<0>Correo electrónico</0>: <1>accounts_receivable@rsmus.com</1> o\n<2>accounts_receivable@rsmcanada.com</2>',
        },
        AccountManagementHelpfulLinks: {
          AccountCentral: {
            LinkText: 'Central de cuentas',
            LinkDescription: 'Agregar usuarios adicionales a este portal',
          },
          CEM: {
            LinkText: 'CEM',
            LinkDescription: 'Actualizar funciones de myRSM',
          },
          PayTrace: {
            LinkText: 'Paytrace',
            LinkDescription:
              'Acceda al procesamiento de tarjetas de crédito de puerta trasera',
          },
          Workday: {
            LinkText: 'Workday',
            LinkDescription: 'Acceder a la información del cliente',
          },
        },
        AccountReferenceDocuments: {
          FaqTitle: 'FAQs',
          FaqDescription:
            'Muchas preguntas se pueden responder rápidamente consultando nuestras preguntas frecuentes.',
          FaqButtonText: 'Leer FAQs',
          AddlDocsTitle: 'Documentación adicional',
          FirstTermsAndConditions: 'Consent to Electronic Communications.pdf',
          OneTimePayment: 'One-Time Payment-US and Canada.pdf',
          RecurringPayment: 'Auto and Recurring payments US.pdf',
          PADAgreementCanada:
            'Business Pre-Authorized Debit (PAD) Agreement-Canada.pdf',
        },
        AutoPayPage: {
          AutoPayLimit: 'Establecer límite de pago automático',
          AutoPayLimitAmount: 'Monto límite',
          AutoPayLimitAmountNoDescription:
            'No se ha establecido un límite de pago automático. En el momento de cada transacción de Pago automático, se procesará todo el saldo abierto.',
          AutoPayLimitAmountYesDescription:
            'Usted elige establecer un límite para cada transacción de Pago automático. Después de cada proceso de pago, cualquier saldo pendiente que supere el límite que especifique a continuación permanecerá en la cuenta.',
          AutoPayLimitNo: 'No',
          AutoPayLimitYes: 'Sí',
          AutoPayCancelled: 'Pago automático cancelado con éxito',
          BankAccountRequired:
            'Elegir una cuenta bancaria existente es un campo obligatorio.',
          BiWeekly: 'Quincenal',
          CancelAutoPay: 'Cancelar Pago Automático',
          CancelAutoPayMessage:
            'Está a punto de cancelar su plan de pago automático {{frequency}}. Su saldo pendiente ya no se pagará automáticamente.',
          CancelAutoPayConfirm: 'Confirmar cancelar Autopay',
          CreditCardRequired:
            'Elegir una tarjeta de crédito existente es un campo obligatorio.',
          Daily: 'A diario',
          EditCancelAutoPay: 'Editar/Cancelar pago automático',
          Frequency: 'Frecuencia',
          FrequencyRequired: 'La frecuencia es un campo obligatorio.',
          HeaderDescriptionEdit:
            'Puede aprovechar su plan de pago automático a continuación. Para cancelar el pago automático por completo, seleccione "Cancelar pago automático"',
          HeaderDescriptionDashboard:
            'Elija su frecuencia de pago, monto y fecha de inicio a continuación. Cancele en cualquier momento a través del Panel de control. Los recibos estarán disponibles en la pestaña Historial de pagos una vez procesados.',
          HeaderDescriptionAccountManagement:
            'Elija su frecuencia de pago, monto y fecha de inicio a continuación. Cancele en cualquier momento a través de Gestión de cuenta. Los recibos estarán disponibles en la pestaña Historial de pagos una vez procesados.',
          InvalidCharacters: 'Caracteres no válidos.',
          LimitAmountRequired: 'El importe límite es un campo obligatorio.',
          LimitAmountMinimumRequired:
            // eslint-disable-next-line no-template-curly-in-string
            'El límite mínimo de pago automático es ${{minAmount}}; ingrese un valor mayor o igual a ${{minAmount}}.',
          LimitAmountMaximumRequired:
            // eslint-disable-next-line no-template-curly-in-string
            'Ingrese un pago menor o igual a ${{maxAmount}}.',
          Monthly: 'Mensual',
          NextAutopayDate: 'Próxima fecha de pago automático',
          NextProcessingDate:
            '<b>Próxima fecha de procesamiento:</b> {{date, short}}',
          Note: '<b>Nota:</b> Solo se le cobrará mediante pago automático si tiene un saldo abierto en su cuenta. Si no hay saldo, no se procesará ningún pago.',
          PaymentInformationHeader: 'Información de pago',
          PayerRequired: 'Pagador es un campo obligatorio.',
          PaymentStartDate: 'fecha de inicio de pago',
          PaymentStartDateDailyMessage:
            '*Los pagos se iniciarán automáticamente diariamente desde la fecha de inicio.',
          PaymentStartDateWeeklyMessage:
            '*Los pagos se iniciarán automáticamente el mismo día todas las semanas a partir de la fecha de inicio.',
          PaymentStartDateBiWeeklyMessage:
            '*Los pagos se iniciarán automáticamente el mismo día cada dos semanas a partir de la fecha de inicio.',
          PaymentStartDateMonthlyMessage:
            '*Los pagos se iniciarán automáticamente el mismo día todos los meses a partir de la fecha de inicio.',
          PaymentStartDateRequired:
            'La fecha de inicio del pago es un campo obligatorio.',
          ValidAmount: 'Ingrese un monto en dólares válido.',
          Weekly: 'Semanalmente',
        },
        AutoPaySetupSuccessPage: {
          AutoPayEdit: 'Editar éxito del pago automático',
          AutoPayEditDescription:
            'Se ha actualizado su pago automático recurrente. Una vez procesados ​​los pagos, podrá encontrar un registro de la transacción en la pestaña <PayHist>Historial de pagos</PayHist>.',
          AutoPaySetup: 'Configuración de pago automático',
          AutoPaySetupDescription:
            'Se ha programado su pago automático recurrente. Una vez procesados ​​los pagos, podrá encontrar un registro de la transacción en la pestaña <PayHist>Historial de pagos</PayHist>.',
          BackToAccountManagement: 'Volver a Gestión de cuentas',
          BackToDashboard: 'Volver al panel',
          ClientName: 'Nombre del cliente',
          Frequency: 'Frecuencia',
          AutoPayStartDate: 'Fecha de inicio del pago automático',
          AutoPayStartDateFormat: '{{date, PP}}',
          AutoPayLimit: 'Límite de pago automático',
          PaymentMethod: 'Método de pago',
          PayerName: 'Nombre del pagador',
        },
        AutoPayDetailsPage: {
          Frequency: 'Frecuencia',
          AutoPayStartDate: 'Fecha de inicio del pago automático',
          AutoPayStartDateFormat: '{{date, PP}}',
          AutoPayLimit: 'Límite de pago automático',
          PaymentMethod: 'Método de pago',
          PayerName: 'Nombre del pagador',
          ClientName: 'Nombre del cliente',
          Details: 'Detalles actuales de pago automático:',
          NoLimitSet: 'No hay límite establecido',
          BankAccountEnding: 'Cuenta que termina en {{accountNumber}}',
          CreditCardEnding:
            'Tarjeta de crédito que termina en {{accountNumber}}',
        },
        PaymentManagement: {
          PaymentMethodAdded: 'Método de pago agregado',
          PaymentMethodAddedMessage:
            '¡Tu método de pago ha sido agregado exitosamente!',
          PaymentManagementTitle: 'Gestión de pagos',
          LookingForInvoice: '¿Buscas una factura?',
          FindAndDownloadReceipts: 'Buscar y descargar recibos',
          SavedPaymentMethods: 'Métodos de pago guardados',
          SavedPaymentMethodsSub:
            '(Agregue o elimine un método de pago para <0>{{clientName}}</0> a continuación)',
          AchAccounts: 'Cuentas bancarias',
          CreditCards: 'Tarjetas de crédito',
          BankAccountEnding: 'Cuenta que termina en {{accountNumber}}',
          CreditCardEnding: '{{cardType}} que termina en {{cardNumber}}',
          AddNewBankAccount: 'Agregar nueva cuenta bancaria',
          AddNewCreditCard: 'Agregar nueva tarjeta de crédito',
          PaymentOptions:
            'Para analizar opciones de pago adicionales, comuníquese con el equipo de Cuentas por cobrar.',
          EnableCreditCard: 'Permitir',
          Footer:
            'Para obtener ayuda con pagos o facturas, comuníquese con Cuentas por cobrar al <tel>833-597-2118</tel> o envíe un correo electrónico a <email>accounts_received@rsmus.com</email>',
          ConfirmEnableCreditCard: {
            Title: 'Confirmar Habilitar pagos CC',
            EnableCreditCardReview:
              'Está a punto de habilitar los pagos con tarjeta de crédito para <client>{{clientName}} ({{clientId}})</client>.<lb />Una vez habilitado, no podrá deshacer esta acción.<lb /> <lb /> ¿Le gustaría continuar?',
            Cancel: 'Cancelar',
            Proceed: 'Confirmar Habilitar pagos CC',
            CreditCardEnableSuccess:
              'Se han habilitado los pagos con tarjeta de crédito.',
            CreditCardEnableSuccessHeader: 'Éxito',
            CreditCardEnableError:
              'Algo salió mal, no se habilitaron los pagos con tarjeta de crédito.',
          },
          ConfirmDeletePaymentMethod: {
            AutoPay: 'Pago automático',
            Title: 'Confirmar eliminación',
            DeleteBankAccountReview:
              '¿Está seguro de que desea eliminar la cuenta que termina en {{accountNumber}}?',
            DeleteCreditCardReview:
              '¿Está seguro de que desea eliminar {{cardType}} que termina en {{cardNumber}}?',
            Cancel: 'Cancelar',
            Proceed: 'Confirmar eliminación',
            PaymentMethodDeletionError:
              'Algo salió mal, el método de pago no fue eliminado.',
            BankAccountDeletionSuccess:
              'La cuenta que termina en {{accountSuffix}} ha sido eliminada.',
            CreditCardDeletionSuccess:
              'El {{cardType}} que termina en {{cardNumber}} se ha eliminado.',
            PaymentMethodDeleteSuccessHeader: 'Éxito',
            BankAccountEndingIn: 'Cuenta que termina en {{accountNumber}}',
            CreditCardEndingIn: '{{cardType}} que termina en {{cardNumber}}',
            ScheduledPaymentDateSelect: 'Scheduled Payment ({{name}})',
            InstallmentPaymentDateSelect: 'Installment Plan ({{name}})',
            ScheduledPaymentDownloadError:
              'Se produjo un error durante la descarga. Por favor inténtalo de nuevo.',
            ScheduledPaymentChangeDateAlertHeader:
              'Fecha de pago programada actualizada',
            ScheduledPaymentChangeDateAlertMessage:
              'Los cambios en el pago programado se han realizado con éxito.',
            CancelScheduledPaymentAlertHeader: 'Pago programado cancelado',
            CancelScheduledPaymentAlertMessage:
              'Su pago programado ha sido cancelado exitosamente.',
          },
        },
        ScheduledPayment: {
          ScheduledPaymentTitle: 'Pagos programados',
          ScheduledPaymentSelect: 'Seleccione Pago Programado',
          ScheduledPaymentDate: 'Fecha de pago programada:',
          ScheduledPaymentDetails: 'Detalles de pago programados:',
          ChangePaymentDate: 'Cambiar fecha de pago',
          CancelScheduledPayment: 'Cancelar pago programado',
          NoRecords: 'No hay registros',
          NoRecordsData:
            'Actualmente no hay ningún pago programado para esta cuenta. Para programar un pago o configurar un plan de pagos, vaya a la pestaña Facturas.',
          NoRecordsClientAdmin:
            'Seleccione una cuenta para obtener una lista de pagos programados.',
          PaymentType: 'Tipo de pago: {{name}} - Cuenta que termina en {{num}}',
          AccountsonPayment: 'Cuentas en pago: {{name}}',
          InvoicesPaid: 'Facturas pagadas: {{nombre}}',
          DownloadDetails: 'Detalles de descarga:',
          paymentSelectInstructions:
            'Los detalles de pago se actualizarán automáticamente a continuación',
        },
        ChangeSchedulePaymentDate: {
          Title: 'Cambiar fecha de pago programada',
          Description:
            'Si desea cambiar la fecha del próximo pago programado para <b>{{planName}}</b>, seleccione una nueva fecha en el calendario a continuación.',
          Note: '<b>Nota:</b> Este cambio afectará solo el próximo pago programado.',
          ConfirmNewPaymentDate: 'Confirmar nueva fecha de pago',
          Cancel: 'Cancelar',
        },
        AddNewBankAccount: '+ nueva cuenta bancaria',
        AddNewCreditCard: '+ nueva tarjeta de crédito',
        PaymentMethodSaveNote:
          '<i>Los nuevos métodos de pago se guardarán en la cuenta de <b>{{clientName}}</b>. Para eliminar un método de pago, seleccione "<b>$t(Invoicing.AccountInformationDoNotSave)</b>" y complete la transacción.</i>',
        AgreementModal: {
          TermsConditionInv: 'Términos y condiciones',
          AcceptTermsCondition:
            'Para aceptar o rechazar, debe desplazarse por los términos y condiciones que se muestran a continuación.',
        },
        Frequency: 'Frecuencia',
        FrequencyRequired: 'La frecuencia es obligatoria',
        SelectInstallmentPlan: 'Seleccionar plan de pago',
        SelectInstallmentPlanRequired:
          'Seleccionar plan de pago a plazos es un campo obligatorio.',
        SelectInstallmentPlanSub:
          '(planes de pago basados en el monto total para la(s) factura(s) seleccionada(s). Si estas selecciones no se ajustan a sus necesidades, llame a Cuentas por cobrar al 833-597-2118 para discutir opciones de pago adicionales)',
        InstallmentPayments: '<0>{{num}} PAGOS :</0> {{paymentAmount}}/PAGO',
        InstallmentPaymentsUnformatted:
          '{{num}} PAGOS : {{paymentAmount}}/PAGO',
        InstallmentPaymentOther: 'OTRA',
        InstallmentPaymentOtherSelect: 'Otros planes de pago',
        InstallmentPaymentOtherRequired:
          'Otros planes de pago es un campo obligatorio.',
        SelectInstallmentPayment: 'Seleccione Otros planes de pago.',
        SelectInstallmentPaymentError: 'Otros planes de pago',
        PaymentsStartDate: 'Fecha de inicio de los pagos',
        PaymentsStartDateRequired: 'Elija una fecha de inicio de pago.',
        Monthly: 'Mensual',
        BiWeekly: 'Quincenal',
        Weekly: 'Semanal',
        AccountInformationDoNotSave: 'Eliminar después de procesar este pago',
        AccountInformation: 'Información de la cuenta',
        AccountInformationSub:
          'Seleccione desde una cuenta existente registrada o agregue una cuenta nueva. Las cuentas nuevas se pueden guardar para pagos futuros o usarse para pagos únicos.',
        Amount: 'Cantidad',
        AmountScheduled: 'Monto programado',
        TotalInstallmentAmount: 'MONTO TOTAL DE LA CUOTA',
        TotalInstallmentAmountSubText: '{{num}} cuotas de {{amount}}',
        TotalInstallmentAmountToolTip:
          'Los pagos se dividen según el número de cuotas y pueden ser inferiores al monto indicado.',
        AmountSelected: 'Cantidad seleccionada',
        AutoPay: 'Pago automático',
        BackToInvoices: 'Volver a las facturas',
        BackToPreviousPage: 'Volver a la página anterior',
        BankAccountEnding: 'Terminación de cuenta bancaria',
        CancelPayment: 'Cancelar el pago',
        ChooseBankAccount: 'Elija una cuenta bancaria existente',
        ChooseCreditCard: 'Elija una tarjeta de crédito existente',
        SelectBankAccount:
          'Seleccione una cuenta bancaria de la lista desplegable',
        SelectCreditCard:
          'Seleccione una tarjeta de crédito de la lista desplegable',
        SelectBankAccountError: 'Seleccionar cuenta bancaria',
        SelectCreditCardError: 'Seleccionar tarjeta de crédito',
        Clear: 'Claro',
        ClearSelection: 'Selección clara',
        Closed: 'Cerrado',
        Company: 'Empresa',
        ConfirmPayment: 'Confirmar pago',
        Continue: 'Continuar',
        ContinueToPayment: 'Continuar con el tipo de pago',
        CreditCardEnding: 'Terminación de la tarjeta de crédito',
        CurrentAmountOwed: 'Monto actual adeudado',
        CustomerName: 'Nombre del cliente',
        CustomerNumber: 'Identificación del cliente',
        BankAccount: 'cuenta bancaria',
        CreditCard: 'Tarjeta de crédito',
        Decline: 'Rechazar',
        Download: 'Descargar seleccionado',
        InvoiceDowload: 'Descargar',
        DownloadCancelledPaymentNotice:
          'Descargar Aviso de Cancelación de Pago',
        DownloadError: 'Error al descargar',
        DownloadErrorMessage:
          'Ocurrió un error durante la descarga. Inténtalo de nuevo.',
        DownloadErrorMessageForNotFound:
          'Una o más facturas seleccionadas no se pueden descargar en este momento. Llame a Cuentas por cobrar al 833.597.2118 para obtener ayuda.',
        DownloadErrorMessageInvoiceDocNotfound:
          'La factura seleccionada no se puede ver en este momento. Llame a Cuentas por cobrar al 833.597.2118 para obtener ayuda.',
        DownloadFailedPaymentNotice: 'Descargar Aviso de Fallo de Pago',
        DownloadPaymentDetails: 'Descargar detalles de pago',
        DownloadReceipt: 'Descargar ',
        ECPInvoicesTitle: 'myRSM - Facturas',
        ECPInvoicingAdministrativeTitle: 'myRSM - Invoicing Administrativa',
        ECPInvoicingTitle: 'myRSM - Facturación',
        ECPPaymentsTitle: 'myRSM - Pagos',
        Edit: 'Editar',
        EditPayment: 'Editar pago',
        EmailUs: 'Envíanos un correo electrónico',
        EndDateIsRequired: 'La fecha de finalización es obligatoria',
        EnterInvoiceOrKeywords:
          'Ingrese el número de factura(s) o palabras clave.',
        EnterInvoiceOrKeywordsSeperateCommas:
          'Ingrese el número de factura(s) o palabras clave. Separe varias facturas con comas.',
        ErrorMessages: {
          MustBeLessThanOpenAmount:
            'La entrada no puede exceder el monto de la factura',
        },
        ExpYourSessionIsAboutToExpire: 'Su sesión está a punto de caducar',
        Exp59Sec: 'Quedan 59 segundos, tu sesión está a punto de expirar',
        Exp30Sec: 'Quedan 30 segundos, tu sesión está a punto de expirar',
        Exp10Sec: 'Quedan 10 segundos, tu sesión está a punto de expirar',
        Exp5Sec: 'Quedan 5 segundos, tu sesión está a punto de expirar',
        ErrorGettingFilters: 'Error al obtener filtros',
        FindAnInvoice: 'Buscar una factura',
        FilterGroups: {
          amountrange: 'Rango de cantidad',
          amountRangeHeader: 'rango de $:',
          amountRangeTooltip:
            'Rango del control deslizante de cantidad, seleccione una cantidad inicial y final.',
          client: 'Cliente',
          clientId: 'Cliente',
          daterange: 'Fechas',
          enddate: 'fecha final',
          invoiceAmountFrom: 'Monto mínimo abierto',
          invoiceAmountTo: 'Monto máximo abierto',
          paymentAmountFrom: 'Monto máximo abierto',
          paymentAmountTo: 'Monto máximo abierto',
          startdate: 'fecha de inicio',
          status: 'Estado',
        },
        FilterCheckboxes: {
          open: 'Abierto',
          closed: 'Cerrado',
          pending: 'Pendiente',
          paid: 'Pagado',
          scheduled: 'Programado',
          cancelled: 'Cancelado',
          failed: 'Fallido',
        },
        IframeGenericErrorMessage:
          'Por favor valide la información de pago y vuelva a intentarlo. Si el problema persiste, comuníquese con su banco o proveedor de tarjeta para obtener ayuda.',
        IframeGenericErrorMessageHeader:
          'La información de pago no se pudo guardar.',
        IframeTitle: 'Formulario de Información de Pago',
        InvoiceDate: 'Fecha de la factura',
        InvoiceDateValue: '{{date, Datetime}}',
        InvoiceNumber: 'Número de factura',
        InvoiceAmount: 'Cantidad de factura',
        InvoicesAmountRangeFilter: {
          GreaterThanMaxError: 'El monto debe ser menor que el máximo.',
          InvalidNumberError: 'Ingrese un monto en dólares válido.',
          LessThanMinError: 'El monto debe ser mayor que el mínimo.',
          Maximum: 'Máximo',
          Minimum: 'Mínimo',
          NegativeNumberError: 'El importe no puede ser inferior a cero.',
          Title: 'Monto abierto',
        },
        InvoicesTable: {
          InvoiceStatuses: {
            Open: 'Abierto',
            Closed: 'Cerrado',
            Scheduled: 'Programado',
          },
        },
        PaymentAmount: 'Monto de pago',
        PartialAmount: 'Pago parcial',
        PaymentAmountRangeFilter: {
          InvoicesAmountRangeFilter: {
            GreaterThanMaxError: 'El monto debe ser menor que el máximo.',
            InvalidNumberError: 'Ingrese un monto en dólares válido.',
            LessThanMinError: 'El monto debe ser mayor que el mínimo.',
            Maximum: 'Máximo',
            Minimum: 'Mínimo',
            NegativeNumberError: 'El importe no puede ser inferior a cero.',
            Title: 'Monto pagado',
          },
        },
        PaymentDate: 'Fecha de pago',
        PaymentDateMessage:
          '(Para permitir el procesamiento, seleccione la fecha de pago a partir del {{date, P}})',
        PaymentDateRequired: 'La fecha de pago es obligatoria',
        NetPayment: 'Pago neto',
        Initiated: 'Fecha de pago',
        Installment: 'Entrega',
        Installments: 'Cuotas',
        Invoices: 'Facturas',
        InvoiceSelected: 'Factura seleccionada',
        InvoicesSelected: 'Facturas seleccionadas',
        ManageScheduledPayment: 'Administrar pago programado',
        Max: 'Máximo',
        Method: 'Tipo de pago',
        NoBankInfoSaved: 'No hay tarjetas/cuenta bancaria guardada',
        NoCardSaved: 'No hay tarjetas guardadas',
        NoDataFound: 'Datos no encontrados.',
        NoData: 'Datos no encontrados',
        NoInvoices: 'No se han encontrado facturas',
        NoInvoicesAccountManager:
          'Vaya a la pestaña Administración de cuentas para ubicar la cuenta por nombre de cliente o número de cliente.',
        NoInvoicesClientAdmin:
          'Vaya a la pestaña Panel de control para localizar la cuenta por nombre de cliente o número de cliente.',
        Note: 'Nota',
        OpenActionsMenuForInvoice: 'Abrir menú de acciones para factura',
        Open: 'Abierto',
        OpenAmount: 'Monto abierto',
        OriginalAmount: 'Cantidad original',
        OtherPaymentOptions: 'Otras opciones de pago',
        Paid: 'Pagado',
        PaidAmount: 'Monto pagado',
        PaidBy: 'Pagado por',
        PartialPaymentForInvoice:
          'Pago parcial de la factura {{invoiceNumber}}',
        Pay: 'Pagar',
        PaymentInformationHeader: 'Información de Pago',
        PaymentDates: 'Fechas de pago',
        Payments: 'Pagos',
        PaymentGateway: {
          AddNewBankAccount: 'Agregar una nueva cuenta bancaria',
          AddNewCreditCardAccount:
            'Agregar una nueva cuenta de tarjeta de crédito',
          PaymentInformation: 'Información del pago',
          TimeoutMessage:
            'Tendrá 5 minutos para completar este formulario. Una vez que se agote el tiempo, el modal se cerrará y no se guardarán todos los cambios.',
        },
        PaymentHistory: 'Historial de pagos',
        PaymentHistoryDisclaimerHeading: 'Descargo de responsabilidad:',
        PaymentHistoryDisclaimer:
          'aquí solo se mostrarán los pagos realizados en myRSM. Los cheques enviados por correo o los pagos electrónicos procesados ​​a través de Bank of America no se mostrarán en el historial de pagos.',
        Payer: 'Pagador',
        PayerMessage:
          'Al agregar una nueva cuenta al pagador, todos los usuarios asociados al pagador seleccionado tendrán acceso a esa cuenta.',
        PayerRequired: 'El pagador es obligatorio',
        PaymentAmountRequired:
          'Ingrese el monto del pago superior a {{amount}}.',
        PaySelectedInvoices: 'Pagar facturas seleccionadas',
        PaySelectedHelperText:
          'El estado de cualquier factura pagada mediante tarjeta de crédito después de las 5:30 p. m. o cuenta bancaria después de las 7:00 p. m. (CT) no se actualizará en myRSM durante 2 días hábiles.',
        PaymentMade: 'Pago realizado',
        PaymentSelectionWillApply:
          'Estas selecciones de pago se aplicarán a todas las facturas que haya seleccionado. Para actualizar la selección de facturas, regrese a la pantalla anterior.',
        PaymentMethod: 'Método de pago',
        PaymentMethodComponent: {
          PaymentMethodAdded: 'Método de pago agregado',
          PaymentMethodAddedMessage:
            '¡Tu método de pago ha sido agregado exitosamente!',
        },
        PaymentMethodRequired: 'El método de pago es obligatorio',
        PaymentSettings: 'Configuración de pago',
        PaymentTermsAndConditions: {
          Accepted: 'Acceptado',
          AcceptTermsAndConditions:
            'Acepto estos términos y condiciones de pago',
          PaymentTermsAndConditions: 'Términos y condiciones de pago',
          ViewPaymentTermsAndConditions:
            'Ver términos y condiciones de pago para continuar',
          ReadtermsandConditions:
            '* Lea los Términos y condiciones y desplácese hacia abajo antes de proceder al pago',
        },
        PayNowNote:
          'El monto anterior es el monto total abierto para todas las cuentas a las que está asociado. Al seleccionar Pagar ahora se iniciará el pago en todas las cuentas. Para pagar por una cuenta específica, seleccione de la lista de la izquierda.',
        PayOnAccount: 'Pagar la cuenta',
        SetupAutoPay: 'Configurar el pago automático',
        EditAutoPay: 'Editar/Cancelar pago automático',
        Autopay: 'Pago automático',
        AutopayEnable: 'activada',
        AutopayOn: 'Pago automático activado',
        AutopayOff: 'Pago automático OFF',
        Setup: 'Construir',
        Update: 'Actualizar',
        PayOnAccountPage: {
          Account: 'Cuenta',
          AssistanceMessage:
            'Para obtener ayuda con pagos o facturas, comuníquese con Cuentas por cobrar al 833-597-2118 o envíe un correo electrónico a accounts_receivable@rsmus.com',
          BackToAccountManagement: 'Volver a Gestión de cuentas',
          BackToDashboard: 'Volver al panel',
          Comments: 'Comentarios',
          HeaderDescription:
            'Los pagos realizados a través de esta pantalla se aplicarán directamente a su cuenta.  Para pagar facturas específicas, navegue hasta ',
          InvalidCharacters: 'Caracteres inválidos.',
          OpenInvoices: 'Facturas abiertas',
          PayAnotherAccount: 'Para pagar en otra cuenta asociada, regrese a ',
          PaymentAmount: 'Monto del pago',
          PurchaseOrderQuoteNumber: 'Número de orden de compra/cotización',
        },
        PaymentType: 'Tipo de pago',
        ProcessedBy: 'Procesado por',
        Retention:
          'Debido a la política de retención, las facturas del {{date}} no se pueden mostrar. Envíe su solicitud por correo electrónico a la dirección de correo electrónico correspondiente.',
        Pending: 'Pendiente',
        Scheduled: 'Programado',
        ScheduledOn: 'Programado para',
        ScheduledPayments: 'Pagos programados',
        SelectAllInvoices: 'Seleccionar todas las facturas',
        SelectAllPayments: 'Seleccionar todos los pagos',
        SelectInvoice: 'Seleccionar factura',
        SelectPaymentType: 'Seleccione tipo de pago',
        PaymentTyperequired: 'El tipo de pago es un campo obligatorio',
        SetupInstallments: 'Establecer cuotas',
        Share: 'Compartir',
        ShareReceipt: 'Compartir recibo',
        StartDateIsRequired: 'La fecha de inicio es obligatoria',
        Status: 'Estado',
        TotalAmount: 'Cantidad total',
        TransactionId: 'ID de transacción',
        PaymentSuccessPage: {
          CancelPayment: 'Cancelar el pago',
          Edit: 'Editar',
          PaymentScheduled: 'Pago programado',
          PaymentSuccess: 'Pago exitoso',
          PaymentScheduledDescription:
            'Una vez procesado, los detalles del pago estarán disponibles en',
          PaynowPaymentSucessDescription:
            'Su pago ha sido procesado. Un registro de esta transacción se puede encontrar en el',
          PaymentSucessDescription1:
            'Una vez que se ha procesado el pago, se puede encontrar un registro de estas transacciones en el',
          PaymentSucessDescription2: 'Historial de pagos',
          PaymentSucessDescription3: 'pestaña.',
          TransactionId: 'ID de transacción',
          TransactionDate: 'Fecha de transacción',
          DateScheduled: 'Fecha de pago programada',
          TransactionDateFormat: '{{date, PP}}',
          PaymentMethod: 'Método de pago',
          PayerName: 'Nombre del pagador',
          InstallmentPaymentDates: 'Fechas de pago a plazos',
          InstallmentPaymentDetails: 'Detalles de pago a plazos',
          InstallmentPlan: 'Plan de instalación',
          Invoices: 'Facturas',
          InvoicesPaid: 'Facturas pagadas',
          DowloadReceipt: 'Detalles de la descarga',
          EditInstallmentPlan: 'Editar plan de pagos',
          EditScheduledPayment: 'Editar pago programado',
          ShareReceipt: 'Compartir recibo',
          ViewPaidInvoiceDetails: 'Ver detalles de la factura pagada',
          HidePaidInvoiceDetails: 'Ocultar detalles de la factura pagada',
          ViewInstallmentPaidInvoiceDetails:
            'Ver detalles de las cuotas programadas',
          HideInstallmentPaidInvoiceDetails:
            'Ocultar detalles de cuotas programadas',
          ViewScheduledPaidInvoiceDetails: 'Ver detalles de pago programados',
          HideScheduledPaidInvoiceDetails:
            'Ocultar los detalles de pago programados',
          ChangePaymentDate: 'Cambiar fecha de pago',
          CancelScheduledPayment: 'Cancelar pago programado',
          CancelInstallmentPayment: 'Cancelar pago a plazos',
          ScheduledPaymentDateSelect: 'Pago programado  ({{name}})',
        },
        UnrelatedInvoicesDialog: {
          Title: 'Las facturas seleccionadas no están relacionadas',
          Subtitle: '¿Desea continuar con el pago?',
          Cancel: 'Cancelar',
          Proceed: 'Continuar',
          DontAskAgain: 'No pregunte de nuevo',
          TitleMutliclient: 'Multi-account payment notice',
          NoteMessage:
            'Nota: Los detalles de esta transacción, incluidos los números de factura, los montos y los identificadores de los clientes, se mostrarán en el recibo de pago de todos los destinatarios. Si desea recibir un recibo específico de la cuenta, seleccione “Cancelar” y procese los pagos de forma individual.',
          NoteMessageMutli:
            'Ha seleccionado pagar facturas de más de una cuenta. Los detalles de esta transacción, incluidos los números de factura, los importes y los identificadores de cliente, se mostrarán en el recibo de pago de todos los destinatarios. Si desea recibir un recibo específico de la cuenta, seleccione "Cancelar" y procese los pagos de forma individual.',
        },
        InvoiceExport: {
          ExportInvoiceHistory: 'Exportar historial de facturas',
          ExportFilteredResults: 'Exportar resultados filtrados',
          ExportEntireRecord: 'Exportar registro completo',
          Cancel: 'Cancelar',
          exportToCSV: 'Exportar a .csv',
          Export: 'Exportación',
          ExportLimitWarning:
            'El sistema exporta un máximo de 3000 registros. Para refinar los resultados, seleccione "Cancelar" y filtre usando el panel lateral y la barra de búsqueda.',
          ExportErrorDownload:
            'Error: No se pudo descargar. Inténtalo de nuevo más tarde.',
        },
        PaymentHistoryExport: {
          ExportPaymentHistory: 'Página de Historial de Pagos de Exportación',
          ExportFilteredResults: 'Exportar resultados filtrados',
          ExportEntireRecord: 'Exportar registro completo',
          Cancel: 'Cancelar',
          exportToCSV: 'Exportar a .csv',
          Export: 'Exportación',
          ExportLimitWarning:
            'El sistema exporta un máximo de 3000 registros. Para refinar los resultados, seleccione "Cancelar" y filtre usando el panel lateral y la barra de búsqueda.',
          ExportErrorDownload:
            'Error: No se pudo descargar. Inténtalo de nuevo más tarde.',
        },
        ConfirmAmountDialog: {
          Title: 'Confirmar monto',
          PartialAmountText:
            'Se ha ingresado un monto de pago parcial. Los pagos parciales sólo se pueden realizar a través de Pagar Ahora. Si desea programar un pago, regrese a las facturas seleccionadas y elimine el monto del pago parcial.',
          ConfirmAmountReview:
            'Revise el monto del pago a continuación y proceda a la pantalla de pago.',
          Cancel: 'Cancelar',
          Proceed: 'Proceder al pago',
        },
        ScheduledinvoicesDialog: {
          DialogText: 'Facturas ya en proceso',
          SubText1:
            'Las siguientes facturas que ha seleccionado para esta transacción ya se están procesando como parte de otro plan de pago en cuotas o pago programado.',
          SubText2:
            'Para continuar configurando un plan de pago, regrese a la pantalla anterior y anule la selección de las facturas anteriores o cambie el tipo de pago a Pagar ahora.',
          ScheduledInvoiceslineitems:
            'Número de factura: {{invoicenumber}} - {{paymenttype}} Pago  ({{Date}})',
          Close: 'Close',
          DeleteCardDialogText: 'Eliminar pagos programados con tarjeta',
          DeleteCardSubText1:
            'El método de pago que está intentando eliminar se está utilizando actualmente para los siguientes pagos planificados:',
          DeleteCardSubText2:
            'Agregue un método de pago alternativo o cancele los pagos planificados antes de eliminar este método de pago.',
          DeleteCardClose: 'Cancelar',
          DeleteCardScheduledInvoiceslineitems:
            '{{paymenttype}}Pago - próxima fecha de pago({{Date}})',
          SelectReplacement: 'Seleccione el método de pago de reemplazo',
          SelectReplacementLabel: 'Seleccionar método de pago de reemplazo',
        },
        CancelScheduledInvoicesDialog: {
          DialogText: 'Cancelar plan de cuotas',
          DialogTextschedl: 'Cancelar pago programado',
          SubText1:
            'Estás a punto de cancelar el plan de cuotas ({{date}}). Cualquier saldo pendiente de las siguientes facturas permanecerá sin pagar en su cuenta..',
          SubTextSchedule:
            'Está a punto de cancelar el pago programado ({{date}}). Los saldos pendientes de las siguientes facturas permanecerán sin pagar en su cuenta.',
          ScheduledInvoiceslineitems:
            'Número de factura: {{invoicenumber}} - {{clientName}} - ({{openAmount}})',
          ScheduledInvoicesitemsMobile: '{{invoicenumber}} - {{openAmount}}',
          Cancel: 'Cancelar',
          Confirm: 'Confirmar cambio',
        },
        TotalNetPayableAmount: 'Cantidad total neta a pagar',
        TotalPayableAmount: 'Cantidad total a pagar',
        TotalOpenAmount: 'Monto abierto total',
        PartialPaymentToolTipNote: 'Nota: ',
        PartialPaymentToolTipText:
          'Los pagos parciales sólo se pueden pagar ahora y no programarse.',
        UploadImage: 'Cargar imagen',
        SelectedOfTotalInvoicesSelected:
          '{{selected}} de {{total}} facturas seleccionadas',
        ViewClient: 'Ver cliente',
        whenWillYouPay: 'cuando vas a pagar',
        ScheduledPlanNote:
          '<i>Las facturas que ha seleccionado ya están en un plan programado. Continúe con <b>Pagar ahora</b> o regrese a la pantalla anterior para cambiar las selecciones<i>',
      },
      SessionManager: {
        Exp10Min: '10 minutos hasta que expire la sesión',
        Exp5Min: '5 minutos hasta que expire la sesión',
        Exp1Min: '1 minuto hasta que expire la sesión',
        Exp30Sec: '30 segundos hasta que expire la sesión',
        Exp10Sec: '10 segundos hasta que expire la sesión',
        Exp5Sec: '5 segundos hasta que expire la sesión',
        Exp1Sec: 'La sesión expiró',
      },
      WelcomeMessageModal: {
        WelcomeTitle: 'Hola {{firstName}}, ¡Bienvenido al myRSM!',
        WelcomeDesc:
          '¡Bienvenido al myRSM! Esta plataforma en línea es una ubicación conveniente, eficiente y segura para que pueda acceder sin problemas a las aplicaciones clave proporcionadas por RSM, colaborar con los empleados de RSM y también recibir liderazgo de pensamiento oportuno en función de sus intereses.',
        WelcomeQuestion: '¿Cómo le gustaría comenzar su experiencia en myRSM?',
        CustomizeMyExperienceTitle: 'PERSONALIZAR MI EXPERIENCIA',
        CustomizeMyExperienceDesc:
          'Tome un asistente rápido de 4 pasos para seleccionar qué industrias, líneas de servicio y temas desea ver más.',
        CustomizeMyExperienceLink: 'Configura tus intereses',
        NoThanksTitle: 'No, gracias',
        NoThanksDesc: 'Solo quiero sumergirme y comenzar a usar myRSM.',
        NoThanksLink: 'Empezar a usar myRSM',
      },
      Footer: {
        PrivacyPolicy: 'Política de privacidad',
        Disclaimer:
          'RSM US LLP is a limited liability partnership and the U.S. member firm of RSM International, a global network of independent audit, tax and consulting firms. The member firms of RSM International collaborate to provide services to global clients, but are separate and distinct legal entities that cannot obligate each other. Each member firm is responsible only for its own acts and omissions, and not those of any other party. Visit rsmus.com/aboutus for more information regarding RSM US LLP and RSM International.',
        Copyright: '© {{copyrightYear}} RSM US LLP. All rights reserved.',
      },
      ModalDialog: {
        CloseDialog: 'Cerrar diálogo',
      },
      RSMUS: 'RSMUS',
      RSMUSClient: 'RSMUS Cliente',
      ReturnToCP: 'Volver a myRSM',
      PaymentMethodLimit: {
        BankAcctOrCredCardLimit:
          'Has alcanzado el máximo de {{cclimit}} guardados {{paidMethodstype}}. Para agregar un nuevo {{paidMethodstype}}, elimine otro de su lista existente.',
      },
    },
  },
}
