import React from 'react'
import { useTranslation } from 'react-i18next'
import SubMenu from '../../../layouts/SubMenu'
import RouterNavListItem from '../../../Navigation/NavListItem/RouterNavListItem'

type ContactUsMenuProps = {
  currentItem?: string
}

const ContactUsMenu = ({ currentItem = '' }: ContactUsMenuProps) => {
  const { t } = useTranslation()

  return (
    <SubMenu currentItem={currentItem} title={t('Navigation.ContactUs')}>
      <RouterNavListItem
        to="/contact-us/support-page"
        value="support-page"
        testId="Lnk_Navigation_Invoices">
        {t('Navigation.Support')}
      </RouterNavListItem>
      <RouterNavListItem
        to="/contact-us/feedback"
        value="feedback"
        testId="Lnk_Navigation_Invoices">
        {t('Navigation.Feedback')}
      </RouterNavListItem>
      <RouterNavListItem
        to="/contact-us/faq"
        value="faq"
        testId="Lnk_Navigation_Invoices">
        {t('Navigation.FAQ')}
      </RouterNavListItem>
    </SubMenu>
  )
}

export default ContactUsMenu
